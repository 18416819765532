import {Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

const {Title} = Typography;

function MemberSuccess() {
  const [counter, setCounter] = useState(5);
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter => (counter ? counter - 1 : counter));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (!counter) {
    return <Redirect to="/" />;
  }
  return (
    <div style={{marginBottom: '24px'}}>
      <div>
        <Title level={4} style={{color: '#148CFA'}}>
          Password set successfully.
        </Title>
        <Title level={4} type={'secondary'}>
          You will be redirected to main page in {counter} sec.
        </Title>
      </div>
    </div>
  );
}

export default MemberSuccess;
