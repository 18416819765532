import * as React from 'react';
import {Row} from 'antd';

export const ListActions = ({className, leftActions, rightActions, middleActions}: any) => (
  <Row className={className} style={{justifyContent: 'space-between '}} type="flex">
    <div>{leftActions}</div>
    {middleActions && <div className="middle-actions">{middleActions}</div>}
    <div>{rightActions}</div>
  </Row>
);
