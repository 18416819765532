import {Dropdown, Button} from 'antd';
import React from 'react';

import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';

import AccountMenu from './accountMenu';
import './accountSwitcher.css';

export function MobileAccountSwitcher({client, team_member, onClick, isNavCollapsed, setSettings, isAdmin}) {
  return (
    <div className="accountSwitcher">
      <span className="accountSwitcher_email">
        {team_member ? (
          <>
            {team_member.email}
            <br />
            <span className="sub_email">member of {client.data.email}</span>
          </>
        ) : (
          client.data.email
        )}
      </span>
      <Dropdown
        overlay={<AccountMenu client={client.data} onClick={onClick} team_member={team_member} isAdmin={isAdmin} />}
        placement="bottomLeft"
        trigger={['click']}
      >
        <Button type="primary" shape="circle" icon="user" style={{marginRight: '10px'}} />
      </Dropdown>
      <Button
        type="primary"
        onClick={() => {
          setSettings({isNavCollapsed: !isNavCollapsed});
        }}
        style={{padding: '5px 10px', marginRight: 5}}
      >
        {isNavCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
    </div>
  );
}
