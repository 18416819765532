import {Form, Icon, Select, message, Popover} from 'antd';
import React, {useState} from 'react';

import {Conversation} from '@growth-x/types';

import {STRINGS} from '../../utils';
import {DealsAmount} from './dealsAmount';
import './conversationTags.css';

const {Option} = Select;

interface IConversationListProps {
  isUpdating: boolean;
  dealsNoteUpdating: boolean;
  conversation: Conversation;
  onChange: (tags: string[], conversation: Conversation) => void;
  updateDealsAmount: (dealsAmount: string) => void;
  allTags: Array<string>;
}

export const ConversationTags = ({
  isUpdating,
  conversation,
  onChange,
  allTags,
  updateDealsAmount,
  dealsNoteUpdating,
}: IConversationListProps) => {
  const [dealsAmountVisible, setDealsAmountVisible] = useState(false);

  const updateDeals = async (value: any) => {
    await updateDealsAmount(value);
    setDealsAmountVisible(false);
  };

  const updateTag = (value: any) => {
    const isHaveTag = el => el === STRINGS.tags.positive_ai_defined;
    const containedPreviouslyClosedDeal = conversation.receiver.tags.find(t => t === STRINGS.tags.closed_deal);
    const containPreviouslyClosedDealNow = value.find(t => t === STRINGS.tags.closed_deal);
    const shouldShowDealsAmountPopover = Boolean(!containedPreviouslyClosedDeal && containPreviouslyClosedDealNow);
    setDealsAmountVisible(shouldShowDealsAmountPopover);
    const isTagExisted = conversation.receiver.tags.some(isHaveTag);
    const isTagAdded = value.some(isHaveTag);
    const hasAnyTag = isTagExisted && isTagAdded;
    const hasNoAnyTag = !isTagExisted && !isTagAdded;

    if (hasAnyTag || hasNoAnyTag) {
      onChange(value, conversation);
    } else {
      message.error(STRINGS.warning.tags_positive_ai_defined_warning, 5);
    }
  };

  return (
    <div className="conversation__additional-info">
      <Form.Item>
        <Icon type="tag" />
        <Popover
          content={<DealsAmount dealsNoteUpdating={dealsNoteUpdating} updateDealsAmount={updateDeals} />}
          title="Deal Amount"
          trigger="click"
          visible={dealsAmountVisible}
        >
          <Select
            mode="tags"
            loading={isUpdating}
            disabled={isUpdating}
            value={conversation.receiver.tags}
            onChange={updateTag}
            placeholder="no tags"
            size="small"
            className="conversation__tag-input"
          >
            {allTags.map((tag: string) => (
              <Option key={tag}>{tag}</Option>
            ))}
          </Select>
        </Popover>
      </Form.Item>
    </div>
  );
};
