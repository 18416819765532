import {
  SET_HS_AUTHORIZATION_CODE_REQUEST,
  SET_HS_AUTHORIZATION_CODE_SUCCESS,
  SET_HS_AUTHORIZATION_CODE_FAILURE,
  GET_HS_AUTHORIZED_CREDENTIALS_REQUEST,
  GET_HS_AUTHORIZED_CREDENTIALS_SUCCESS,
  GET_HS_AUTHORIZED_CREDENTIALS_FAILURE,
  CLEAR_HS_AUTHORIZED_CREDENTIALS_REQUEST,
  CLEAR_HS_AUTHORIZED_CREDENTIALS_SUCCESS,
  CLEAR_HS_AUTHORIZED_CREDENTIALS_FAILURE,
  GET_HS_USERS_SUCCESS,
  GET_HS_SEARCH_PROPERTY_SUCCESS,
} from './hsOauth2Actions';

const initialState: any = {
  isLoading: false,
  users: [],
  propertyNameOptions: [],
};

export const hsOauth2Reducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_HS_AUTHORIZED_CREDENTIALS_REQUEST: {
      return {...state, isLoading: true};
    }
    case GET_HS_AUTHORIZED_CREDENTIALS_SUCCESS: {
      return {...state, isLoading: false, ...action.data};
    }
    case GET_HS_AUTHORIZED_CREDENTIALS_FAILURE: {
      return {...state, isLoading: false};
    }
    case SET_HS_AUTHORIZATION_CODE_REQUEST: {
      return {...state, isLoading: true};
    }
    case SET_HS_AUTHORIZATION_CODE_SUCCESS: {
      return {...state, isLoading: false};
    }
    case SET_HS_AUTHORIZATION_CODE_FAILURE: {
      return {...state, isLoading: false};
    }
    case CLEAR_HS_AUTHORIZED_CREDENTIALS_REQUEST: {
      return {...state, isLoading: true};
    }
    case CLEAR_HS_AUTHORIZED_CREDENTIALS_SUCCESS: {
      return {isLoading: false};
    }
    case CLEAR_HS_AUTHORIZED_CREDENTIALS_FAILURE: {
      return {...state, isLoading: false};
    }
    case GET_HS_USERS_SUCCESS: {
      return {...state, users: action.users};
    }
    case GET_HS_SEARCH_PROPERTY_SUCCESS: {
      return {...state, propertyNameOptions: action.options}
    }
    default:
      return state;
  }
};
