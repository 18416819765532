import {Campaign, ValidateCSVResponse} from '@growth-x/types';

import {STRINGS} from '../..';
import {brandConfig, getCampaignErrorMessageByCode} from '../../utils/';

export class CampaignsService {
  client: any;
  constructor({client}: any) {
    this.client = client;
  }
  serviceName = 'campaigns';
  getCampaignsByClient(clientId: number, archive_mode: string) {
    return this.client.get(this.serviceName, {
      client_id: clientId,
      archive_mode,
      toggle_api_feature: 'client_attachment',
    });
  }
  getCampaignsStats(parameters: any) {
    return this.client.get(`stats/by_campaign`, parameters);
  }
  getCampaignsGraph(parameters: any) {
    return this.client.get(`stats/graph`, parameters);
  }
  getCampaignAudienceStats(parameters: any) {
    return this.client.get(`stats/audience`, parameters);
  }
  getCampaignClickedLinksStats(parameters: any) {
    return this.client.get(`stats/by_campaign/tracking_links/`, parameters);
  }
  getCampaignById(campaignId: number) {
    return this.client.get(this.serviceName, {
      id: campaignId,
      toggle_api_feature: 'client_attachment',
    });
  }
  getCampaignUserByCampaign(campaignId: number) {
    return this.client.get('campaign_users', {
      campaign_id: campaignId,
      method: 'by_campaign',
    });
  }
  getCampaignUserByClient(clientId: number, archive_mode: string) {
    return this.client.get('campaign_users', {
      client_id: clientId,
      method: 'by_client',
      archive_mode,
    });
  }
  create(campaign: any) {
    return this.client
      .postMultipart(this.serviceName, {...campaign, toggle_api_feature: 'client_attachment'})
      .catch((e: any) => e.response)
      .then((result: any) => result);
  }
  update(campaign: any) {
    return this.client
      .postMultipart(this.serviceName, {...campaign, method: 'update', toggle_api_feature: 'client_attachment'})
      .catch((e: any) => e.response)
      .then((result: any) => result);
  }
  updateCampaign(parameters: any) {
    return this.client.post(this.serviceName, {...parameters, toggle_api_feature: 'client_attachment'});
  }
  updateActions(campaign: Campaign) {
    const parameters: any = {
      id: campaign.id,
      method: 'update_actions',
      messages_active: campaign.messages_active,
      followups_active: campaign.followups_active,
      second_followups_active: campaign.second_followups_active,
      third_followups_active: campaign.third_followups_active,
    };
    return this.client.put(this.serviceName, parameters);
  }
  archive(campaignId: number) {
    return this.client.put(this.serviceName, {
      id: campaignId,
      method: 'archive',
    });
  }
  unarchive(campaignId: number) {
    return this.client.put(this.serviceName, {
      id: campaignId,
      method: 'unarchive',
    });
  }
  duplicate(campaignId: number) {
    return this.client.post(this.serviceName, {
      id: campaignId,
      method: 'duplicate',
    });
  }
  deleteCampaign(campaignId: number) {
    return this.client.delete(`api/v2/campaigns/${campaignId}/delete-campaign`, {});
  }
  updateActionAndError(campaign: any) {
    const isWithError = campaign.error_code && campaign.error_code.length;
    const parameters: any = {
      id: campaign.id,
      method: 'update_fields',
      error_code: isWithError ? campaign.error_code : '',
      error: isWithError ? campaign.error : '',
      messages_active: campaign.messages_active,
      followups_active: campaign.followups_active,
      second_followups_active: campaign.second_followups_active,
      third_followups_active: campaign.third_followups_active,
    };
    return this.client.put(this.serviceName, parameters);
  }
  updateError(campaign: any) {
    const errorMsg = getCampaignErrorMessageByCode(campaign.error_code);
    const errMsg = errorMsg.replace('{brandConfig.contact}', brandConfig.contact);
    if (errorMsg !== STRINGS.errors.campaign.error_default) {
      const parameters = {
        id: campaign.id,
        method: 'update_fields',
        error: errMsg,
        error_code: campaign.error_code,
      };

      return this.client.put(this.serviceName, parameters);
    }

    console.error(`wrong campaign error code ${campaign.error_code}`);
  }
  updateWarning(campaign: any) {
    const warningMsg = getCampaignErrorMessageByCode(campaign.warning_code);
    const warnMsg = warningMsg.replace('{brandConfig.contact}', brandConfig.contact);

    if (warningMsg !== STRINGS.errors.campaign.error_default) {
      const parameters = {
        id: campaign.id,
        method: 'update_fields',
        warning: campaign.warning_code || '',
        warning_code: warnMsg,
      };

      return this.client.put(this.serviceName, parameters);
    }

    console.error(`wrong campaign warning code ${campaign.error_code}`);
  }

  async validateCSV(csv: File): Promise<ValidateCSVResponse> {
    return await this.client.postMultipart(`${this.serviceName}/validate_csv/`, {csv});
  }

  async updateLastScan(id, reset = false) {
    const params = {
      id,
      method: 'update_scan_date',
      ...(reset && {
        reset: true,
      }),
    };
    return await this.client.put(this.serviceName, params);
  }

  getFollowupMessageSuggestion(parameters: any) {
    return this.client.get(`api/v2/suggest-followup`, parameters);
  }
}
