export class ManualTasksService {
  client: any;
  constructor({client}: any) {
    this.client = client;
  }
  serviceName = 'api/v2/manual_task';

  getTasks() {
    return this.client.get(`${this.serviceName}`, {});
  }

  loadMoreTasks(url) {
    return this.client.getByUrl(url, {});
  }

  createTask(params: any) {
    return this.client.post(`${this.serviceName}`, params);
  }
}
