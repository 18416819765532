/**
 * Evaluate potential
 * 1 - 300 warning
 * 301 - 2500 success
 * 2501 - 20000 warning
 * 20001+ danger
 * @param potential
 */

export const evaluatePotential = (potential: number) => {
  if (potential >= 1 && potential <= 300) return 'warning';
  if (potential >= 300 && potential <= 2500) return 'success';
  if (potential >= 2501 && potential <= 20000) return 'warning';
  if (potential > 20000) return 'danger';
};
