import {StorageServiceAbstract} from '@growth-x/ui';

class _StorageService extends StorageServiceAbstract {
  setItem = (property: string, value: any) => {
    localStorage.setItem(property, value);
  };
  getItem = (property: string) => {
    const value = localStorage.getItem(property);
    if (value) {
      try {
        return JSON.parse(value);
      } catch (err) {
        this.removeItem(property);
        return undefined;
      }
    }
  };
  removeItem = (property: string) => {
    localStorage.removeItem(property);
  };
  getAllItems = () => {
    const keys = Object.keys(localStorage);

    let store: any = {};

    for (const key of keys) {
      if (!key.includes('intercom')) {
        store = {...store, [key]: this.getItem(key)};
      }
    }

    return store;
  };
}

export const StorageService = new _StorageService();
