import {Spin} from 'antd';
import React, {Suspense, ComponentType} from 'react';

/**
 * HOC to wrap a component in a Suspense component.
 */

export default function withSuspense<P>(Component: ComponentType) {
  return function WithSuspense(props: P) {
    return (
      <Suspense
        fallback={
          <div>
            Loading <Spin />
          </div>
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };
}
