import {Button, Dropdown, Menu} from 'antd';
import React, {useMemo} from 'react';

import {EllipsisOutlined} from '@ant-design/icons';
import {ExportCsv, STRINGS} from '@growth-x/ui';

import {prepareHeaders} from './utils';

export function TableDataActions({client, campaigns, showColumns, archiveMode}) {
  const {data, headers} = prepareHeaders(campaigns, showColumns, archiveMode);
  const fileName = useMemo(() => {
    if (archiveMode === 'unarchived_only') return 'ActiveCampaigns.csv';
    if (archiveMode === 'archived_only') return 'ArchivedCampaigns.csv';
    return 'AllCampaigns.csv';
  }, [archiveMode]);

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="1">
            <ExportCsv text="Export Stats" fileName={fileName} data={data} headers={headers} />
          </Menu.Item>
          <Menu.Item key="2">
            <a href={`${STRINGS.analyzer_url}${client.analytics_code}`} rel="noopener noreferrer" target="_blank">
              More Details
            </a>
          </Menu.Item>
        </Menu>
      }
      placement="bottomLeft"
    >
      <Button shape="circle" loading={!client} className="campaigns-page__right-controls">
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  );
}
