import {
  CREATE_CLIENT_ATTACHMENT_REQUEST,
  CREATE_CLIENT_ATTACHMENT_SUCCESS,
  CREATE_CLIENT_ATTACHMENT_FAILURE,
  DELETE_CLIENT_ATTACHMENT_REQUEST,
  DELETE_CLIENT_ATTACHMENT_SUCCESS,
  DELETE_CLIENT_ATTACHMENT_FAILURE,
  GET_CLIENT_ATTACHMENTS_REQUEST,
  GET_CLIENT_ATTACHMENTS_SUCCESS,
  GET_CLIENT_ATTACHMENTS_FAILURE,
  GET_CLIENT_ATTACHMENT_REQUEST,
  GET_CLIENT_ATTACHMENT_SUCCESS,
  GET_CLIENT_ATTACHMENT_FAILURE,
} from './clientAttachmentsActions';

const initialState: any = {
  list: {data: []},
  clientAttachmentsLoading: false,
  clientAttachmentsListError: '',
};

export const clientAttachmentsReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CLIENT_ATTACHMENTS_REQUEST:
      return {
        ...state,
        clientAttachmentsLoading: true,
      };

    case GET_CLIENT_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        clientAttachmentsLoading: false,
        list: action.clientAttachmentsList,
      };

    case GET_CLIENT_ATTACHMENTS_FAILURE:
      return {
        ...state,
        clientAttachmentsLoading: false,
        clientAttachmentsListError: action.error,
      };

    case CREATE_CLIENT_ATTACHMENT_REQUEST:
      return {
        ...state,
        clientAttachmentsLoading: true,
      };

    case CREATE_CLIENT_ATTACHMENT_SUCCESS: {
      const isAlreadyAdded = state.list.data.find(
        (attachment: any) =>
          attachment.attachment_name === action.attachment.attachment_name ||
          attachment.attachment_name === action.attachment.name
      );
      if (isAlreadyAdded) {
        return {
          ...state,
          clientAttachmentsLoading: false,
        };
      }
      return {
        ...state,
        clientAttachmentsLoading: false,
        list: {
          ...state.list,
          data: [...state.list.data, action.attachment],
        },
      };
    }

    case CREATE_CLIENT_ATTACHMENT_FAILURE:
      return {
        ...state,
        clientAttachmentsLoading: false,
        clientAttachmentsListError: action.error,
      };

    case DELETE_CLIENT_ATTACHMENT_REQUEST:
      return {
        ...state,
        clientAttachmentsLoading: true,
      };

    case DELETE_CLIENT_ATTACHMENT_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data.filter(attachment => attachment.id !== action.attachment_id),
        },
      };

    case DELETE_CLIENT_ATTACHMENT_FAILURE:
      return {
        ...state,
        clientAttachmentsListError: action.error,
      };

    case GET_CLIENT_ATTACHMENT_REQUEST:
      return {
        ...state,
        clientAttachmentsLoading: true,
      };

    case GET_CLIENT_ATTACHMENT_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data.map(attachment => {
            if (attachment.id === action.attachment_id)
              return {
                ...attachment,
                file: action.file,
              };
            return attachment;
          }),
        },
      };

    case GET_CLIENT_ATTACHMENT_FAILURE:
      return {
        ...state,
        clientAttachmentsListError: action.error,
      };

    default:
      return state;
  }
};
