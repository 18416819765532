import {getCompaniesReplacer} from './getCompaniesReplacer';
import {replaceKeys} from './replaceKeys';

export const isContainLink = (text: string) => {
  if (!text || !text.length) {
    return false;
  }

  const companiesReplacer = getCompaniesReplacer(text, {});

  const keys = ['{user.first_name}', '{receiver.first_name}', '{receiver.last_name}', ...companiesReplacer.keys];

  const values = ' '.repeat(keys.length).split(' ');

  text = replaceKeys(text, keys, values);

  const regex = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi);
  return regex.test(text);
};
