export class NotificationsService {
  client: any;
  serviceName = 'notifications';
  constructor({client}: any) {
    this.client = client;
  }
  getNotificationsList() {
    return this.client.get(`${this.serviceName}`, {});
  }

  createNotification(parameters) {
    return this.client.post(`${this.serviceName}`, parameters);
  }

  loadMoreNotifications(url) {
    return this.client.getByUrl(url, {});
  }
  readNotification(notificationId) {
    return this.client.post(`${this.serviceName}/read`, {id: notificationId});
  }

  getNotificationSettings() {
    return this.client.get('api/v2/manage-notifications/', {});
  }

  setNotificationSettings(parameters) {
    return this.client.post('api/v2/manage-notifications/', parameters);
  }
}
