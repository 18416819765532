import {Tooltip, Button} from 'antd';
import React from 'react';
import {isMobile} from 'react-device-detect';

import '../campaignsActions.scss';
import {SelectTableColumns} from './SelectTableColumns';
import {TableDataActions} from './TableDataActions';

export function CampaignsRightActions({
  client,
  campaignsLoading,
  handleRefreshCampaigns,
  statsMode,
  setStatsMode,
  graphLoading,
  showColumns,
  archiveMode,
  campaigns,
  handleChangeColumns,
  clientTags,
}: any) {
  return (
    <div className="campaigns-page__right-controls">
      <Button
        type="default"
        loading={graphLoading}
        onClick={() => setStatsMode(!statsMode)}
        className="campaigns-page__right-controls account-stats"
      >
        Stats
      </Button>
      {!isMobile && <SelectTableColumns onChange={handleChangeColumns} columns={showColumns} clientTags={clientTags} />}
      <TableDataActions client={client} campaigns={campaigns} showColumns={showColumns} archiveMode={archiveMode} />
      <Tooltip title="Refresh">
        <Button shape="circle" icon="reload" loading={campaignsLoading} onClick={handleRefreshCampaigns} />
      </Tooltip>
    </div>
  );
}
