import {Modal, Row, Select} from 'antd';
import React from 'react';

import {Campaign, Client} from '@growth-x/types';

import {STRINGS, toQueryString} from '../..';

const {Option} = Select;

interface CampaignStatisticModalProps {
  handleCancel: () => void;
  visible: boolean;
  client: Client;
  campaign?: Campaign;
}

interface CampaignStatisticModalState {
  days: string;
  userId?: string;
}

export class CampaignStatisticModal extends React.Component<CampaignStatisticModalProps, CampaignStatisticModalState> {
  state: CampaignStatisticModalState = {
    days: '7',
    userId: undefined,
  };

  static getDerivedStateFromProps(nextProps: CampaignStatisticModalProps, prevState: CampaignStatisticModalState) {
    if (
      nextProps.campaign &&
      nextProps.campaign.selectedUser &&
      nextProps.campaign.selectedUser.id !== prevState.userId
    ) {
      return {
        userId: 0,
      };
    }
    return null;
  }

  getUrl = () => {
    const parameters: any = {
      analytics_code: this.props.client.analytics_code,
      days: this.state.days,
    };
    if (this.props.campaign) {
      parameters.campaign = this.props.campaign.id;
    }
    if (this.state.userId && this.state.userId != '0') {
      parameters.user = this.state.userId;
    }
    return `${STRINGS.metabase_campaigns_stats}?${toQueryString(parameters)}`;
  };

  handleDaysChange = (days: string) => {
    this.setState({days});
  };

  handleUserChange = (userId: string) => {
    this.setState({userId});
  };

  render() {
    const url = this.getUrl();
    const user = this.props.campaign ? this.props.campaign.selectedUser : null;
    return (
      <Modal
        visible={this.props.visible}
        onCancel={this.props.handleCancel}
        footer={null}
        width={700}
        title={this.props.campaign ? this.props.campaign.name : 'Campaign statistic'}
      >
        <Row type="flex" style={{justifyContent: 'flex-end'}}>
          <Select
            value={this.state.days}
            defaultValue="7"
            style={{width: 200, marginBottom: '20px', marginRight: '10px'}}
            onChange={this.handleDaysChange}
          >
            <Option value="1">Last day</Option>
            <Option value="7">Last week</Option>
            <Option value="30">Last month</Option>
            <Option value="90">Last 3 months</Option>
          </Select>
          <Select value={this.state.userId} style={{width: 200, marginBottom: '20px'}} onChange={this.handleUserChange}>
            <Option value={0}>All Users</Option>
            {user ? <Option value={user.id}>{user.email}</Option> : ''}
          </Select>
        </Row>
        <Row type="flex" style={{justifyContent: 'flex-end'}}>
          <iframe id="iframe-stats" src={url} frameBorder="0" width="680" height="400" />
        </Row>
      </Modal>
    );
  }
}
