/**
 * This function will filter the array to find all columns that exists inside
 * the defaultTags array.
 * @param {Array<string>} allColumns - An array of strings that represents all
 * columns that are being shown right now.
 * @param {Array<string>} allNotTagColumns - An array of strings that represents all
 * columns that are not tags.
 * @returns {Array<string>} An array of strings that represents all columns of
 * tags that exists inside the allColumns
 */
export const findTagColumns = (allColumns: Array<string> = [], allNotTagColumns: Array<any> = []): Array<string> => {
  return [...new Set([...allColumns.filter((column: string) => !allNotTagColumns.find(c => c.value === column))])];
};
