import {STRINGS} from './constants';
import {QueryData} from './queryData';

export const urlHasAdvancedFilters = (url: string) => {
  const oldFilters = [
    'companySize',
    'pastCompany',
    'tenureAtCurrentPosition',
    'tenureAtCurrentCompany',
    'yearsOfExperience',
    'companyType',
    'group',
    'titleTimeScope',
  ];
  const newFilters = [
    'geo',
    'geoIncluded',
    'geoExcluded',
    'seniority',
    'seniorityIncluded',
    'seniorityExcluded',
    'industry',
    'industryIncluded',
    'industryExcluded',
    'school',
    'schoolIncluded',
    'schoolExcluded',
    'function',
    'functionIncluded',
    'functionExcluded',
    'title',
    'titleIncluded',
    'titleExcluded',
    'company',
    'companyIncluded',
    'companyExcluded',
  ];
  const queryAdvancedFilters = [
    'REGION',
    'COMPANY_HEADCOUNT',
    'CURRENT_TITLE',
    'SENIORITY_LEVEL',
    'INDUSTRY',
    'YEARS_OF_EXPERIENCE',
    'SCHOOL',
    'FUNCTION',
    'CURRENT_COMPANY',
    'PAST_COMPANY',
    'GROUP',
  ];
  return checkFiltersPresent(url, [...oldFilters, ...newFilters, ...queryAdvancedFilters]);
};

export const checkFiltersPresent = (url = '', filters: string[]) => {
  const queryData = extractQueryData(url);
  for (const f in filters) {
    if (queryData.hasOwnProperty(filters[f])) {
      return true;
    }
  }
  if (queryData.hasOwnProperty('query')) {
    const query = queryData.query[0] || '';
    for (const f of filters) {
      if (query.includes(`(type:${f},`)) {
        return true;
      }
    }
  }
  return false;
};

const extractQueryData = (url: string) => {
  try {
    const queryString = url.substring(url.indexOf('?'));
    const query: any = new QueryData(queryString, true);
    return query;
  } catch (err) {
    //in case the uri is malformed return empty object
    return {};
  }
};

const includesTitleFilter = (url: string) => {
  return checkFiltersPresent(url, ['title', 'jobTitleEntities']);
};

const includesLocationFilter = (url: string) => {
  return checkFiltersPresent(url, ['facetGeoRegion', 'facet.G', 'REGION']);
};

export const getAdvancedLinkedinMessages = (url: string): string[] => {
  const result: string[] = [];
  if (!url) {
    return result;
  }
  if (!urlHasAdvancedFilters(url) && !url.includes('savedSearchId')) {
    result.push(
      `Advanced filters are not set. Use Linkedin advanced search filters and special search types to get targeted results. More info <a href="${STRINGS.advanced_search_help_url}" target="_blank" rel="noreferrer noopener">here</a>. `
    );
  }
  if (urlHasAdvancedFilters(url)) {
    result.push(
      `Great job! You've defined some advanced search filters. Improve your targeting even more by following these tips <a href="${STRINGS.advanced_search_help_url}" target="_blank" rel="noreferrer noopener">here</a>. `
    );
  }
  if (!includesTitleFilter(url) || !includesLocationFilter(url)) {
    result.push(`Remember to use the location and title filter for a more successful campaign. `);
  }
  return result;
};
