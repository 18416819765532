import {InstantlyCampaign} from '@growth-x/types';

import {InstantlyService} from '../../services';

export const VERIFY_INSTANTLY_TOKEN_REQUEST = 'VERIFY_INSTANTLY_TOKEN_REQUEST';
export const VERIFY_INSTANTLY_TOKEN_SUCCESS = 'VERIFY_INSTANTLY_TOKEN_SUCCESS';
export const VERIFY_INSTANTLY_TOKEN_FAILURE = 'VERIFY_INSTANTLY_TOKEN_FAILURE';

export const GET_INSTANTLY_CAMPAIGNS_REQUEST = 'GET_INSTANTLY_CAMPAIGNS_REQUEST';
export const GET_INSTANTLY_CAMPAIGNS_SUCCESS = 'GET_INSTANTLY_CAMPAIGNS_SUCCESS';
export const GET_INSTANTLY_CAMPAIGNS_FAILURE = 'GET_INSTANTLY_CAMPAIGNS_FAILURE';

export const CONNECT_INSTANTLY_REQUEST = 'CONNECT_INSTANTLY_REQUEST';
export const CONNECT_INSTANTLY_SUCCESS = 'CONNECT_INSTANTLY_SUCCESS';
export const CONNECT_INSTANTLY_FAILURE = 'CONNECT_INSTANTLY_FAILURE';

export const DISCONNECT_INSTANTLY_REQUEST = 'DISCONNECT_INSTANTLY_REQUEST';
export const DISCONNECT_INSTANTLY_SUCCESS = 'DISCONNECT_INSTANTLY_SUCCESS';
export const DISCONNECT_INSTANTLY_FAILURE = 'DISCONNECT_INSTANTLY_FAILURE';

export const instantlyActions = {
  verifyToken,
  getCampaigns,
  connect,
  disconnect,
};

function verifyToken() {
  return async (dispatch: any) => {
    try {
      dispatch(request());
      await InstantlyService.verifyToken();
      dispatch(success());
    } catch (e) {
      dispatch(failure(JSON.parse(e?.request?.response)));
    }
  };

  function request() {
    return {type: VERIFY_INSTANTLY_TOKEN_REQUEST};
  }
  function success() {
    return {type: VERIFY_INSTANTLY_TOKEN_SUCCESS};
  }
  function failure(error: string) {
    return {type: VERIFY_INSTANTLY_TOKEN_FAILURE, error};
  }
}

function getCampaigns() {
  return async (dispatch: any) => {
    try {
      dispatch(request());
      const result = await InstantlyService.getCampaigns();
      dispatch(success(result.data));
    } catch (e) {
      dispatch(failure('There is a problem on our side. Please, try later or reach out the support team.'));
    }
  };

  function request() {
    return {type: GET_INSTANTLY_CAMPAIGNS_REQUEST};
  }
  function success(campaigns: InstantlyCampaign[]) {
    return {type: GET_INSTANTLY_CAMPAIGNS_SUCCESS, campaigns};
  }
  function failure(error: string) {
    return {type: GET_INSTANTLY_CAMPAIGNS_FAILURE, error};
  }
}

const CONNECT_ERROR_CODES = {
  AUTHORIZATION_FAILED: 'authorization_failed',
  BLANK: 'blank',
};

const CONNECT_ERRORS_MESSAGES = {
  AUTHORIZATION_FAILED: 'Failed to authorize. Please, check the API key or reach out the support team.',
  BLANK: 'This field may not be blank.',
};

function connect(api: string) {
  return async (dispatch: any) => {
    try {
      dispatch(request());
      await InstantlyService.connect(api);
      dispatch(success());
    } catch (e) {
      let error = 'There is a problem on our side. Please, try later or reach out the support team.';
      const code = e?.response?.data?.code;
      if (code === CONNECT_ERROR_CODES.AUTHORIZATION_FAILED) {
        error = CONNECT_ERRORS_MESSAGES.AUTHORIZATION_FAILED;
      }
      dispatch(failure(error));
    }
  };

  function request() {
    return {type: CONNECT_INSTANTLY_REQUEST};
  }
  function success() {
    return {type: CONNECT_INSTANTLY_SUCCESS};
  }
  function failure(error: string) {
    return {type: CONNECT_INSTANTLY_FAILURE, error};
  }
}

function disconnect() {
  return async (dispatch: any) => {
    try {
      dispatch(request());
      await InstantlyService.disconnect();
      dispatch(success());
    } catch (e) {
      dispatch(failure(e?.request?.response));
    }
  };

  function request() {
    return {type: DISCONNECT_INSTANTLY_REQUEST};
  }
  function success() {
    return {type: DISCONNECT_INSTANTLY_SUCCESS};
  }
  function failure(error: string) {
    return {type: DISCONNECT_INSTANTLY_FAILURE, error};
  }
}
