import {Spin} from 'antd';
import React from 'react';

import './fullPageLoader.scss';

export const FullPageLoader = () => (
  <div className="fullPageLoader">
    <Spin size="large" />
  </div>
);
