import {Dropdown, Button, Icon, Menu} from 'antd';
import * as React from 'react';

import {STRINGS, LockIcon} from '@growth-x/ui';

import {IntercomService} from '../../services';

export const ProspectsLeftActions = ({handleActionClick, client}: any) => {
  const actionsMenu = (
    <Menu onClick={handleActionClick}>
      <Menu.Item disabled={!client.data_credits} key="enhance">
        Enhance Data
        {!client.data_credits && (
          <LockIcon
            message={STRINGS.message_unlock_feature}
            onClick={() => IntercomService.trackIntercomEvent('enhanced_feature_request', true, !client.data_credits)}
          />
        )}
      </Menu.Item>
      <Menu.Item key="archive">Archive</Menu.Item>
    </Menu>
  );
  return (
    <div className="prospects-page__left-buttons">
      <Dropdown overlay={actionsMenu} trigger={['click']}>
        <Button type="primary" style={{marginRight: '15px'}}>
          Actions <Icon type="down" />
        </Button>
      </Dropdown>
    </div>
  );
};
