import {Form, Input, Button, Icon} from 'antd';
import {Formik} from 'formik';
import React from 'react';
import * as Yup from 'yup';

interface RequestResetPasswordFormProps {
  requestPasswordReset: (email: string) => boolean;
  client: any;
  redirectTo: (route: string) => void;
}

const EmailSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Required'),
});

function RequestResetPasswordForm(props: RequestResetPasswordFormProps) {
  const onSubmit = async (form: any) => {
    const isSent = await props.requestPasswordReset(form.email);
    if (isSent) redirectTo('/login');
  };

  const redirectTo = (route: string) => {
    props.redirectTo(route);
  };
  const {
    client: {loading},
  } = props;
  return (
    <Formik initialValues={{email: ''}} validationSchema={EmailSchema} onSubmit={onSubmit}>
      {({values, handleSubmit, setFieldValue, errors, touched}) => (
        <Form className="login-form" onSubmit={handleSubmit}>
          <Form.Item
            validateStatus={errors.email && touched.email ? 'error' : ''}
            hasFeedback
            help={errors.email && touched.email ? errors.email : ''}
          >
            <Input
              prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}} />}
              type="text"
              placeholder="Email*"
              value={values.email}
              onChange={ev => setFieldValue('email', ev.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

export default RequestResetPasswordForm;
