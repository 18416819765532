import {SfOAuth2Service} from '../../services';

export const SET_SF_AUTHORIZATION_CODE_REQUEST = 'SET_SF_AUTHORIZATION_CODE_REQUEST';
export const SET_SF_AUTHORIZATION_CODE_SUCCESS = 'SET_SF_AUTHORIZATION_CODE_SUCCESS';
export const SET_SF_AUTHORIZATION_CODE_FAILURE = 'SET_SF_AUTHORIZATION_CODE_FAILURE';
export const GET_SF_AUTHORIZED_CREDENTIALS_REQUEST = 'GET_SF_AUTHORIZED_CREDENTIALS_REQUEST';
export const GET_SF_AUTHORIZED_CREDENTIALS_SUCCESS = 'GET_SF_AUTHORIZED_CREDENTIALS_SUCCESS';
export const GET_SF_AUTHORIZED_CREDENTIALS_FAILURE = 'GET_SF_AUTHORIZED_CREDENTIALS_FAILURE';
export const CLEAR_SF_AUTHORIZED_CREDENTIALS_REQUEST = 'CLEAR_SF_AUTHORIZED_CREDENTIALS_REQUEST';
export const CLEAR_SF_AUTHORIZED_CREDENTIALS_SUCCESS = 'CLEAR_SF_AUTHORIZED_CREDENTIALS_SUCCESS';
export const CLEAR_SF_AUTHORIZED_CREDENTIALS_FAILURE = 'CLEAR_SF_AUTHORIZED_CREDENTIALS_FAILURE';
export const WAIT_LOGOUT_REQUEST = 'WAIT_LOGOUT_REQUEST';
export const WAIT_LOGOUT_SUCCESS = 'WAIT_LOGOUT_SUCCESS';

export const sfOauth2Actions = {
  setAuthorizationCode,
  getAuthorizedCredentials,
  clearAuthorizedCredentials,
  waitSfLogoutRequest,
  waitSfLogoutSuccess,
};

function getAuthorizedCredentials() {
  return async (dispatch: any) => {
    dispatch(request());
    return await SfOAuth2Service.getAuthorizedCredentials().then(
      ({data}: any) => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return {type: GET_SF_AUTHORIZED_CREDENTIALS_REQUEST};
  }
  function success(data) {
    return {type: GET_SF_AUTHORIZED_CREDENTIALS_SUCCESS, data};
  }
  function failure(error) {
    return {type: GET_SF_AUTHORIZED_CREDENTIALS_FAILURE, error};
  }
}

function setAuthorizationCode(params) {
  return async (dispatch: any) => {
    dispatch(request());
    return await SfOAuth2Service.setAuthorizationCode(params).then(
      ({data}: any) => {
        dispatch(success(data));
        dispatch(getAuthorizedCredentials());
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return {type: SET_SF_AUTHORIZATION_CODE_REQUEST};
  }
  function success(data) {
    return {type: SET_SF_AUTHORIZATION_CODE_SUCCESS, data};
  }
  function failure(error) {
    return {type: SET_SF_AUTHORIZATION_CODE_FAILURE, error};
  }
}

function clearAuthorizedCredentials() {
  return async (dispatch: any) => {
    dispatch(request());
    return await SfOAuth2Service.clearAuthorizedCredentials().then(
      ({data}: any) => {
        dispatch(success(data));
        dispatch(getAuthorizedCredentials());
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return {type: CLEAR_SF_AUTHORIZED_CREDENTIALS_REQUEST};
  }
  function success(data) {
    return {type: CLEAR_SF_AUTHORIZED_CREDENTIALS_SUCCESS, data};
  }
  function failure(error) {
    return {type: CLEAR_SF_AUTHORIZED_CREDENTIALS_FAILURE, error};
  }
}

function waitSfLogoutRequest() {
  return async (dispatch: any) => {
    dispatch(request());
  };
  function request() {
    return {type: WAIT_LOGOUT_REQUEST};
  }
}

function waitSfLogoutSuccess() {
  return async (dispatch: any) => {
    dispatch(request());
  };
  function request() {
    return {type: WAIT_LOGOUT_SUCCESS};
  }
}
