export class WebSocketClient {
  number = 0; // Message number
  autoReconnectInterval = 5 * 1000; // ms
  url = '';
  instance: any = null;
  onOpen: any;
  onMessage: any;
  onClose: any;
  onError: any;
  shouldReconnect: any;

  open(url: string) {
    this.url = url;
    this.instance = new WebSocket(this.url);
    this.instance.onopen = () => {
      this.onOpen && this.onOpen();
    };
    this.instance.onmessage = (data: any, flags: any) => {
      this.number++;
      this.onMessage(data, flags, this.number);
    };
    this.instance.onclose = (e: any) => {
      switch (e.code) {
        case 1000: // CLOSE_NORMAL
          break;
        default:
          // Abnormal closure
          if (!this.shouldReconnect || this.shouldReconnect()) {
            this.reconnect(e);
          }
          break;
      }
      this.onClose && this.onClose(e);
    };
    this.instance.onerror = (e: any) => {
      switch (e.code) {
        case 'ECONNREFUSED':
          if (!this.shouldReconnect || this.shouldReconnect()) {
            this.reconnect(e);
          }
          break;
        default:
          this.onError && this.onError(e);
          break;
      }
    };
  }

  send(data: any, option?: any) {
    try {
      this.instance.send(data, option);
    } catch (e) {
      this.instance.emit('error', e);
    }
  }

  reconnect(e: any) {
    // console.log(`WebSocketClient: retry in ${this.autoReconnectInterval}ms`,e);
    // this.instance.removeAllListeners();
    var that = this;
    setTimeout(function () {
      // console.log("WebSocketClient: reconnecting...");
      that.open(that.url);
    }, this.autoReconnectInterval);
  }
}
