import React, {useState, useEffect} from 'react';
import './timer.css';

interface TimerProps {
  initialMinutes?: number;
  initialSeconds?: number;
}

export const Timer = (props: TimerProps) => {
  const {initialMinutes = 0, initialSeconds = 0} = props;
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const myInterval = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  const getModifierTextColor = () => {
    if (minutes === 1) {
      return 'text-warning';
    }

    if (minutes === 0) {
      return 'text-danger';
    }

    return '';
  };

  const isTimeOver = minutes === 0 && seconds === 0;

  return (
    <div className="timer__container">
      {isTimeOver ? (
        <p className="timer__text">The time is over, please close this window and try again.</p>
      ) : (
        <p className={`timer__timer ${getModifierTextColor()}`}>
          &nbsp;
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </p>
      )}
    </div>
  );
};
