import * as React from 'react';
import {Tooltip, Icon} from 'antd';

interface LockIconProps {
  message: string;
  filled?: any;
  onClick?: any;
}

export const LockIcon = ({message, filled = 'filled', onClick}: LockIconProps) => (
  <Tooltip title={message}>
    <Icon style={{color: 'var(--color-tooltip-icon)'}} type="lock" theme={filled} onClick={onClick} />
  </Tooltip>
);
