import moment from 'moment';

import {Client} from '@growth-x/types';
import {isCampaignRetention} from '@growth-x/ui';

import {CONSTANTS} from '../../services';
import {defaultColumns} from '../connections/constants';

export function isDuplicateButtonActive(type: string, client: Client) {
  if (type === CONSTANTS.campaignTypes.acquisition_via_import) {
    return !client.import_emails_feature;
  }
  if (type === CONSTANTS.campaignTypes.acquisition_message_request) {
    return !client.message_requests_feature;
  }
  return false;
}

function filterCampaignsByDate(allCampaigns, date, {users, stats, campaigns, campaign_types, actions}) {
  const dateOptions = {
    last_30_days_vs_prev_30_days: 30,
    last_7_days: 7,
    last_30_days: 30,
    last_3_months: 92,
    last_6_months: 183,
    last_12_months: 365,
  };
  const daysInPeriod = dateOptions[date];
  const campaignsStats = stats?.by_campaign || {};
  function compareDate(campaign) {
    const now = moment.utc();
    const creation = moment.utc(campaign.creation_date);
    creation.add(daysInPeriod, 'days');
    return creation.toDate() > now.toDate();
  }

  return allCampaigns.filter(campaign => {
    let shouldShow = true;
    if (users?.length) {
      shouldShow = users.some(userId => String(campaign.selectedUser?.id) === String(userId));
    }
    if (campaigns?.length) {
      shouldShow = shouldShow && campaigns.some(campaignId => String(campaign.id) === String(campaignId));
    }
    if (campaign_types?.length) {
      shouldShow = shouldShow && campaign_types.some(type => String(campaign.type) === String(type));
    }
    if (actions?.length) {
      if (isCampaignRetention(campaign)) {
        shouldShow =
          shouldShow &&
          actions.some(action => ['ON', 'OFF'].includes(action) && !!campaign.messages_active === (action === 'ON'));
      } else {
        shouldShow = shouldShow && actions.some(action => campaign.actions.includes(action));
      }
    }
    shouldShow = shouldShow && (!!campaignsStats[campaign.id] || compareDate(campaign));
    return shouldShow;
  });
}

function filterCampaignsByLifetime(allCampaigns, {users, campaigns, campaign_types, actions}) {
  return allCampaigns.filter(campaign => {
    let shouldShow = true;
    if (users?.length) {
      shouldShow = users.some(userId => String(campaign.selectedUser?.id) === String(userId));
    }
    if (campaigns?.length) {
      shouldShow = shouldShow && campaigns.some(campaignId => String(campaign.id) === String(campaignId));
    }
    if (campaign_types?.length) {
      shouldShow = shouldShow && campaign_types.some(type => String(campaign.type) === String(type));
    }
    if (actions?.length) {
      if (isCampaignRetention(campaign)) {
        shouldShow =
          shouldShow &&
          actions.some(action => ['ON', 'OFF'].includes(action) && !!campaign.messages_active === (action === 'ON'));
      } else {
        shouldShow = shouldShow && actions.some(action => campaign.actions.includes(action));
      }
    }
    return shouldShow;
  });
}

export function getFilteredCampaigns(state) {
  const clientId = state.client.data.id;
  const {users, campaigns, date, campaign_types, actions} = state.settings[clientId].campaignFilters;
  const stats = state.campaigns.campaignsStats;

  if (stats?.by_campaign) {
    if (date === 'lifetime') {
      return filterCampaignsByLifetime(state.campaigns.list, {users, campaigns, campaign_types, actions});
    }
    return filterCampaignsByDate(state.campaigns.list, date, {users, stats, campaigns, campaign_types, actions});
  }
  return state.campaigns.list;
}

export function getSettings(state) {
  const clientId = state.client.data.id;
  const clientSettings = state.settings[clientId];
  return {
    ...clientSettings,
    proxyLocations: state.settings.proxyLocations,
    showConnectionsColumns: clientSettings.showConnectionsColumns || defaultColumns,
  };
}
