import {CONSTANTS} from '../../utils/constants';

class _StatusesMap {
  _validStatuses = new Map();
  _errorStatuses = new Map();
  constructor() {
    this._setValidStatuses();
    this._setErrorStatuses();
  }
  getValidStatuses() {
    return this._validStatuses;
  }
  getErrorStatuses() {
    return this._errorStatuses;
  }
  getAllStatuses() {
    return new Map([...this._validStatuses, ...this._errorStatuses]);
  }
  _setValidStatuses() {
    this._validStatuses.set(CONSTANTS.audience_statuses.created, {
      label: 'to be outreached',
      color: '#74AACC',
      styles: {fontWeight: 'bold'},
      priority: 100,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.invited, {label: 'invited', color: '#3c763d', priority: 98});
    this._validStatuses.set(CONSTANTS.audience_statuses.invited_free_inmail, {
      label: 'invited (free inmail)',
      color: '#51e87e',
      priority: 98,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.invited_credit_used, {
      label: 'invited (with credit)',
      color: '#3c763d',
      priority: 97,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.invites_from_previous_potential, {
      label: 'invited (previous audience)',
      color: '#87e8de',
      tooltip:
        'This campaign was edited and the audience might have changed. This number represents the receivers that were invited using the previous audience.',
      priority: 97,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_tag_not_matched, {
      label: 'excluded (tag change)',
      color: '#74405c',
      priority: 96,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.nurtured, {label: 'nurtured', color: '#98d9d9', priority: 96});
    this._validStatuses.set(CONSTANTS.audience_statuses.manually_sequenced_nurtured, {
      label: 'manually nurtured',
      color: '#9db35c',
      priority: 95,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_no_email, {
      label: 'no email',
      color: '#d05454',
      priority: 94,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.manually_sequenced_created, {
      label: 'to be nurtured (Manually added)',
      color: '#909e68',
      priority: 93,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_pending, {
      label: 'pending',
      color: '#fff566',
      priority: 92,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.already_connected, {
      label: 'already connected',
      color: '#91ffa3',
      priority: 91,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_company_excluded, {
      label: 'excluded (blacklist)',
      color: '#e4b23a',
      priority: 90,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_email_required, {
      label: 'email required',
      color: '#ffbb96',
      priority: 88,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_invite_exist, {
      label: 'excluded (invite exist)',
      color: '#7d4aad',
      priority: 86,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_message_exist, {
      label: 'message exist',
      color: '#1e2c4a',
      priority: 84,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_different_typed_message, {
      label: 'message not match',
      color: '#ffadd2',
      priority: 82,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_different_typed_invite, {
      label: 'message not match',
      color: '#b87cf2',
      priority: 80,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_open_sequence, {
      label: 'open sequence',
      color: '#ff00ff',
      priority: 78,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_exclude_responders, {
      label: 'excluded responders',
      color: '#634c79',
      priority: 76,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_contacted_by_excluded_campaign, {
      label: 'contacted by excluded campaign',
      color: '#c86da4',
      priority: 74,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_not_gx_connection, {
      label: 'not gx connection',
      color: '#36cfc9',
      priority: 72,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_do_not_send_tag, {
      label: 'flagged with #do-not-send',
      color: '#364fc9',
      priority: 73,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_response_or_followup_detected, {
      label: 'response or manual detected',
      color: '#096dd9',
      priority: 70,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_communication_disabled, {
      label: 'communication disabled',
      color: '#8a1c2c',
      priority: 68,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_in_mail, {
      label: 'conversation restricted',
      color: '#722ed1',
      priority: 66,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_profile_url, {
      label: 'broken profile url',
      color: '#10239e',
      priority: 64,
    });

    this._validStatuses.set(CONSTANTS.audience_statuses.error_invalid_email, {
      label: 'invalid email',
      color: '#e46c0d',
      priority: 65,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_email_not_found, {
      label: 'email not found',
      color: '#bb8300',
      priority: 66,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_invalid_profile_url_and_email, {
      label: 'invalid profile url or email',
      color: '#118ab2',
      priority: 67,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_inmail_exist, {
      label: 'inmail request exists',
      color: '#0c675e',
      priority: 64,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_message_requests_exist, {
      label: 'message request exists',
      color: '#adc6ff',
      priority: 63,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_receiver_not_match_with_public_identifier, {
      label: 'receiver not matched',
      color: '#ffd166',
      priority: 62,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_receiver_not_found_in_search_results, {
      label: 'receiver not in search results',
      color: '#ef476f',
      priority: 61,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.error_group_profile_page_search_result_null, {
      label: 'search result is empty',
      color: '#eaff8f',
      priority: 60,
    });
    this._validStatuses.set(CONSTANTS.audience_statuses.errors, {label: 'in review', color: '#bfbfbf', priority: 62});
  }
  _setErrorStatuses() {
    this._errorStatuses.set(CONSTANTS.audience_statuses.error_no_invite_modal, {
      label: 'no invite modal',
      color: '#ff4d4f',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.error_session_expired_modal, {
      label: 'session expired',
      color: '#ffc53d',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.error_invite_with_error, {
      label: 'invite with error',
      color: '#2f54eb',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.error_conversation_failed, {
      label: 'conversation failed',
      color: '#9254de',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.error_file_not_attached, {
      label: 'file not attached',
      color: '#eb2f96',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.error_invite_invalid, {
      label: 'invalid invite',
      color: '#13c2c2',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.error_message_invalid, {
      label: 'invalid message',
      color: '#1890ff',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.error_message_not_allowed, {
      abel: 'message not allowed',
      color: '#d4b106',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.error_message, {label: 'error message', color: '#5b8c00'});
    this._errorStatuses.set(CONSTANTS.audience_statuses.error_has_invite, {label: 'has invite', color: '#d48806'});
    this._errorStatuses.set(CONSTANTS.audience_statuses.error_invite_not_allowed, {
      label: 'invite not allowed',
      color: '#d6e4ff',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.error_profile_id_not_found, {
      label: 'profile id not found',
      color: '#d3adf7',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.error_not_loaded, {label: 'not loaded', color: '#ff85c0'});
    this._errorStatuses.set(CONSTANTS.audience_statuses.error_no_receiver_id, {
      label: 'no receiver id',
      color: '#1a5d56',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.hidden_from_search, {
      label: 'hidden from search',
      color: '#91d5ff',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.campaign_audience_errors_count, {
      label: 'audience error',
      color: '#fffb8f',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.invite_message_429, {
      label: 'too name invites',
      color: '#ae2fe3',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.invite_import_message_500, {
      label: 'acq+ 500 error',
      color: '#ff1b1b',
    });
    this._errorStatuses.set(CONSTANTS.audience_statuses.invite_import_message_400, {
      label: 'acq+ 400 error',
      color: '#ff9929',
    });
  }
}

const StatusesMap = new _StatusesMap();

export default StatusesMap;
