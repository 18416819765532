import {message} from 'antd';

import {NotificationsService} from '../../services';

export const PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS';
export const TOGGLE_NOTIFICATIONS = 'TOGGLE_NOTIFICATIONS';
export const GET_NOTIFICATIONS_LIST_REQUEST = 'GET_NOTIFICATIONS_LIST_REQUEST';
export const GET_NOTIFICATIONS_LIST_SUCCESS = 'GET_NOTIFICATIONS_LIST_SUCCESS';
export const GET_NOTIFICATIONS_LIST_FAILURE = 'GET_NOTIFICATIONS_LIST_FAILURE';
export const LOAD_MORE_NOTIFICATIONS_REQUEST = 'LOAD_MORE_NOTIFICATIONS_REQUEST';
export const LOAD_MORE_NOTIFICATIONS_SUCCESS = 'LOAD_MORE_NOTIFICATIONS_SUCCESS';
export const LOAD_MORE_NOTIFICATIONS_FAILURE = 'LOAD_MORE_NOTIFICATIONS_FAILURE';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
export const READ_NOTIFICATION_FAILURE = 'READ_NOTIFICATION_FAILURE';
export const GET_NOTIFICATION_SETTINGS_REQUEST = 'GET_NOTIFICATION_SETTINGS_REQUEST';
export const GET_NOTIFICATION_SETTINGS_SUCCESS = 'GET_NOTIFICATION_SETTINGS_SUCCESS';
export const SET_NOTIFICATION_SETTINGS_REQUEST = 'SET_NOTIFICATION_SETTINGS_REQUEST';
export const SET_NOTIFICATION_SETTINGS_SUCCESS = 'SET_NOTIFICATION_SETTINGS_SUCCESS';

export const notificationsActions = {
  pushNotifications,
  toggleNotifications,
  getNotificationsList,
  markNotificationAsRead,
  loadMoreNotifications,
  createNotification,
  getNotificationSettings,
  setNotificationSettings,
};

function getNotificationsList() {
  return (dispatch: any) => {
    dispatch(request());
    return NotificationsService.getNotificationsList().then(
      ({data}: any) => {
        dispatch(success(data?.results || [], data?.count || 0, data.next));
      },
      (error: string) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {type: GET_NOTIFICATIONS_LIST_REQUEST};
  }
  function success(notifications: any, unreadNotificationsCount: number, next: string) {
    return {type: GET_NOTIFICATIONS_LIST_SUCCESS, notifications, unreadNotificationsCount, nextUrl: next};
  }
  function failure(error: string) {
    return {type: GET_NOTIFICATIONS_LIST_FAILURE, error};
  }
}

function pushNotifications(notifications: any) {
  return {type: PUSH_NOTIFICATIONS, notifications};
}

function markNotificationAsRead(notification) {
  return (dispatch: any) => {
    if (notification.id) {
      return NotificationsService.readNotification(notification.id).then(
        () => {
          notification.unread = false;
          dispatch(success(notification));
        },
        (error: string) => {
          dispatch(failure(error));
        }
      );
    }
  };
  function success(notification: any) {
    return {type: READ_NOTIFICATION_SUCCESS, payload: notification};
  }
  function failure(error: string) {
    return {type: READ_NOTIFICATION_FAILURE, error};
  }
}

function loadMoreNotifications() {
  return (dispatch: any, getState: any) => {
    dispatch(request());
    const next = getState().notifications.nextUrl;
    if (next) {
      return NotificationsService.loadMoreNotifications(next).then(
        ({data}: any) => {
          dispatch(success(data?.results || [], data?.count || 0, data.next));
        },
        (error: string) => {
          dispatch(failure(error));
        }
      );
    }
  };
  function request() {
    return {type: LOAD_MORE_NOTIFICATIONS_REQUEST};
  }
  function success(notifications: any, unreadNotificationsCount: number, next: string) {
    return {type: LOAD_MORE_NOTIFICATIONS_SUCCESS, notifications, unreadNotificationsCount, nextUrl: next};
  }
  function failure(error: string) {
    return {type: LOAD_MORE_NOTIFICATIONS_FAILURE, error};
  }
}

function createNotification(parameters: any) {
  NotificationsService.createNotification(parameters);
}

function toggleNotifications() {
  return {type: TOGGLE_NOTIFICATIONS};
}

function getNotificationSettings() {
  return (dispatch: any, getState: any) => {
    dispatch(request());
    return NotificationsService.getNotificationSettings().then(
      ({data}: any) => {
        const users = prepareUsers(getState, data?.users);
        dispatch(success({...data, users}));
      },
      () => {
        message.error('Notification settings could not retrieved!');
      }
    );
  };
  function request() {
    return {type: GET_NOTIFICATION_SETTINGS_REQUEST};
  }
  function success(data: any) {
    return {type: GET_NOTIFICATION_SETTINGS_SUCCESS, data};
  }
}

function setNotificationSettings(params) {
  return (dispatch: any, getState: any) => {
    dispatch(request());
    return NotificationsService.setNotificationSettings(params).then(
      ({data}: any) => {
        message.success('Notification settings updated');
        const users = prepareUsers(getState, data?.users);
        dispatch(success({...data, users}));
      },
      () => {
        message.error('Notification settings could not updated!');
      }
    );
  };
  function request() {
    return {type: SET_NOTIFICATION_SETTINGS_REQUEST};
  }
  function success(data: any) {
    return {type: SET_NOTIFICATION_SETTINGS_SUCCESS, data};
  }
}

function prepareUsers(getState, users) {
  const teamMember = getState().client.team_member;
  return (users || []).filter(u => !teamMember || (teamMember?.user && teamMember?.user === u.id));
}
