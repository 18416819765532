import {Form, Input, Button, Icon, Spin} from 'antd';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import './changePasswordForm.scss';

interface ChangePasswordFormProps {
  changePassword: (password: string, token: string) => Promise<boolean>;
  checkPasswordResetToken: (token: string) => Promise<boolean>;
  token: string;
  client: any;
  redirectTo: (route: string) => void;
}

const PasswordSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  passwordConfirmation: Yup.string().test('passwords-match', 'Passwords must match', function (value) {
    return this.parent.password === value;
  }),
});

function ChangePasswordForm(props: ChangePasswordFormProps) {
  const [isValidToken, setIsValidToken] = useState(false);
  const [isValidating, setIsValidating] = useState(true);

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async () => {
    const isValidToken = await props.checkPasswordResetToken(props.token);
    setIsValidToken(isValidToken);
    setIsValidating(false);
  };

  const onSubmit = async (form: any) => {
    const isChanged = await props.changePassword(props.token, form.password);
    if (isChanged) redirectTo('/login');
  };

  const redirectTo = (route: string) => props.redirectTo(route);

  const {
    client: {loading},
  } = props;

  if (isValidating) {
    return (
      <div style={{display: 'flex', height: 60, justifyContent: 'center', alignItems: 'center'}}>
        <Spin />
      </div>
    );
  }

  if (!isValidToken && !isValidating) {
    return (
      <div>
        <div className="reset-password_token-error">Token is expired</div>
        <div style={{marginBottom: '10px'}}>
          <Button type="primary" block onClick={() => redirectTo('reset-password')}>
            Go to Reset Password
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{password: '', passwordConfirmation: ''}}
      validationSchema={PasswordSchema}
      onSubmit={onSubmit}
    >
      {({values, handleSubmit, setFieldValue, errors, touched}) => (
        <Form className="login-form" onSubmit={handleSubmit}>
          <Form.Item
            validateStatus={errors.password && touched.password ? 'error' : ''}
            hasFeedback
            help={errors.password && touched.password ? errors.password : ''}
          >
            <Input
              prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
              type="password"
              placeholder="Password*"
              value={values.password}
              onChange={ev => setFieldValue('password', ev.target.value)}
            />
          </Form.Item>
          <Form.Item
            validateStatus={errors.passwordConfirmation && touched.passwordConfirmation ? 'error' : ''}
            hasFeedback
            help={errors.passwordConfirmation && touched.passwordConfirmation ? errors.passwordConfirmation : ''}
          >
            <Input
              prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
              type="password"
              placeholder="Confirm Password*"
              value={values.passwordConfirmation}
              onChange={ev => setFieldValue('passwordConfirmation', ev.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Change Password
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

export default ChangePasswordForm;
