export class AudienceTreeService {
  client: any;
  constructor({client}: any) {
    this.client = client;
  }
  handleResponse(response: any) {
    if (response.status !== 200) {
      return Promise.reject('Bad request');
    }
    return response;
  }
  getAudienceTree() {
    return this.client.get('api/v1/audience_tree/tree', {}).then(this.handleResponse);
  }
  parseSearchUrl(url: string) {
    return this.client.post('api/v1/audience_tree/sn/parser', {url}).then(this.handleResponse);
  }
}
