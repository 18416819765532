import {Button, Select, Form, Input, Icon} from 'antd';
import React, {useState} from 'react';

import {AdminFeatureIcon} from '@growth-x/ui';

const {Option} = Select;

const SERVER_ARIAS = ['US', 'EU', 'TOKYO', 'SYDNEY'];

const defaultNewServer = {
  username: '',
  server_area: SERVER_ARIAS[0],
};

export function CreateCloudInstance({onSubmit, loading}) {
  const [form, setForm] = useState(defaultNewServer);
  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(form.username, form.server_area);
  };
  return (
    <Form layout="inline" onSubmit={handleSubmit}>
      <Form.Item key={'username'}>
        <Input
          onChange={e => setForm(f => ({...f, username: e.target.value}))}
          prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}} />}
          placeholder="Username"
          required
          value={form.username}
        />
      </Form.Item>
      <Form.Item key={'server_area'}>
        <Select
          value={form.server_area}
          style={{width: 100}}
          onChange={area => {
            setForm(f => ({...f, server_area: area}));
          }}
        >
          {SERVER_ARIAS.map((area, index) => (
            <Option key={`${area}-${index}`} value={area}>
              {area}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button loading={loading} disabled={loading} type="primary" htmlType="submit">
          {loading && ' '}
          <AdminFeatureIcon color="var(--color-white)" /> Create Cloud Instance
        </Button>
      </Form.Item>
    </Form>
  );
}
