import {Button, Form, Input, Alert} from 'antd';
import {Formik} from 'formik';
import React from 'react';
import * as Yup from 'yup';

const PasswordSchema = Yup.object().shape({
  old_password: Yup.string().required('Required'),
  new_password: Yup.string().required('Required'),
  password_confirmation: Yup.string().test('passwords-match', 'Passwords must match', function (value) {
    return this.parent.new_password === value;
  }),
});

export function AccountPassword({loading, error, onSubmit}) {
  const handleSubmit = values => {
    onSubmit(values.old_password, values.new_password);
  };
  return (
    <Formik
      initialValues={{old_password: '', new_password: '', password_confirmation: ''}}
      validationSchema={PasswordSchema}
      onSubmit={handleSubmit}
    >
      {({values, handleSubmit, setFieldValue, errors, touched}) => (
        <Form className="login-form" onSubmit={handleSubmit}>
          <Form.Item
            validateStatus={errors.old_password && touched.old_password ? 'error' : ''}
            hasFeedback
            help={errors.old_password && touched.old_password ? errors.old_password : ''}
            label={'Current Password*'}
          >
            <Input.Password
              allowClear
              type="password"
              value={values.old_password}
              onChange={ev => setFieldValue('old_password', ev.target.value)}
            />
          </Form.Item>
          <Form.Item
            validateStatus={errors.new_password && touched.new_password ? 'error' : ''}
            hasFeedback
            help={errors.new_password && touched.new_password ? errors.new_password : ''}
            label={'New Password*'}
          >
            <Input.Password
              allowClear
              type="password"
              value={values.new_password}
              onChange={ev => setFieldValue('new_password', ev.target.value)}
            />
          </Form.Item>
          <Form.Item
            validateStatus={errors.password_confirmation && touched.password_confirmation ? 'error' : ''}
            hasFeedback
            help={errors.password_confirmation && touched.password_confirmation ? errors.password_confirmation : ''}
            label={'Confirm Password*'}
          >
            <Input.Password
              allowClear
              type="password"
              value={values.password_confirmation}
              onChange={ev => setFieldValue('password_confirmation', ev.target.value)}
            />
          </Form.Item>
          {error && <Alert message={error} type="error" />}
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Save Password
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}
