import {Template} from '@growth-x/types';

export class TemplateService {
  client: any;
  serviceName = 'message_templates';
  constructor({client}: any) {
    this.client = client;
  }
  getAllTemplates() {
    return this.client.get(this.serviceName, {});
  }
  getTemplateById(id: number) {
    return this.client.get(`${this.serviceName}/${id}`, {});
  }
  createTemplate(template: Template) {
    return this.client.post(this.serviceName, template);
  }
  updateTemplate(template: Template) {
    return this.client.patch(`${this.serviceName}/${template.id}`, template);
  }
  deleteTemplate(id: number) {
    return this.client.delete(`${this.serviceName}/${id}`);
  }
}
