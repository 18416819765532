import {connect} from 'react-redux';

import {User} from '@growth-x/types';
import {LazyMembersDetails} from '@growth-x/ui';

import {membersActions} from '../redux/members';

function mapState(state: any) {
  const activeUsers = state.users.list.filter((user: User) => !user.archived);
  return {
    users: activeUsers,
    client: state.client.data,
    members: state.members.list,
    isLoading: state.members.isLoading,
    isAdmin: state.settings.isAdmin,
  };
}

const actionCreators = {
  getMembers: membersActions.getMembers,
  addMember: membersActions.addMember,
  removeMember: membersActions.removeMember,
  updateMember: membersActions.updateMember,
  sendInvitation: membersActions.sendInvitation,
};

export const MembersDetailsContainer = connect(mapState, actionCreators)(LazyMembersDetails);
