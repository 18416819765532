import {
  GET_MEMBERS_REQUEST,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAILURE,
  ADD_MEMBER_REQUEST,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_FAILURE,
  REMOVE_MEMBER_REQUEST,
  REMOVE_MEMBER_SUCCESS,
  REMOVE_MEMBER_FAILURE,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  SEND_INVITATION_REQUEST,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_FAILURE,
  CHECK_INVITATION_REQUEST,
  CHECK_INVITATION_SUCCESS,
  CHECK_INVITATION_FAILURE,
  SET_MEMBER_PASSWORD_REQUEST,
  SET_MEMBER_PASSWORD_SUCCESS,
  SET_MEMBER_PASSWORD_FAILURE,
  SET_CSM_LIST,
} from './membersActions';

import {LOGOUT} from '../client';

const initialState: any = {
  list: [],
  isLoading: false,
  isLoadingInvitation: false,
  isInvitationValid: false,
  invitationError: '',
  csmList: [],
};

export const membersReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_MEMBERS_REQUEST: {
      return {...state, isLoading: true};
    }
    case GET_MEMBERS_SUCCESS: {
      return {...state, isLoading: false, list: action.members};
    }
    case GET_MEMBERS_FAILURE: {
      return {...state, isLoading: false, list: []};
    }
    case ADD_MEMBER_REQUEST: {
      return {...state, isLoading: true};
    }
    case ADD_MEMBER_SUCCESS: {
      return {...state, isLoading: false, list: state.list.concat({...action.member, status: 'IN_PROGRESS'})};
    }
    case ADD_MEMBER_FAILURE: {
      return {...state, isLoading: false};
    }
    case REMOVE_MEMBER_REQUEST: {
      return {...state, isLoading: true};
    }
    case REMOVE_MEMBER_SUCCESS: {
      return {...state, isLoading: false, list: state.list.filter(m => m.id !== action.id)};
    }
    case REMOVE_MEMBER_FAILURE: {
      return {...state, isLoading: false};
    }
    case UPDATE_MEMBER_REQUEST: {
      return {...state, isLoading: true};
    }
    case UPDATE_MEMBER_SUCCESS: {
      const newList = state.list.map(m => (m.id !== action.member.id ? m : {...action.member}));
      return {...state, isLoading: false, list: newList};
    }
    case UPDATE_MEMBER_FAILURE: {
      return {...state, isLoading: false};
    }
    case SEND_INVITATION_REQUEST: {
      return {...state, isLoading: true};
    }
    case SEND_INVITATION_SUCCESS: {
      return {...state, isLoading: false};
    }
    case SEND_INVITATION_FAILURE: {
      return {...state, isLoading: false};
    }
    case CHECK_INVITATION_REQUEST: {
      return {...state, isLoadingInvitation: true, invitationError: '', isInvitationValid: false};
    }
    case CHECK_INVITATION_SUCCESS: {
      return {...state, isLoadingInvitation: false, invitationError: ''};
    }
    case CHECK_INVITATION_FAILURE: {
      return {...state, isLoadingInvitation: false, invitationError: action.error};
    }
    case SET_MEMBER_PASSWORD_REQUEST: {
      return {...state, isLoadingInvitation: true, invitationError: '', isInvitationValid: false};
    }
    case SET_MEMBER_PASSWORD_SUCCESS: {
      return {...state, isLoadingInvitation: false, invitationError: '', isInvitationValid: true};
    }
    case SET_MEMBER_PASSWORD_FAILURE: {
      return {...state, isLoadingInvitation: false, invitationError: action.error};
    }
    case LOGOUT: {
      return {...initialState};
    }
    case SET_CSM_LIST: {
      return {...state, csmList: action.data };
    }
    default:
      return state;
  }
};
