export const formatAttachmentName = (name: string) => {
  if (!name) {
    return;
  }

  if (name.length < 10) {
    return name;
  }

  return `${name.substring(0, 5)}...${name.split('.').pop()}`;
};
