export class UsersService {
  client: any;
  serviceName = 'users';
  constructor({client}: any) {
    this.client = client;
  }
  getUsersByClient(clientId: string | number) {
    return this.client.get(this.serviceName, {
      client_id: clientId,
      get_archived: true,
      api_feature_toggle: 'jsonify_cookies',
    });
  }
  create(user: any) {
    return this.client.post(this.serviceName, user);
  }
  update(user: any) {
    return this.client.put(this.serviceName, user);
  }
  loadUserStats(days: number) {
    return this.client.get(this.serviceName, {method: 'users_stats', days});
  }
  archiveUser(id: number) {
    return this.client.put(this.serviceName, {id, method: 'archive'});
  }
  validatePassword(id: number, password: string) {
    return this.client.post('api/v2/users/validate_password', {user_id: id, password});
  }
  unarchiveUser(id: number) {
    return this.client.post('admin_maintenance/unarchive_user', {user_id: id});
  }
  revealPassword(id: number) {
    return this.client.post('admin_maintenance/reveal_user_password', {user_id: id});
  }
  changeUserState(id: number, value: boolean) {
    return this.client.post('admin_maintenance/change_user_state', {user_id: id, value});
  }
  changeClientEmail(newEmail: string) {
    return this.client.post('admin_maintenance/change_client_email', {new_email: newEmail});
  }
  changeClientName(newName: string) {
    return this.client.post('admin_maintenance/change_client_name', {new_name: newName});
  }
  changeManagerData(values: any) {
    return this.client.post('admin_maintenance/change_manager_data', values);
  }
  snoozeLoginError(id: number, days: number) {
    return this.client.post('admin_maintenance/mark_as_managed', {user_id: id, until: days});
  }
  rescanUserCampaign(campaign_id: number, user_id: number) {
    return this.client.put(this.serviceName, {
      user_id,
      campaign_id,
      loop: 0,
      page: 1,
    });
  }
  updateError(user: any) {
    const parameters = {
      id: user.id,
      method: 'update_error',
      error_code: user.error_code || '',
    };

    return this.client.put(this.serviceName, parameters);
  }
  updateWarning(user: any) {
    const parameters = {
      id: user.id,
      method: 'update_warning_code',
      warning_code: user.warning_code || '',
    };

    return this.client.put(this.serviceName, parameters);
  }
  updateSnSubscriptionSelect(userId, value) {
    const parameters = {
      id: userId,
      method: 'update_sn_selection',
      sn_code: value || 1,
    };
    return this.client.put(this.serviceName, parameters);
  }
  updateCookies(userId, cookies) {
    const parameters = {
      id: userId,
      method: 'cookies',
      api_feature_toggle: 'jsonify_cookies',
      cookies,
    };
    return this.client.put(this.serviceName, parameters);
  }
  updateLoginErrorDate(userId, externalParams = {}) {
    const parameters = {
      id: userId,
      method: 'last_login_error_date',
      ...externalParams,
    };
    return this.client.put(this.serviceName, parameters);
  }
  scheduleArchive(user: number, archive_date: string) {
    return this.client.post(`admin_maintenance/schedule-user-archive`, {user, archive_date});
  }
  cancelScheduledArchive(user: number) {
    return this.client.post(`admin_maintenance/cancel-user-scheduled-archive`, {user});
  }
}
