export const SET_HEADER_MODAL = 'SET_HEADER_MODAL';

export const headerModalActions = {
  setHeaderModal,
};

function setHeaderModal(modal) {
  return (dispatch: any) => {
    dispatch({type: SET_HEADER_MODAL, headerModal: modal});
  };
}
