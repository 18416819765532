import {Select, Button, Icon} from 'antd';
import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const {Option} = Select;

export const UsersFilter = ({handleChange, users, filters, clearAndClose}) => {
  return (
    <div className="campaigns-page__filter-wrapper">
      <Icon>
        <FontAwesomeIcon icon={['fas', 'users']} />
      </Icon>
      <Select
        mode="multiple"
        maxTagCount={1}
        placeholder="Select users"
        onChange={handleChange}
        value={users.length > 0 ? filters.users : undefined}
        className="campaigns-page__filter-input"
      >
        {users.map(user => (
          <Option key={user.id}>{user.first_name}</Option>
        ))}
      </Select>
      <Button onClick={clearAndClose} icon="close" type="link" className="campaigns-page__close-filter" size="small" />
    </div>
  );
};
