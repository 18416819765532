import * as React from 'react';
import {Icon} from 'antd';
import {formatAttachmentName} from '../../utils/functions/formatAttachmentName';

interface ConversationMessageAttachmentProps {
  messageContent: string;
  attachment_name: string;
}

export const ConversationMessageAttachment = ({
  messageContent,
  attachment_name,
}: ConversationMessageAttachmentProps) => {
  if (!messageContent) {
    return (
      <li style={{listStyle: 'none'}} className="conversation__message-attachment">
        <Icon type="file" style={{marginRight: '0.2em'}} />
        {formatAttachmentName(attachment_name)}
      </li>
    );
  } else {
    return (
      <li style={{listStyle: 'none'}}>
        <Icon type="file" style={{marginRight: '0.2em'}} />
        {formatAttachmentName(attachment_name)}
      </li>
    );
  }
};
