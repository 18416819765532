import {Dropdown, Menu, Button, Switch, Tooltip, Modal, Popconfirm} from 'antd';
import React, {useEffect, useMemo, useState} from 'react';

import {LockIcon, STRINGS, brandConfig, isCampaignAcquisition} from '@growth-x/ui';

import {IntercomService} from '../../services';

export const CampaignsLeftActions = ({
  client,
  campaigns,
  showInitialReviewButton,
  onCreateCampaign,
  requestInitialReview,
  reviewRequested,
  handleChangeRunState,
  settings,
  setSettings,
}: any) => {
  const [showToggleCampaignsTip, setShowToggleCampaignsTip] = useState(true);
  useEffect(() => {
    const showNewClientRunCampaignsTip = settings?.showNewClientRunCampaignsTip;
    const isSomeCampaignWasRunning = campaigns.some(c => c?.stats?.reach);
    const isNewCampaignReady = campaigns.some(
      c => c?.selectedUser && !c?.selectedUser?.error_code && c?.actions?.length
    );
    setShowToggleCampaignsTip(
      showNewClientRunCampaignsTip && !client.enabled && isNewCampaignReady && !isSomeCampaignWasRunning
    );
  }, [campaigns]);
  const runBotTooltip = useMemo(() => {
    if (!client.active) {
      if (brandConfig.isLeadoku) return STRINGS.info_demo_leadoku;
      return STRINGS.info_demo;
    }
    return '';
  }, [client]);
  const checkAndCreateCampaign = params => {
    if (params.key === 'acquisition_inmail') {
      const hasAcquisitionCampaigns = campaigns.find(campaign => isCampaignAcquisition(campaign));
      if (hasAcquisitionCampaigns) {
        onCreateCampaign(params);
      } else {
        Modal.confirm({
          title: 'Are you sure you want to start with an inmail campaign?',
          content:
            'We recommend starting with Acquisition campaigns, based on our experience, they generate twice as many responses as inMail campaigns.',
          onOk: () => onCreateCampaign(params),
          onCancel: undefined,
        });
      }
    } else {
      onCreateCampaign(params);
    }
  };
  return (
    <div className="campaigns-page__left-controls">
      <Dropdown
        trigger={['click']}
        placement="bottomLeft"
        overlay={
          <Menu onClick={checkAndCreateCampaign}>
            <Menu.Item className="highlighted-campaign-type" key="acquisition">
              Acquisition
            </Menu.Item>
            {/*<Menu.Item disabled={!client.import_emails_feature} key="acquisition_via_import">*/}
            {/*  Acquisition +*/}
            {/*</Menu.Item>*/}
            {/* {client.message_requests_feature && (
              <Menu.Item key="acquisition_message_request">Acquisition (Groups) Beta</Menu.Item>
            )} */}
            <Menu.Item
              key="retention"
              className="highlighted-campaign-type"
              disabled={!client.premium_feature}
              style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
            >
              Nurturing
              {!client.premium_feature && (
                <LockIcon
                  message={STRINGS.message_unlock_feature}
                  onClick={() =>
                    IntercomService.trackIntercomEvent('retention_feature_request', true, !client.premium_feature)
                  }
                />
              )}
            </Menu.Item>
            {client.inmail_feature && <Menu.Item key="acquisition_inmail">Acquisition+ (inMail) Beta</Menu.Item>}
          </Menu>
        }
      >
        <Button type="primary">New Campaign</Button>
      </Dropdown>
      {showInitialReviewButton && (
        <Button style={{marginLeft: '10px'}} type="primary" onClick={requestInitialReview}>
          Request Initial Review
        </Button>
      )}
      {!showInitialReviewButton && reviewRequested && (
        <div style={{display: 'inline-block', padding: '8px 5px 5px 5px'}}>{STRINGS.info.campaigns_review}</div>
      )}
      <Popconfirm
        className="campaigns-page__toggle-tip"
        placement="bottomLeft"
        title={'Click here to run campaigns'}
        onConfirm={() => {
          setSettings({showNewClientRunCampaignsTip: false})
          setShowToggleCampaignsTip(false)
        }}
        okText="Ok"
        cancelButtonProps={{style: {display: 'none'}}}
        visible={showToggleCampaignsTip}
      >
        <Tooltip title={runBotTooltip}>
          <Switch
            disabled={!campaigns && !campaigns.length}
            checked={!!client.enabled}
            style={{marginLeft: '10px'}}
            onChange={handleChangeRunState}
          />
        </Tooltip>
      </Popconfirm>
    </div>
  );
};
