import {Row, Col, Collapse, Spin} from 'antd';
import moment from 'moment';
import React, {useEffect, useState} from 'react';

import {AdminFeatureIcon} from '@growth-x/ui';

export function SubscriptionInfo({getSubscriptionInfo}) {
  const [details, setDetails] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const getDetails = async function () {
    try {
      const data = await getSubscriptionInfo();
      setDetails(data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  useEffect(() => {
    getDetails();
  }, []);

  if (!loading && !details) return null;

  if (loading) return <Spin />;

  return (
    <div>
      <Collapse>
        <Collapse.Panel
          header={
            <b>
              <AdminFeatureIcon /> Subscription Info:
            </b>
          }
          key="1"
        >
          <Row type="flex" align="middle" gutter={[16, 16]}>
            {details.subscription_started_date && (
              <>
                <Col span={12}>
                  <b>Subscription date:</b>
                </Col>
                <Col span={12}>{moment(details.subscription_started_date * 1000).format('MM/DD/YYYY')}</Col>
              </>
            )}
            {details.next_renewal_date && (
              <>
                <Col span={12}>
                  <b>Next renewal date:</b>
                </Col>
                <Col span={12}>{moment(details.next_renewal_date * 1000).format('MM/DD/YYYY')}</Col>
              </>
            )}
            <Col span={12}>
              <b>Total subscriptions:</b>
            </Col>
            <Col span={12}>{details.total_subscriptions}</Col>
            <Col span={12}>
              <b>Subscription MRR:</b>
            </Col>
            <Col span={12}>${details.subscription_mrr}</Col>
            <Col span={12}>
              <b>Total MRR:</b>
            </Col>
            <Col span={12}>${details.total_mrr}</Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}
