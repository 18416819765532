import {Empty, Input, Menu, Icon} from 'antd';
import React, {useEffect, useRef} from 'react';

const {Search} = Input;

export function TemplatesMenu({
  searchText,
  filteredTemplates,
  filterTemplates,
  inputTemplate,
  createTemplate,
  onClose,
  className,
}) {
  const templatesRef = useRef(null);

  useEffect(() => {
    const closeMenu = function (e) {
      if (
        !templatesRef.current?.contains(e.target) &&
        typeof e.target.className === 'string' &&
        !e.target.className.includes(className)
      ) {
        onClose();
      }
    };

    window.addEventListener('click', closeMenu);

    return () => {
      window.removeEventListener('click', closeMenu);
    };
  }, []);

  return (
    <div ref={templatesRef}>
      <Search className={className} onChange={event => filterTemplates(event.target.value)} value={searchText} />
      {createTemplate && (
        <Menu
          onClick={() => {
            onClose();
            createTemplate();
          }}
        >
          <Menu.Item key={'new'}>
            <Icon type="file-add" /> New Template
          </Menu.Item>
        </Menu>
      )}
      {filteredTemplates && (
        <Menu onClick={inputTemplate}>
          {filteredTemplates.map(template => (
            <Menu.Item key={template.id}>{template.title}</Menu.Item>
          ))}
        </Menu>
      )}
      {(!filteredTemplates || !filteredTemplates.length) && (
        <div style={{background: '#fff'}}>
          <Empty />
        </div>
      )}
    </div>
  );
}
