import {Button, Spin} from 'antd';
import React from 'react';

import MemberError from './components/memberError/memberError';
import MemberSetPassword from './components/memberSetPassword/memberSetPassword';
import MemberSuccess from './components/memberSuccess/memberSuccess';

export function MemberInvitation({error, isValid, isLoading, onSubmit}) {
  if (isLoading) {
    return <Spin />;
  }

  return (
    <>
      {!error && !isValid && <MemberSetPassword onSubmit={onSubmit} />}
      {error && <MemberError error={error} />}
      {!error && isValid && <MemberSuccess />}
      {<Button href="/">Go to main page</Button>}
    </>
  );
}
