import {Input} from 'antd';
import React, {useState} from 'react';

import './dealsAmount.css';

const {Search} = Input;

export const DealsAmount = ({updateDealsAmount, dealsNoteUpdating}) => {
  const [inputDealsAmountValue, setDealsAmountValue] = useState<string>('0');
  return (
    <Search
      style={{width: 160}}
      placeholder="Deal Amount"
      className="deals-amount__input"
      enterButton="Ok"
      size="small"
      type="number"
      loading={dealsNoteUpdating}
      value={inputDealsAmountValue}
      onChange={(e: any) => setDealsAmountValue(e.target.value)}
      onSearch={updateDealsAmount}
    />
  );
};
