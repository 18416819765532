import {Form, Input, Button, Icon, Alert} from 'antd';
import {Formik} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import {ClientData} from '@growth-x/types';

import './loginForm.css';

interface LoginFormProps {
  client: ClientData;
  login: (email: string, password: string) => void;
}

const LoginSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email format').required('Required'),
});

export class LoginForm extends React.Component<LoginFormProps> {
  constructor(props: any) {
    super(props);
  }
  componentWillUnmount() {}
  onSubmit = (form: any) => this.props.login(form.email, form.password);
  render() {
    const {
      client: {status, loading},
    } = this.props;
    return (
      <Formik initialValues={{email: '', password: ''}} validationSchema={LoginSchema} onSubmit={this.onSubmit}>
        {({values, handleSubmit, setFieldValue, errors, touched}) => (
          <Form className="login-form" onSubmit={handleSubmit}>
            <Form.Item
              validateStatus={errors.email && touched.email ? 'error' : ''}
              hasFeedback
              help={errors.email && touched.email ? errors.email : ''}
            >
              <Input
                prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}} />}
                type="text"
                placeholder="Email*"
                value={values.email}
                onChange={ev => setFieldValue('email', ev.target.value)}
              />
            </Form.Item>
            <Form.Item
              validateStatus={errors.password && touched.password ? 'error' : ''}
              hasFeedback
              help={errors.password && touched.password ? errors.password : ''}
            >
              <Input
                prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
                type="password"
                placeholder="Password*"
                value={values.password}
                onChange={ev => setFieldValue('password', ev.target.value)}
              />
            </Form.Item>
            {!!status.length && (
              <Alert message="Log in failed" description={status} type="error" style={{marginBottom: '10px'}} />
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Log in
              </Button>
            </Form.Item>
          </Form>
        )}
      </Formik>
    );
  }
}
