import {Typography} from 'antd';
import React from 'react';

import {ReactComponent as Bender} from './bender.svg';

const {Text, Title} = Typography;

function MemberError({error}) {
  return (
    <div style={{marginBottom: '24px'}}>
      <div>
        <Bender />
        <Title level={3} style={{color: '#148CFA'}}>
          400 Error
        </Title>
      </div>
      <div>
        <Text type="danger" style={{fontSize: '1.3em'}}>
          {error}
        </Text>
      </div>
    </div>
  );
}

export default MemberError;
