import {Form, Input, Button, Icon, Alert, Select} from 'antd';
import {Formik} from 'formik';
import * as React from 'react';
import * as Yup from 'yup';

import {ClientData} from '@growth-x/types';

import {brandConfig, STRINGS} from '../..';
import './registerForm.css';

interface RegisterFormProps {
  client: ClientData;
  signup: (name: string, email: string, password: string, heard_from: string) => Promise<boolean>;
}

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email format').required('Required'),
  heard_from: Yup.string().max(300, 'Allowed 300 characters max'),
});

const HEAR_FROM = [
  {id: 1, value: 'Facebook'},
  {id: 2, value: 'LinkedIn'},
  {id: 3, value: 'Email'},
  {id: 4, value: 'Referral'},
  {id: 5, value: 'Search Engine'},
  {id: 6, value: 'Other'},
];

export class RegisterForm extends React.Component<RegisterFormProps> {
  state = {
    hearFrom: HEAR_FROM,
    otherChoosen: false,
    heard_from: '',
  };

  onSubmit = async (form: any) => {
    const isCreated = await this.props.signup(
      form.name,
      form.email,
      form.password,
      form.hearFrom !== 'Other' ? form.hearFrom : form.heard_from
    );
    if (isCreated) {
      if (brandConfig.isLeadoku || brandConfig.isGx) {
        (window as any).dataLayer.push({event: 'form_submitted'});
      }
    }
  };
  render() {
    const {
      client: {status, loading},
    } = this.props;
    return (
      <Formik
        initialValues={{name: '', email: '', password: '', heard_from: ''}}
        validationSchema={RegisterSchema}
        onSubmit={this.onSubmit}
      >
        {({values, handleSubmit, setFieldValue, errors, touched}: any) => (
          <Form className="register-form" onSubmit={handleSubmit}>
            <Form.Item
              validateStatus={errors.name && touched.name ? 'error' : ''}
              hasFeedback
              help={errors.name && touched.name ? errors.name : ''}
            >
              <Input
                prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}} />}
                value={values.name}
                onChange={(ev: any) => setFieldValue('name', ev.target.value)}
                placeholder="First Name*"
              />
            </Form.Item>
            <Form.Item
              validateStatus={errors.email && touched.email ? 'error' : ''}
              hasFeedback
              help={errors.email && touched.email ? errors.email : ''}
            >
              <Input
                prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}} />}
                type="text"
                placeholder="Email*"
                autoComplete="off"
                value={values.email}
                onChange={(ev: any) => setFieldValue('email', ev.target.value)}
              />
            </Form.Item>
            <Form.Item
              validateStatus={errors.password && touched.password ? 'error' : ''}
              hasFeedback
              help={errors.password && touched.password ? errors.password : ''}
            >
              <Input
                prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
                type="password"
                autoComplete="off"
                placeholder="Password*"
                value={values.password}
                onChange={(ev: any) => setFieldValue('password', ev.target.value)}
              />
            </Form.Item>

            <Form.Item>
              <Select
                placeholder="How did you hear about us?"
                value={values.hearFrom}
                onChange={(val: any) => {
                  setFieldValue('hearFrom', val);
                  if (val === 'Other') this.setState({otherChoosen: true});
                  else {
                    this.setState({otherChoosen: false});
                  }
                }}
              >
                {this.state.hearFrom.map(hearItem => {
                  return (
                    <Select.Option key={hearItem.id} value={hearItem.value}>
                      {`${hearItem.value}`}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {this.state.otherChoosen ? (
              <Form.Item validateStatus={errors.heard_from && touched.heard_from ? 'error' : ''}>
                <Input.TextArea
                  placeholder={'Input your option here'}
                  autoSize={{minRows: 1, maxRows: 10}}
                  value={values.heard_from}
                  onChange={(ev: any) => setFieldValue('heard_from', ev.target.value)}
                />
                {
                  <div style={{justifyContent: 'space-between', display: 'flex'}}>
                    <span style={errors.heard_from ? {color: 'red'} : {}}>
                      {errors.heard_from ? 'Allowed 300 characters max ' : ''}
                    </span>
                    <span style={errors.heard_from ? {color: 'red'} : {}}>{values.heard_from.length}/300</span>
                  </div>
                }
              </Form.Item>
            ) : (
              <div />
            )}
            {!!status.length && (
              <Alert message="Sign up failed" description={status} type="error" style={{marginBottom: '10px'}} />
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Sign up
              </Button>
              {!brandConfig.isAffiliate && (
                <span className="register-form_licence">
                  By signing up you agree to our{' '}
                  <a href={STRINGS.url.terms} target="_blank" rel="noopener noreferrer">
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a href={STRINGS.url.privacy} target="_blank">
                    Privacy Policy
                  </a>
                </span>
              )}
            </Form.Item>
          </Form>
        )}
      </Formik>
    );
  }
}
