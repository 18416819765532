export class AdminMaintenanceService {
  client: any;
  constructor({client}: any) {
    this.client = client;
  }
  serviceName = 'admin_maintenance';
  getCloudInstance() {
    return this.client.get(`${this.serviceName}/get_cloud_instance`, {}).then(res => res.data);
  }
  createCloudInstance(username, server_area) {
    return this.client.post(`${this.serviceName}/create_cloud_instance`, {username, server_area});
  }
  deleteCloudInstance() {
    return this.client.post(`${this.serviceName}/delete_cloud_instance`, {});
  }
  forceRebootCloudInstance() {
    return this.client.post(`${this.serviceName}/force_reboot_cloud_instance`, {});
  }
  delayDeleteCloudInstance(days) {
    return this.client.post(`${this.serviceName}/delay_delete_cloud_instance`, {days});
  }
  archiveUser(id: number, allowOtherUsersToContact: boolean, changeChargebeeUsers: boolean) {
    return this.client.post(`${this.serviceName}/archive_user`, {
      user_id: id,
      allow_other_users_to_contact: allowOtherUsersToContact,
      change_chargebee_users: changeChargebeeUsers,
    });
  }
  getSubscriptionInfo() {
    return this.client.get('billing/billing_details', {}).then(res => res.data);
  }
}
