import {message} from 'antd';

import {AdminMaintenanceService} from '../../services';

export const SET_INSTANCE_SUCCESS = 'SET_INSTANCE_SUCCESS';
export const SET_PROGRESS_DONE = 'SET_PROGRESS_DONE';
export const SET_PROGRESS_PERCENTAGE = 'SET_PROGRESS_PERCENTAGE';
export const CREATE_INSTANCE_REQUEST = 'CREATE_INSTANCE_REQUEST';
export const CREATE_INSTANCE_SUCCESS = 'CREATE_INSTANCE_SUCCESS';
export const CREATE_INSTANCE_FAILURE = 'CREATE_INSTANCE_FAILURE';
export const GET_INSTANCE_REQUEST = 'GET_INSTANCE_REQUEST';
export const GET_INSTANCE_SUCCESS = 'GET_INSTANCE_SUCCESS';
export const GET_INSTANCE_FAILURE = 'GET_INSTANCE_FAILURE';
export const REBOOT_INSTANCE_REQUEST = 'REBOOT_INSTANCE_REQUEST';
export const REBOOT_INSTANCE_SUCCESS = 'REBOOT_INSTANCE_SUCCESS';
export const REBOOT_INSTANCE_FAILURE = 'REBOOT_INSTANCE_FAILURE';
export const DELETE_INSTANCE_REQUEST = 'DELETE_INSTANCE_REQUEST';
export const DELETE_INSTANCE_SUCCESS = 'DELETE_INSTANCE_SUCCESS';
export const DELETE_INSTANCE_FAILURE = 'DELETE_INSTANCE_FAILURE';

export const adminMaintenanceActions = {
  setStateChange,
  createCloudInstance,
  getCloudInstance,
  forceRebootCloudInstance,
  deleteCloudInstance,
  delayDeleteCloudInstance,
  setProgressPercentage,
  setProgressDone,
  getSubscriptionInfo,
};

function setStateChange(data) {
  return (dispatch: any) => {
    if (data) dispatch(set(data));
  };

  function set(data) {
    return {type: SET_INSTANCE_SUCCESS, data};
  }
}

function setProgressDone() {
  return (dispatch: any) => {
    dispatch(setDone());
  };

  function setDone() {
    return {type: SET_PROGRESS_DONE};
  }
}

function setProgressPercentage(data) {
  return (dispatch: any) => {
    dispatch(set(data));
  };

  function set(data) {
    return {type: SET_PROGRESS_PERCENTAGE, data};
  }
}

function getCloudInstance() {
  return async (dispatch: any) => {
    dispatch(request());
    await AdminMaintenanceService.getCloudInstance().then(
      (data: any) => {
        if (data) dispatch(success(data));
      },
      () => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return {type: GET_INSTANCE_REQUEST};
  }
  function success(data) {
    return {type: GET_INSTANCE_SUCCESS, data};
  }
  function failure() {
    return {type: GET_INSTANCE_FAILURE};
  }
}

function createCloudInstance(username, server_area) {
  return async (dispatch: any) => {
    dispatch(request());
    await AdminMaintenanceService.createCloudInstance(username, server_area).then(
      (data: any) => {
        if (data) {
          dispatch(success(data));
          message.success('Cloud instance is creating. Should be ready in 10 minutes.');
        }
      },
      () => {
        dispatch(failure());
        message.error('Some issues occurred. Please, contact the dev team.');
      }
    );
  };

  function request() {
    return {type: CREATE_INSTANCE_REQUEST};
  }
  function success(data) {
    return {type: CREATE_INSTANCE_SUCCESS, data};
  }
  function failure() {
    return {type: CREATE_INSTANCE_FAILURE};
  }
}

function forceRebootCloudInstance() {
  return async (dispatch: any) => {
    dispatch(request());
    await AdminMaintenanceService.forceRebootCloudInstance().then(
      (data: any) => {
        if (data) {
          message.success('Cloud instance is rebooting. Should be ready in 5 minutes.');
        }
      },
      () => {
        dispatch(failure());
        message.error('Some issues occurred. Please, contact the dev team.');
      }
    );
  };

  function request() {
    return {type: REBOOT_INSTANCE_REQUEST};
  }
  function failure() {
    return {type: REBOOT_INSTANCE_FAILURE};
  }
}

function deleteCloudInstance() {
  return async (dispatch: any) => {
    dispatch(request());
    await AdminMaintenanceService.deleteCloudInstance().then(
      (data: any) => {
        if (data) {
          dispatch(success(data));
          message.success(`Cloud instance is deleting.`);
        }
      },
      () => {
        dispatch(failure());
        message.error('Some issues occurred. Please, contact the dev team.');
      }
    );
  };

  function request() {
    return {type: DELETE_INSTANCE_REQUEST};
  }
  function success(data) {
    return {type: DELETE_INSTANCE_SUCCESS, data};
  }
  function failure() {
    return {type: DELETE_INSTANCE_FAILURE};
  }
}

function delayDeleteCloudInstance(days) {
  return async (dispatch: any) => {
    dispatch(request());
    await AdminMaintenanceService.delayDeleteCloudInstance(days).then(
      (data: any) => {
        if (data) {
          dispatch(success());
          message.success(`Cloud instance will be deleted in ${days} ${days > 1 ? 'days' : 'day'}.`);
        }
      },
      () => {
        dispatch(failure());
        message.error('Some issues occurred. Please, contact the dev team.');
      }
    );
  };

  function request() {
    return {type: DELETE_INSTANCE_REQUEST};
  }
  function success() {
    return {type: DELETE_INSTANCE_SUCCESS, clientInstance: null};
  }
  function failure() {
    return {type: DELETE_INSTANCE_FAILURE};
  }
}

function getSubscriptionInfo() {
  return async () => {
    return AdminMaintenanceService.getSubscriptionInfo();
  };
}
