import React from 'react';
import {CSVLink} from 'react-csv';

interface Props {
  text: string;
  fileName: string;
  data: [];
  headers: any;
}

export const ExportCsv: React.FC<Props> = ({text, fileName, data, headers}) => {
  return (
    <CSVLink
      filename={fileName}
      data={data}
      headers={headers}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {text}
    </CSVLink>
  );
};
