import {Icon, Popover, Input} from 'antd';
import React, {useState} from 'react';

const {TextArea} = Input;

export const SuggestionFeedback = ({onSave, children, visible, setVisible, conversation}: any) => {
  const [suggestionRate, setSuggestionRate] = useState(null);
  const [showComment, setShowComment] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState('');

  const setRate = rate => {
    setSuggestionRate(rate);
    if (rate <= 3) {
      setShowComment(true);
    }
  };
  const getStar = number => {
    return (
      <Icon
        key={number}
        type="star"
        onClick={() => setRate(number)}
        theme={suggestionRate >= number ? 'filled' : 'outlined'}
      />
    );
  };
  return (
    <Popover
      title={<p style={{textAlign:'center'}}>Are you satisfied with<br/> the suggestion?</p>}
      visible={visible}
      trigger="click"
      onVisibleChange={visible => {
        if (!visible) {
          if (suggestionRate) {
            onSave(conversation, suggestionRate, feedbackComment);
            setFeedbackComment('');
            setRate(null);
          }
          setVisible(false);
        }
      }}
      content={
        <div className="suggestion-feedback">
          <div className="suggestion-feedback__rate">
            {Array.from({length: 5}, (_, i) => i + 1).map(number => getStar(number))}
          </div>
          {showComment && (
            <div className="suggestion-feedback__comment">
              <TextArea
                onChange={e => setFeedbackComment(e.target.value)}
                value={feedbackComment}
                placeholder="Write your comment about the suggestion"
              />
            </div>
          )}
        </div>
      }
    >
      {children}
    </Popover>
  );
};
