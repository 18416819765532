import {Button, Icon} from 'antd';
import React from 'react';

export const RemoveFollowUpButton = ({onClick}) => {
  return (
    <Button type="link" style={{color: '#d22528', padding: 0}} onClick={onClick}>
      <Icon type="close-circle" />
    </Button>
  );
};
