import {message} from 'antd';

import {ClientMaintenanceService} from '../../services';
import {campaignsActions} from '../campaigns';
import {usersActions} from '../users';

export const GET_CLIENTS_MAINTENANCE = 'GET_CLIENTS_MAINTENANCE';

export const clientMaintenanceActions = {
  getAffiliatedClients,
  moveUser,
};

function getAffiliatedClients() {
  return (dispatch: any) => {
    ClientMaintenanceService.getAffiliatedClients().then(
      (res: any) => {
        dispatch(success(res.data.clients));
      },
      () => message.error('Something went wrong. Please try again later')
    );
  };

  function success(affiliatedClients) {
    return {type: GET_CLIENTS_MAINTENANCE, affiliatedClients};
  }
}

function moveUser(params, withCampaigns) {
  return (dispatch: any, getState: any) => {
    const resultPromise = withCampaigns ? ClientMaintenanceService.moveUser(params) : ClientMaintenanceService.forceMoveUser(params);
    resultPromise.then(
      (res: any) => {
        if (res.data.status === 'scheduled') {
          const clientId = getState().client.id;
          dispatch(usersActions.getUsers(clientId)).then(() => dispatch(campaignsActions.getCampaigns(clientId)));
          message.success('User successfully moved to client');
        }
      },
      () => message.error('Something went wrong. Please try again later')
    );
  };
}
