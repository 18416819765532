export const selectHoldingShift = (id, totalKeys, selectedKeys, lastSelected) => {
  const firstSelected = totalKeys.indexOf(lastSelected);
  const lastSelection = totalKeys.indexOf(id);

  const [from, to] = [firstSelected, lastSelection].sort((a, b) => a - b);

  return {
    newSelectedKeys: [...new Set([...selectedKeys, ...totalKeys.slice(from, to + 1)])],
    lastSelection: id,
  };
};

export const selectHoldingCtrl = (id, totalKeys, selectedKeys) => {
  const auxSelectedKeys = [...selectedKeys];
  let auxLastSelection = '';
  if (auxSelectedKeys.indexOf(id) >= 0) {
    auxSelectedKeys.splice(auxSelectedKeys.indexOf(id), 1);
  } else {
    auxSelectedKeys.push(id);
    auxLastSelection = id;
  }

  auxSelectedKeys.sort((a, b) => totalKeys.indexOf(a) - totalKeys.indexOf(b));

  return {
    newSelectedKeys: auxSelectedKeys,
    lastSelection: auxLastSelection,
  };
};
