import React from 'react';

import {NoVNCConnection, ManageCloudInstance} from '@growth-x/ui';

export function AdminTools({
  client,
  isAdmin,
  clientInstance,
  getLoading,
  updateLoading,
  getCloudInstance,
  createCloudInstance,
  forceRebootCloudInstance,
  delayDeleteCloudInstance,
  deleteCloudInstance,
  setProgressPercentage,
  setProgressDone,
  getClient,
}) {
  const isNoVncAvailable = client.c && (isAdmin || client.manage_cloud_feature);
  return (
    <div>
      {isAdmin && (
        <ManageCloudInstance
          clientInstance={clientInstance}
          getLoading={getLoading}
          updateLoading={updateLoading}
          createCloudInstance={createCloudInstance}
          forceRebootCloudInstance={forceRebootCloudInstance}
          delayDeleteCloudInstance={delayDeleteCloudInstance}
          deleteCloudInstance={deleteCloudInstance}
          getCloudInstance={getCloudInstance}
          setProgressPercentage={setProgressPercentage}
          setProgressDone={setProgressDone}
          getClient={getClient}
        />
      )}
      <div>
        {isNoVncAvailable && (
          <NoVNCConnection
            client={client}
            options={{showToolbar: true, autoConnect: false, disabled: getLoading || updateLoading}}
          />
        )}
      </div>
    </div>
  );
}
