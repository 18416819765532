import {Campaign} from '@growth-x/types';

export function isCampaignAcquisition(campaign: Campaign) {
  return (
    campaign &&
    campaign.type &&
    !['via_import', 'message_request', 'inmail'].find(substring => campaign.type.includes(substring)) &&
    campaign.type.includes('acquisition')
  );
}
