import * as Yup from 'yup';

import {replaceKeys, STRINGS, CONSTANTS} from '../../utils';

export const AccountListSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  list_name: Yup.string().required('Required'),
  user: Yup.number().required('Required'),
  filter_url: Yup.string().when('type', (type: string, schema: any) => {
    return schema
      .test(
        'max',
        ({value}: any) => {
          const isNewUIFilter = value.includes('recentSearchParam');
          const limit = isNewUIFilter ? CONSTANTS.limits.new_url_length : CONSTANTS.limits.url_length;
          return replaceKeys(STRINGS.not_support_too_long_urls, ['{url_max_length}'], [`${limit}`]);
        },
        (value: any) => {
          if (!value) return true;
          const isNewUIFilter = value.includes('recentSearchParam');
          const limit = isNewUIFilter ? CONSTANTS.limits.new_url_length : CONSTANTS.limits.url_length;
          return value.length <= limit;
        }
      )
      .url(STRINGS.not_support_linkedin_url_filters)
      .matches(/^((?!.*saved-leads.*).)*$/, STRINGS.not_support_saved_leads_filters)
      .matches(/^((?!.*sales\/lists.*).)*$/, STRINGS.not_support_saved_lists_filters)
      .matches(/^((?!.*sharedSearchId.*).)*$/, STRINGS.not_support_saved_search_filters)
      .matches(/^.*(sales\/search).*$/, 'Users without a Sales Navigator account will be skipped.')
      .required('Required');
  }),
});
