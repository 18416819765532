import React from 'react';

import ChangePasswordForm from './changePasswordForm/ChangePasswordForm';
import RequestResetPasswordForm from './requestPasswordForm/RequestPasswordForm';

export const ResetPasswordForm = ({
  client,
  token,
  changePassword,
  requestPasswordReset,
  checkPasswordResetToken,
  redirectTo,
}: any) => {
  return (
    <div className="request-reset-password-form">
      {!!token && (
        <ChangePasswordForm
          client={client}
          token={token}
          changePassword={changePassword}
          checkPasswordResetToken={checkPasswordResetToken}
          redirectTo={redirectTo}
        />
      )}
      {!token && (
        <RequestResetPasswordForm client={client} requestPasswordReset={requestPasswordReset} redirectTo={redirectTo} />
      )}
    </div>
  );
};
