import {Icon} from 'antd';
import * as React from 'react';

const ProcessSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 1024 1024">
    <path d="M580.1984 68.1984h-136.6016c-56.5248 0-102.4 45.8752-102.4 102.4s45.8752 102.4 102.4 102.4h136.6016c56.5248 0 102.4-45.8752 102.4-102.4s-45.8752-102.4-102.4-102.4m0 136.6016h-136.6016c-18.8416 0-34.2016-15.36-34.2016-34.2016s15.36-34.2016 34.2016-34.2016h136.6016c18.8416 0 34.2016 15.36 34.2016 34.2016s-15.36 34.2016-34.2016 34.2016m0 204.8h-136.6016c-56.5248 0-102.4 45.8752-102.4 102.4s45.8752 102.4 102.4 102.4h136.6016c56.5248 0 102.4-45.8752 102.4-102.4s-45.8752-102.4-102.4-102.4m0 136.6016h-136.6016c-18.8416 0-34.2016-15.36-34.2016-34.2016s15.36-34.2016 34.2016-34.2016h136.6016c18.8416 0 34.2016 15.36 34.2016 34.2016s-15.36 34.2016-34.2016 34.2016m0 204.8h-136.6016c-56.5248 0-102.4 45.8752-102.4 102.4s45.8752 102.4 102.4 102.4h136.6016c56.5248 0 102.4-45.8752 102.4-102.4s-45.8752-102.4-102.4-102.4m0 136.3968h-136.6016c-18.8416 0-34.2016-15.36-34.2016-34.2016s15.36-34.2016 34.2016-34.2016h136.6016c18.8416 0 34.2016 15.36 34.2016 34.2016s-15.36 34.2016-34.2016 34.2016M204.8 819.2c-36.4544 0-70.8608-14.1312-96.6656-39.936s-39.936-60.0064-39.936-96.6656c0-36.4544 14.1312-70.8608 39.936-96.6656s60.0064-39.936 96.6656-39.936h68.1984v-68.1984H204.8c-113.0496 0-204.8 91.7504-204.8 204.8s91.7504 204.8 204.8 204.8v68.1984l102.4-102.4-102.4-102.4V819.2zM819.2 136.6016h-68.1984V204.8H819.2c36.4544 0 70.8608 14.1312 96.6656 39.936s39.936 60.0064 39.936 96.6656c0 36.4544-14.1312 70.8608-39.936 96.6656s-60.0064 39.936-96.6656 39.936V409.6l-102.4 102.4 102.4 102.4v-68.1984c113.0496 0 204.8-91.7504 204.8-204.8 0-113.2544-91.7504-204.8-204.8-204.8" />
  </svg>
);

interface ProcessIconProps {
  color: string;
}

export const ProcessIcon = ({color}: ProcessIconProps) => <Icon component={ProcessSvg} style={{fill: color}} />;
