import {ComponentType, lazy} from 'react';

import withSuspense from './withSuspense';

type ImportFactory<T> = Promise<{default: T}>;

type TLazyLoading<T extends ComponentType<any>> = (
  lazyComponent: () => ImportFactory<T>,
  attemptsLeft?: number
) => ImportFactory<T>;

type TLazyWithRetry = <T>(lazyComponent: () => ImportFactory<T>, attemptsLeft?: number) => ComponentType<any>;

export const withRetry: TLazyLoading<any> = (lazyComponent, attemptsLeft = 5) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch(error => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }

          withRetry(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1500);
      });
  });
};

export const lazyWithRetry: TLazyWithRetry = (lazyComponent, attemptsLeft) =>
  withSuspense(lazy(() => withRetry(lazyComponent, attemptsLeft)));
