import {brandConfig, CONSTANTS} from '@growth-x/ui';

const env = process.env.NX_ENV || 'production';
let url = isProd() ? 'https://api.growth-x.com/growth/' : 'https://api-dev.growth-x.com/growth/';
let ws_url = isProd() ? 'wss://api.growth-x.com/ws/' : 'wss://api-dev.growth-x.com/ws/';
let chargebee = isProd() ? 'growth-x' : 'growth-x-test';
const intercomAppId = isProd() && brandConfig.intercomFeature ? brandConfig.intercomAppId : 'tnke9fcd';

if (brandConfig.isLeadoku) {
  url = isProd() ? 'https://api.leadoku.io/growth/' : 'https://api-dev.leadoku.io/growth/';
  ws_url = isProd() ? 'wss://api.leadoku.io/ws/' : 'wss://api-dev.leadoku.io/ws/';
  chargebee = isProd() ? 'leadoku' : 'leadoku-test';
} else if (brandConfig.isDigitalmark) {
  url = isProd() ? 'https://api.digitalmark.ai/growth/' : 'https://api-dev.growth-x.com/growth/';
  ws_url = isProd() ? 'wss://api.digitalmark.ai/ws/' : 'wss://api-dev.growth-x.com/ws/';
} else if (brandConfig.isInfinite) {
  url = isProd() ? 'https://api.infinite.co.il/growth/' : 'https://api-dev.growth-x.com/growth/';
  ws_url = isProd() ? 'wss://api.infinite.co.il/ws/' : 'wss://api-dev.growth-x.com/ws/';
}

export function isProd() {
  return env === 'production';
}

const _CONSTANTS = {
  ...CONSTANTS,
  env,
  url,
  ws_url,
  chargebee,
  intercomAppId,
};

export {_CONSTANTS as CONSTANTS};
