import {STRINGS} from '@growth-x/ui';

import {
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  UPDATE_CLIENT,
  LOGOUT,
  CLEAR_STATUS,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILURE,
  UPDATE_DATA_CREDITS,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_CUSTOM_PROXY_VISIBLE,
} from './clientActions';
import {WS_CLIENT_UPDATED} from '../websocket';

const initialState: any = {
  status: '',
  loading: false,
  data: undefined,
  team_member: undefined,
  cancelSubscriptionError: '',
  unauthorizedError: false,
  loadingUpdate: false,
  errorUpdate: '',
  clientCustomProxyVisible: false,
};

export const clientReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {...state, loading: true, unauthorizedError: false};
    }
    case LOGIN_SUCCESS: {
      return {...state, status: '', loading: false, data: action.client, team_member: action.team_member};
    }
    case LOGIN_FAILURE: {
      return {...state, loading: false, status: STRINGS.error_login_invalid};
    }
    case SIGNUP_REQUEST: {
      return {...state, loading: true};
    }
    case SIGNUP_SUCCESS: {
      return {...state, status: '', loading: false, data: action.client, team_member: action.team_member};
    }
    case SIGNUP_FAILURE: {
      return {...state, loading: false, status: action.status};
    }
    case CLEAR_STATUS: {
      return {...state, status: ''};
    }
    case UPDATE_CLIENT: {
      return {...state, data: {...state.data, ...action.client}};
    }
    case UPDATE_CLIENT_REQUEST: {
      return {...state, loadingUpdate: true, errorUpdate: ''};
    }
    case UPDATE_CLIENT_FAILURE: {
      return {...state, loadingUpdate: false, errorUpdate: action.error};
    }
    case UPDATE_CLIENT_SUCCESS: {
      return {...state, loadingUpdate: false, data: {...state.data, ...action.client}};
    }
    case WS_CLIENT_UPDATED: {
      return {...state, data: {...state.data, ...action.client}};
    }
    case GET_CLIENT_SUCCESS: {
      return {...state, data: action.client, team_member: action.team_member};
    }
    case GET_CLIENT_FAILURE: {
      return {...initialState};
    }
    case UPDATE_DATA_CREDITS: {
      const newDataCredits = state.data.data_credits - action.total_matches;
      return {...state, data: {...state.data, data_credits: newDataCredits}};
    }
    case LOGOUT: {
      return {...initialState, unauthorizedError: action.unauthorizedError};
    }
    case UPDATE_CLIENT_CUSTOM_PROXY_VISIBLE: {
      return {...state, clientCustomProxyVisible: action.value};
    }

    default:
      return state;
  }
};
