import * as React from 'react';

import {getLogo} from './utils';
import './Logo.scss';

const LogoImage = getLogo();
export const MobileLogo = () => {
  return (
    <div className="logo_mobile">
      <LogoImage className="logo__img" />
    </div>
  );
};
