import {message} from 'antd';

import {
  InboxService,
  ManualSequenceService,
  TagsService,
  TaskService,
  UserReceiverService,
  ConversationNoteService,
} from '../../services';

import {WS_APP_TASK_UPDATED} from '../websocket';

export const GET_INBOX_UNREAD_COUNT_BY_USER_REQUEST = 'GET_INBOX_UNREAD_COUNT_BY_USER_REQUEST';
export const GET_INBOX_UNREAD_COUNT_BY_USER_SUCCESS = 'GET_INBOX_UNREAD_COUNT_BY_USER_SUCCESS';
export const GET_INBOX_UNREAD_COUNT_BY_USER_FAILURE = 'GET_INBOX_UNREAD_COUNT_BY_USER_FAILURE';
export const GET_INBOX_CONVERSATION_REQUEST = 'GET_INBOX_CONVERSATION_REQUEST';
export const GET_INBOX_CONVERSATION_SUCCESS = 'GET_INBOX_CONVERSATION_SUCCESS';
export const GET_INBOX_CONVERSATION_FAILURE = 'GET_INBOX_CONVERSATION_FAILURE';
export const LOAD_MORE_INBOX_CONVERSATION_REQUEST = 'LOAD_MORE_INBOX_CONVERSATION_REQUEST';
export const LOAD_MORE_INBOX_CONVERSATION_SUCCESS = 'LOAD_MORE_INBOX_CONVERSATION_SUCCESS';
export const LOAD_MORE_INBOX_CONVERSATION_FAILURE = 'LOAD_MORE_INBOX_CONVERSATION_FAILURE';
export const MARK_CONVERSATION_AS_READ_REQUEST = 'MARK_CONVERSATION_AS_READ_REQUEST';
export const MARK_CONVERSATION_AS_READ_SUCCESS = 'MARK_CONVERSATION_AS_READ_SUCCESS';
export const MARK_CONVERSATION_AS_READ_FAILURE = 'MARK_CONVERSATION_AS_READ_FAILURE';
export const UPDATE_INBOX_CONVERSATION_TAGS_REQUEST = 'UPDATE_INBOX_CONVERSATION_TAGS_REQUEST';
export const UPDATE_INBOX_CONVERSATION_TAGS_SUCCESS = 'UPDATE_INBOX_CONVERSATION_TAGS_SUCCESS';
export const UPDATE_INBOX_CONVERSATION_TAGS_FAILURE = 'UPDATE_INBOX_CONVERSATION_TAGS_FAILURE';
export const SEND_INBOX_MESSAGE_REQUEST = 'SEND_INBOX_MESSAGE_REQUEST';
export const SEND_INBOX_MESSAGE_SUCCESS = 'SEND_INBOX_MESSAGE_SUCCESS';
export const SEND_INBOX_MESSAGE_FAILURE = 'SEND_INBOX_MESSAGE_FAILURE';
export const UPDATE_INBOX_MESSAGE_REQUEST = 'UPDATE_INBOX_MESSAGE_REQUEST';
export const UPDATE_INBOX_MESSAGE_SUCCESS = 'UPDATE_INBOX_MESSAGE_SUCCESS';
export const UPDATE_INBOX_MESSAGE_FAILURE = 'UPDATE_INBOX_MESSAGE_FAILURE';
export const UNMARK_CONVERSATION_AS_YOUR_TURN_REQUEST = 'UNMARK_CONVERSATION_AS_YOUR_TURN_REQUEST';
export const UNMARK_CONVERSATION_AS_YOUR_TURN_SUCCESS = 'UNMARK_CONVERSATION_AS_YOUR_TURN_SUCCESS';
export const UNMARK_CONVERSATION_AS_YOUR_TURN_FAILURE = 'UNMARK_CONVERSATION_AS_YOUR_TURN_FAILURE';
export const UPDATE_INBOX_REQUEST = 'UPDATE_INBOX_REQUEST';
export const UPDATE_INBOX_SUCCESS = 'UPDATE_INBOX_SUCCESS';
export const UPDATE_INBOX_FAILURE = 'UPDATE_INBOX_FAILURE';
export const UPDATE_INBOX_USER_RECEIVER_NOTE_REQUEST = 'UPDATE_INBOX_USER_RECEIVER_NOTE_REQUEST';
export const UPDATE_INBOX_USER_RECEIVER_NOTE_SUCCESS = 'UPDATE_INBOX_USER_RECEIVER_NOTE_SUCCESS';
export const UPDATE_INBOX_USER_RECEIVER_NOTE_FAILURE = 'UPDATE_INBOX_USER_RECEIVER_NOTE_FAILURE';
export const UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_REQUEST = 'UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_REQUEST';
export const UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_SUCCESS = 'UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_SUCCESS';
export const UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_FAILURE = 'UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_FAILURE';
export const INBOX_CONVERSATION_UPDATED = 'INBOX_CONVERSATION_UPDATED';
export const INBOX_CONVERSATION_UNSNOOZED = 'INBOX_CONVERSATION_UNSNOOZED';
export const INBOX_REMOVE_CONVERSATION_FROM_LIST = 'INBOX_REMOVE_CONVERSATION_FROM_LIST';
export const ADD_CUSTOM_EMAIL_TO_RECEIVER_REQUEST = 'ADD_CUSTOM_EMAIL_TO_RECEIVER_REQUEST';
export const ADD_CUSTOM_EMAIL_TO_RECEIVER_SUCCESS = 'ADD_CUSTOM_EMAIL_TO_RECEIVER_SUCCESS';
export const ADD_CUSTOM_EMAIL_TO_RECEIVER_FAILURE = 'ADD_CUSTOM_EMAIL_TO_RECEIVER_FAILURE';
export const ADD_CUSTOM_PHONE_TO_RECEIVER_REQUEST = 'ADD_CUSTOM_PHONE_TO_RECEIVER_REQUEST';
export const ADD_CUSTOM_PHONE_TO_RECEIVER_SUCCESS = 'ADD_CUSTOM_PHONE_TO_RECEIVER_SUCCESS';
export const ADD_CUSTOM_PHONE_TO_RECEIVER_FAILURE = 'ADD_CUSTOM_PHONE_TO_RECEIVER_FAILURE';
export const GET_INBOX_CONVERSATION_COUNT_REQUEST = 'GET_INBOX_CONVERSATION_COUNT_REQUEST';
export const GET_INBOX_CONVERSATION_COUNT_SUCCESS = 'GET_INBOX_CONVERSATION_COUNT_SUCCESS';
export const GET_INBOX_CONVERSATION_COUNT_FAILURE = 'GET_INBOX_CONVERSATION_COUNT_FAILURE';
export const GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_REQUEST =
  'GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_REQUEST';
export const GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_SUCCESS =
  'GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_SUCCESS';
export const GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_FAILURE =
  'GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_FAILURE';
export const ADD_RECEIVER_TO_MANUAL_SEQUENCE_REQUEST = 'ADD_RECEIVER_TO_MANUAL_SEQUENCE_REQUEST';
export const ADD_RECEIVER_TO_MANUAL_SEQUENCE_SUCCESS = 'ADD_RECEIVER_TO_MANUAL_SEQUENCE_SUCCESS';
export const ADD_RECEIVER_TO_MANUAL_SEQUENCE_FAILURE = 'ADD_RECEIVER_TO_MANUAL_SEQUENCE_FAILURE';
export const REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_REQUEST = 'REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_REQUEST';
export const REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_SUCCESS = 'REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_SUCCESS';
export const REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_FAILURE = 'REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_FAILURE';

export const inboxActions = {
  getInboxUnreadCountByUser,
  getConversations,
  markConversationAsRead,
  loadMoreInboxConversations,
  updateConversationTags,
  sendInboxMessage,
  updateMessageStatus,
  unmarkConversationYourTurn,
  updateInbox,
  inboxUpdated,
  updateReceiverNote,
  updateDealsAmount,
  snoozeConversation,
  archiveConversation,
  conversationUnsnoozed,
  addCustomEmailToUserReceiver,
  addCustomPhoneToUserReceiver,
  setInSequenceConversation,
  addReceiverToManualSequence,
  removeReceiverFromManualSequence,
  getCampaignByReceiverFromManualSequence,
  createConversationNote,
  suggestTemplate,
  saveSuggestionFeedback,
};

function getInboxUnreadCountByUser() {
  return (dispatch: any, getState: any) => {
    if (!getState().client.data) {
      return;
    }
    dispatch(request());
    InboxService.getInboxUnreadCountByUser().then(
      ({data}: any) => {
        if (data && data.counts) {
          dispatch(success(data.counts));
        }
      },
      () => dispatch(failure())
    );
  };

  function request() {
    return {type: GET_INBOX_UNREAD_COUNT_BY_USER_REQUEST};
  }
  function success(counts: any) {
    return {type: GET_INBOX_UNREAD_COUNT_BY_USER_SUCCESS, counts};
  }
  function failure() {
    return {type: GET_INBOX_UNREAD_COUNT_BY_USER_FAILURE};
  }
}

function getTotalConversationsCount(params: any) {
  return (dispatch: any) => {
    const {user_id, status, search} = params;
    dispatch(request(user_id, status, search));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const notEmptyParams = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== null && v !== '' && (!Array.isArray(v) || v.length))
    );
    InboxService.getInboxConversationsCount({...notEmptyParams, only_total: true}).then(
      ({data}: any) => {
        if (data && (data.count || data.count === 0)) {
          dispatch(success(data.count));
        }
      },
      () => dispatch(failure())
    );
  };

  function request(userId: string, status: string, search: string[]) {
    return {type: GET_INBOX_CONVERSATION_COUNT_REQUEST, userId, status, search};
  }
  function success(count: number) {
    return {type: GET_INBOX_CONVERSATION_COUNT_SUCCESS, count};
  }
  function failure() {
    return {type: GET_INBOX_CONVERSATION_COUNT_FAILURE};
  }
}

function getConversations(params: any) {
  return async (dispatch: any) => {
    let specificConversation = null;
    const {user_id, status, search} = params;
    dispatch(request(user_id, status, search));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const notEmptyParams = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== null && v !== '' && (!Array.isArray(v) || v.length))
    );
    const receiver_id = notEmptyParams['receiver_id'];
    delete notEmptyParams['receiver_id'];
    if (receiver_id) {
      const response = await InboxService.getInboxConversations({user_id, receiver_id});
      specificConversation = response.data?.conversations?.length ? response.data.conversations[0] : null;
    }
    await InboxService.getInboxConversations(notEmptyParams).then(
      ({data}: any) => {
        if (data && data.conversations) {
          let conversations = data.conversations;
          if (specificConversation) {
            conversations = [specificConversation, ...conversations.filter(c => c.id !== specificConversation.id)];
          }
          dispatch(success(conversations, user_id, status, search));
        }
      },
      () => dispatch(failure())
    );
    dispatch(getTotalConversationsCount(notEmptyParams));
  };

  function request(userId: string, status: string, search: string[]) {
    return {type: GET_INBOX_CONVERSATION_REQUEST, userId, status, search};
  }
  function success(conversations: any, userId: string, status: string, search: string[]) {
    return {type: GET_INBOX_CONVERSATION_SUCCESS, conversations, userId, status, search};
  }
  function failure() {
    return {type: GET_INBOX_CONVERSATION_FAILURE};
  }
}

function loadMoreInboxConversations(params: any) {
  return (dispatch: any, getState: any) => {
    dispatch(request());
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const notEmptyParams = Object.fromEntries(Object.entries(params).filter(([_, v]) => v !== null && v !== ''));
    InboxService.getInboxConversations({...notEmptyParams, offset: getState().inbox.pagination.offset + 50}).then(
      ({data}: any) => {
        if (data && data.conversations) {
          dispatch(success(data.conversations));
        }
      },
      () => dispatch(failure())
    );
  };

  function request() {
    return {type: LOAD_MORE_INBOX_CONVERSATION_REQUEST};
  }
  function success(conversations: any) {
    return {type: LOAD_MORE_INBOX_CONVERSATION_SUCCESS, conversations};
  }
  function failure() {
    return {type: LOAD_MORE_INBOX_CONVERSATION_FAILURE};
  }
}

function markConversationAsRead(params: any) {
  return (dispatch: any) => {
    dispatch(request());
    InboxService.markConversationAsRead(params).then(
      () => {
        dispatch(success(params));
        dispatch(getInboxUnreadCountByUser());
      },
      () => dispatch(failure())
    );
  };

  function request() {
    return {type: MARK_CONVERSATION_AS_READ_REQUEST};
  }
  function success(conversation: any) {
    return {type: MARK_CONVERSATION_AS_READ_SUCCESS, ...conversation};
  }
  function failure() {
    return {type: MARK_CONVERSATION_AS_READ_FAILURE};
  }
}

function updateReceiverNote(user_id: number, receiver_id: number, inbox_note: string) {
  return async (dispatch: any) => {
    dispatch(request());
    try {
      await UserReceiverService.updateInboxNoteValue(user_id, receiver_id, inbox_note);
      dispatch(success(user_id, receiver_id, inbox_note));
    } catch (e) {
      dispatch(failure());
    }

    function request() {
      return {type: UPDATE_INBOX_USER_RECEIVER_NOTE_REQUEST};
    }
    function failure() {
      return {type: UPDATE_INBOX_USER_RECEIVER_NOTE_FAILURE};
    }
    function success(user_id: number, receiver_id: number, inbox_note: string) {
      return {type: UPDATE_INBOX_USER_RECEIVER_NOTE_SUCCESS, inbox_note, user_id, receiver_id};
    }
  };
}

function updateDealsAmount(user_id: number, receiver_id: number, deals_amount: string) {
  return async (dispatch: any) => {
    dispatch(request());
    try {
      const result = await UserReceiverService.updateDealsAmountValue(user_id, receiver_id, deals_amount);
      if (result?.status === 200) {
        dispatch(success(user_id, receiver_id, deals_amount));
      } else {
        dispatch(failure());
      }
    } catch (e) {
      dispatch(failure());
    }

    function request() {
      return {type: UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_REQUEST};
    }
    function failure() {
      return {type: UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_FAILURE};
    }
    function success(user_id: number, receiver_id: number, deals_amount: string) {
      return {type: UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_SUCCESS, deals_amount, user_id, receiver_id};
    }
  };
}

function updateConversationTags(newTags: string[], conversation: any) {
  return async (dispatch: any) => {
    const oldTags = conversation.receiver.tags;
    const added = newTags.filter((t: string) => !oldTags.includes(t));
    const removed = oldTags.filter((t: string) => !newTags.includes(t));
    dispatch(request());
    try {
      if (added && added.length) {
        await TagsService.addTags(added, [conversation.receiver.id]);
      }
      if (removed && removed.length) {
        await TagsService.removeTags(removed, [conversation.receiver.id]);
      }
      const updatedConversation = {
        ...conversation,
        receiver: {
          ...conversation.receiver,
          tags: newTags,
        },
      };
      console.log('update Tags action ', added, removed, updatedConversation);
      dispatch(success(updatedConversation));
    } catch (e) {
      dispatch(failure());
    }

    function request() {
      return {type: UPDATE_INBOX_CONVERSATION_TAGS_REQUEST};
    }
    function failure() {
      return {type: UPDATE_INBOX_CONVERSATION_TAGS_FAILURE};
    }
    function success(conversation: any) {
      return {type: UPDATE_INBOX_CONVERSATION_TAGS_SUCCESS, conversation};
    }
  };
}

function sendInboxMessage(params: any) {
  return (dispatch: any) => {
    dispatch(request());
    TaskService.postTask({
      ...params,
      code: 'send_message',
      status: 'created',
    }).then(
      (result: any) => {
        dispatch(success(result));
      },
      () => dispatch(failure())
    );
  };
  function request() {
    return {type: SEND_INBOX_MESSAGE_REQUEST};
  }
  function failure() {
    return {type: SEND_INBOX_MESSAGE_FAILURE};
  }
  function success(conversation: any) {
    return {type: SEND_INBOX_MESSAGE_SUCCESS, conversation};
  }
}

function updateMessageStatus(params: any) {
  return (dispatch: any) => {
    dispatch(request());
    TaskService.updateTask(params).then(
      (result: any) => {
        dispatch(success(result));
      },
      () => dispatch(failure())
    );
  };
  function request() {
    return {type: SEND_INBOX_MESSAGE_REQUEST};
  }
  function failure() {
    return {type: SEND_INBOX_MESSAGE_FAILURE};
  }
  function success(conversation: any) {
    return {type: SEND_INBOX_MESSAGE_SUCCESS, conversation};
  }
}

function unmarkConversationYourTurn(params: any) {
  return (dispatch: any) => {
    dispatch(request());
    InboxService.unmarkConversationYourTurn(params).then(
      () => {
        dispatch(success(params));
        dispatch(getInboxUnreadCountByUser());
      },
      () => dispatch(failure())
    );
  };

  function request() {
    return {type: UNMARK_CONVERSATION_AS_YOUR_TURN_REQUEST};
  }
  function success(conversation: any) {
    return {type: UNMARK_CONVERSATION_AS_YOUR_TURN_SUCCESS, ...conversation};
  }
  function failure() {
    return {type: UNMARK_CONVERSATION_AS_YOUR_TURN_FAILURE};
  }
}

function updateInbox(userId) {
  return (dispatch: any) => {
    dispatch(request());
    TaskService.postTask({
      code: 'update_inbox',
      status: 'created',
      user_id: userId,
    }).then(
      (result: any) => {
        dispatch(success(result));
      },
      e => {
        console.log('failure', e);
        dispatch(failure());
      }
    );
  };
  function request() {
    return {type: UPDATE_INBOX_REQUEST};
  }
  function failure() {
    return {type: UPDATE_INBOX_FAILURE};
  }
  function success(result: any) {
    return {type: UPDATE_INBOX_SUCCESS, payload: result};
  }
}

function inboxUpdated(app_task: any) {
  // call when we scann responses finised (inbox synced)
  return (dispatch: any) => {
    dispatch(getInboxUnreadCountByUser());
    dispatch({type: WS_APP_TASK_UPDATED, app_task});
  };
}

function snoozeConversation(id: number, until: string) {
  return async (dispatch: any) => {
    try {
      const {data} = await InboxService.snoozeConversation(id, until);
      dispatch(success(data));
      dispatch({type: INBOX_REMOVE_CONVERSATION_FROM_LIST, id});
    } catch (e) {
      message.error('error');
    }

    function success(conversation) {
      return {type: INBOX_CONVERSATION_UPDATED, conversation};
    }
  };
}

function archiveConversation(id: number, flag: boolean) {
  return async (dispatch: any) => {
    try {
      const {data} = await InboxService.archiveConversation(id, flag);
      dispatch(success(data));
      dispatch({type: INBOX_REMOVE_CONVERSATION_FROM_LIST, id});
    } catch (e) {
      message.error('error');
    }

    function success(conversation) {
      return {type: INBOX_CONVERSATION_UPDATED, conversation};
    }
  };
}

function conversationUnsnoozed() {
  return () => {
    message.info('Please reload inbox to show new messages');
  };
}

function addCustomEmailToUserReceiver(custom_email: string, id: number) {
  const params = {custom_email, id};

  return (dispatch: any) => {
    dispatch(request());
    UserReceiverService.addCustomFieldToUserReceiver(params).then(
      (result: any) => {
        dispatch(success(result.data));
      },
      () => dispatch(failure())
    );
  };
  function request() {
    return {type: ADD_CUSTOM_EMAIL_TO_RECEIVER_REQUEST};
  }
  function failure() {
    return {type: ADD_CUSTOM_EMAIL_TO_RECEIVER_FAILURE};
  }
  function success(data: any) {
    return {type: ADD_CUSTOM_EMAIL_TO_RECEIVER_SUCCESS, data};
  }
}

function addCustomPhoneToUserReceiver(custom_phone_number: string, id: number) {
  const params = {custom_phone_number, id};

  return (dispatch: any) => {
    dispatch(request());
    UserReceiverService.addCustomFieldToUserReceiver(params).then(
      (result: any) => {
        dispatch(success(result.data));
      },
      () => dispatch(failure())
    );
  };
  function request() {
    return {type: ADD_CUSTOM_PHONE_TO_RECEIVER_REQUEST};
  }
  function failure() {
    return {type: ADD_CUSTOM_PHONE_TO_RECEIVER_FAILURE};
  }
  function success(data: any) {
    return {type: ADD_CUSTOM_PHONE_TO_RECEIVER_SUCCESS, data};
  }
}

function setInSequenceConversation(id: number, state: string) {
  return async (dispatch: any) => {
    try {
      const {data} = await InboxService.setInSequence(id, state);
      dispatch(success(data));
    } catch (e) {
      let messageText = 'Something went wrong';
      if (e?.response?.data?.length) {
        messageText = e.response?.data[0].message;
      }
      message.error(messageText);
    }

    function success(conversation) {
      return {type: INBOX_CONVERSATION_UPDATED, conversation};
    }
  };
}

function getCampaignByReceiverFromManualSequence(receiver_id: number, user_id: number) {
  const params = {receiver_id, user_id};
  return (dispatch: any) => {
    dispatch(request());
    ManualSequenceService.getCampaignByReceiver(params).then(
      (result: any) => {
        dispatch(success(result?.data?.results[0]?.campaign));
      },
      () => dispatch(failure())
    );
  };

  function request() {
    return {type: GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_REQUEST};
  }
  function failure() {
    return {type: GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_FAILURE};
  }
  function success(data: any) {
    return {type: GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_SUCCESS, data};
  }
}

function addReceiverToManualSequence(campaign: number, receiver: number, user: number) {
  const params = {campaign, receiver, user};

  return async (dispatch: any) => {
    try {
      dispatch(request());
      const {data} = await ManualSequenceService.addReceiver(params);
      dispatch(success(data));
      dispatch(getCampaignByReceiverFromManualSequence(receiver, user));
      message.success('The receiver successfully added to campaign sequence');
    } catch (e) {
      let messageText = 'Something went wrong when adding to campaign sequence';
      if (e?.response?.data?.length) {
        messageText = e.response?.data[0].message;
        messageText = messageText.includes('nurtured')
          ? 'The receiver is already nurtured by this campaign'
          : messageText;
        message.error(messageText);
      }
      dispatch(failure());
    }
  };

  function request() {
    return {type: ADD_RECEIVER_TO_MANUAL_SEQUENCE_REQUEST};
  }
  function failure() {
    return {type: ADD_RECEIVER_TO_MANUAL_SEQUENCE_FAILURE};
  }
  function success(data: any) {
    return {type: ADD_RECEIVER_TO_MANUAL_SEQUENCE_SUCCESS, data};
  }
}

function removeReceiverFromManualSequence(campaign: number, receiver: number, user: number) {
  const params = {campaign, receiver, user};

  return (dispatch: any) => {
    dispatch(request());
    ManualSequenceService.removeReceiver(params).then(
      () => {
        dispatch(success());
        message.success('The receiver successfully removed from campaign sequence');
      },
      () => {
        dispatch(failure());
        message.error('Something went wrong while being removed from the campaign sequence');
      }
    );
  };

  function request() {
    return {type: REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_REQUEST};
  }
  function failure() {
    return {type: REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_FAILURE};
  }
  function success() {
    return {type: REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_SUCCESS};
  }
}

function createConversationNote(note: any) {
  return () => {
    return ConversationNoteService.createNote(note);
  };
}

function suggestTemplate(conversationId: number) {
  return async () => {
    try {
      const result = await InboxService.suggestTemplate(conversationId);
      if (result?.data?.warning?.message) {
        message.warning(result?.data?.warning?.message);
      }
      return result.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        message.error(error?.response?.data?.error);
      } else {
        message.error('Something went wrong. Please try again later');
      }
    }
  };
}

function saveSuggestionFeedback(conversationId: number, score: number, commentary: string) {
  try {
    return async () => {
      await InboxService.saveSuggestionFeedback(conversationId, score, commentary);
      message.success('Thank you for the feedback!');
    };
  } catch (error) {
    message.error('Something went wrong with saving your feedback');
  }
}
