import {Select} from 'antd';
import React from 'react';

import {Campaign} from '@growth-x/types';
import {InfoIcon, getCampaignTypes} from '@growth-x/ui';

import './campaignDetailsTitle.scss';

export function CampaignDetailsTitle(
  campaign: Campaign,
  onTypeChange: any,
  client: any,
  brandConfig: any
): any | React.ReactNode {
  if (!campaign) {
    return null;
  }
  const type = getCampaignTypes(campaign.type);
  if (campaign.type !== 'acquisition' && campaign.type !== 'acquisition_via_import') {
    return type.value;
  }

  const isNotChangeableType = campaign.stats?.total_invites || campaign.campaign_users?.[0]?.potential;

  if (isNotChangeableType) {
    if (campaign.type === 'acquisition_via_import') {
      return getAcquisitionPlusTooltip(type.value, brandConfig);
    }
    return type.value;
  }
  return (
    <Select value={campaign.type} className="campaign-type-switcher" onChange={onTypeChange}>
      <Select.Option value="acquisition">Acquisition</Select.Option>
      {/*<Select.Option disabled={!client.import_emails_feature} value="acquisition_via_import">*/}
      {/*  Acquisition +*/}
      {/*</Select.Option>*/}
    </Select>
  );
}

function getAcquisitionPlusTooltip(type: string, brandConfig: any): React.ReactNode {
  return (
    <>
      <span>{type}: </span>
      <InfoIcon
        message={
          <span>
            If you need to send over 100 invites per week, use this type of campaign in addition to your regular
            Acquisition campaigns.
            {!brandConfig.isAffiliate && (
              <>
                <span>Follow the instructions by clicking&nbsp;</span>
                <a
                  style={{textDecoration: 'underline'}}
                  href={brandConfig.articles.acquisitionCampaignEmail}
                  target="_blank"
                  rel="noreferrer"
                >
                  here.
                </a>
              </>
            )}
          </span>
        }
      />
    </>
  );
}
