import {Button} from 'antd';
import React from 'react';

export const InstantlyLink = () => (
  <Button
    className="instantly-link"
    type="link"
    target="_blank"
    href="https://developer.instantly.ai/preview/introduction/test_your_api_key">
    Generate API key
  </Button>
);
