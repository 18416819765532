import {Button} from 'antd';
import React from 'react';

import './selectionActions.css';

interface ISelectionActionsProps {
  hasSelectedRows: boolean;
  clearSelection: () => void;
  archiveCampaigns: () => void;
  mainButtonText: string;
  disabledMainButton: boolean;
}

export const SelectionActions = ({
  hasSelectedRows,
  clearSelection,
  archiveCampaigns,
  mainButtonText,
  disabledMainButton,
}: ISelectionActionsProps) => {
  return (
    <div className={`campaigns-page__selection-actions ${hasSelectedRows ? 'selected' : ''}`}>
      <Button type="default" onClick={clearSelection} className="campaigns-page__selection-actions_default-button">
        Clear selection
      </Button>
      <Button type="primary" onClick={archiveCampaigns} disabled={disabledMainButton}>
        {mainButtonText}
      </Button>
    </div>
  );
};
