import {Empty} from 'antd';
import * as React from 'react';

import DataSet from '@antv/data-set';
import G2 from '@antv/g2';

interface UserActivityProps {
  stats: any;
}

export class UserActivityChart extends React.Component<UserActivityProps> {
  graph: any = React.createRef();
  chart: any;

  componentDidMount() {
    this.init(this.props.stats);
  }

  componentWillUnmount(): void {
    this.chart = null;
  }

  init = (stats: any) => {
    if (!stats?.length) return null;
    this.chart = new G2.Chart({
      container: this.graph.current,
      width: 300,
      height: 50,
      padding: {top: 5, right: 30, bottom: 5, left: 30},
      plotBackground: {fillOpacity: 0.5},
      background: {fillOpacity: 0.5},
    });
    this.chart.source(this.getDataView(stats));
    this.chart.axis(false);
    this.chart.intervalStack().position('day*total').color('messages', this.getColors);

    this.chart.tooltip({inPlot: false, position: 'bottom'});
    this.chart.render();
  };

  getColors = (val: any) => {
    switch (val) {
      case 'invites':
        return '#5cb85c';
      case 'responses':
        return '#d60093';
      case 'nurtured':
        return '#98d9d9';
      case 'inmail':
        return '#c197ef';
      default:
        return '#fdbe00';
    }
  };

  getDataView = (stats: any) => {
    const ds = new DataSet();
    const dv = ds.createView().source(stats);

    dv.transform({
      type: 'rename',
      map: {
        nurture: 'nurtured',
      },
    }).transform({
      type: 'fold',
      fields: ['invites', 'responses', 'followups', 'nurtured', 'inmail'],
      key: 'messages',
      value: 'total',
      retains: ['day'],
    });
    return dv;
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.stats !== this.props.stats && this.props.stats?.length) {
      this.update(this.getDataView(this.props.stats));
    }
  }

  update = (stats: any) => {
    if (this.chart) {
      this.chart.changeData(stats);
    }
  };

  render() {
    return this.props?.stats?.length ? (
      <div ref={this.graph} />
    ) : (
      <Empty
        description="No latest activity"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{marginLeft: '20px'}}
        style={{margin: '10px 0', textAlign: 'left'}}
      />
    );
  }
}
