import {message} from 'antd';
import {HsOAuth2Service} from '../../services';

export const SET_HS_AUTHORIZATION_CODE_REQUEST = 'SET_HS_AUTHORIZATION_CODE_REQUEST';
export const SET_HS_AUTHORIZATION_CODE_SUCCESS = 'SET_HS_AUTHORIZATION_CODE_SUCCESS';
export const SET_HS_AUTHORIZATION_CODE_FAILURE = 'SET_HS_AUTHORIZATION_CODE_FAILURE';
export const GET_HS_AUTHORIZED_CREDENTIALS_REQUEST = 'GET_HS_AUTHORIZED_CREDENTIALS_REQUEST';
export const GET_HS_AUTHORIZED_CREDENTIALS_SUCCESS = 'GET_HS_AUTHORIZED_CREDENTIALS_SUCCESS';
export const GET_HS_AUTHORIZED_CREDENTIALS_FAILURE = 'GET_HS_AUTHORIZED_CREDENTIALS_FAILURE';
export const CLEAR_HS_AUTHORIZED_CREDENTIALS_REQUEST = 'CLEAR_HS_AUTHORIZED_CREDENTIALS_REQUEST';
export const CLEAR_HS_AUTHORIZED_CREDENTIALS_SUCCESS = 'CLEAR_HS_AUTHORIZED_CREDENTIALS_SUCCESS';
export const CLEAR_HS_AUTHORIZED_CREDENTIALS_FAILURE = 'CLEAR_HS_AUTHORIZED_CREDENTIALS_FAILURE';
export const GET_HS_USERS_REQUEST = 'GET_HS_USERS_REQUEST';
export const GET_HS_USERS_SUCCESS = 'GET_HS_USERS_SUCCESS';
export const GET_HS_USERS_FAILURE = 'GET_HS_USERS_FAILURE';
export const GET_HS_SEARCH_PROPERTY_REQUEST = 'GET_HS_SEARCH_PROPERTY_REQUEST';
export const GET_HS_SEARCH_PROPERTY_SUCCESS = 'GET_HS_SEARCH_PROPERTY_SUCCESS';
export const GET_HS_SEARCH_PROPERTY_FAILURE = 'GET_HS_SEARCH_PROPERTY_FAILURE';
export const GET_HS_CONFIGURE_REQUEST = 'GET_HS_CONFIGURE_REQUEST';
export const GET_HS_CONFIGURE_SUCCESS = 'GET_HS_CONFIGURE_SUCCESS';
export const GET_HS_CONFIGURE_FAILURE = 'GET_HS_CONFIGURE_FAILURE';

export const hsOauth2Actions = {
  setAuthorizationCode,
  getAuthorizedCredentials,
  clearAuthorizedCredentials,
  getUsers,
  getSearchProperty,
  configureHubspot,
};

function getAuthorizedCredentials() {
  return async (dispatch: any) => {
    dispatch(request());
    return await HsOAuth2Service.getAuthorizedCredentials().then(
      ({data}: any) => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return {type: GET_HS_AUTHORIZED_CREDENTIALS_REQUEST};
  }
  function success(data) {
    return {type: GET_HS_AUTHORIZED_CREDENTIALS_SUCCESS, data};
  }
  function failure(error) {
    return {type: GET_HS_AUTHORIZED_CREDENTIALS_FAILURE, error};
  }
}

function setAuthorizationCode(params) {
  return async (dispatch: any) => {
    dispatch(request());
    return await HsOAuth2Service.setAuthorizationCode(params).then(
      ({data}: any) => {
        dispatch(success(data));
        dispatch(getAuthorizedCredentials());
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return {type: SET_HS_AUTHORIZATION_CODE_REQUEST};
  }
  function success(data) {
    return {type: SET_HS_AUTHORIZATION_CODE_SUCCESS, data};
  }
  function failure(error) {
    return {type: SET_HS_AUTHORIZATION_CODE_FAILURE, error};
  }
}

function clearAuthorizedCredentials() {
  return async (dispatch: any) => {
    dispatch(request());
    return await HsOAuth2Service.clearAuthorizedCredentials().then(
      ({data}: any) => {
        dispatch(success(data));
        dispatch(getAuthorizedCredentials());
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return {type: CLEAR_HS_AUTHORIZED_CREDENTIALS_REQUEST};
  }
  function success(data) {
    return {type: CLEAR_HS_AUTHORIZED_CREDENTIALS_SUCCESS, data};
  }
  function failure(error) {
    return {type: CLEAR_HS_AUTHORIZED_CREDENTIALS_FAILURE, error};
  }
}

function getUsers() {
  return async (dispatch: any) => {
    dispatch(request());
    return await HsOAuth2Service.getUsers().then(
      ({data}: any) => {
        dispatch(success(data?.results));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return {type: GET_HS_USERS_REQUEST};
  }
  function success(users) {
    return {type: GET_HS_USERS_SUCCESS, users};
  }
  function failure(error) {
    return {type: GET_HS_USERS_FAILURE, error};
  }
}

function getSearchProperty(params) {
  return async (dispatch: any) => {
    dispatch(request());
    return await HsOAuth2Service.getSearchProperty(params).then(
      ({data}: any) => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return {type: GET_HS_SEARCH_PROPERTY_REQUEST};
  }
  function success(options) {
    console.log('data ', options);
    return {type: GET_HS_SEARCH_PROPERTY_SUCCESS, options};
  }
  function failure(error) {
    return {type: GET_HS_SEARCH_PROPERTY_FAILURE, error};
  }
}

function configureHubspot(params) {
  return async (dispatch: any) => {
    dispatch(request());
    return await HsOAuth2Service.configureHubspot(params).then(
      ({data}: any) => {
        dispatch(success(data));
        dispatch(getAuthorizedCredentials());
        message.success('Hubspot changes are saved');
      },
      error => {
        dispatch(failure(error));
        message.error('Something went wrong. Please try again later');
      }
    );
  };
  function request() {
    return {type: GET_HS_CONFIGURE_REQUEST};
  }
  function success(data) {
    return {type: GET_HS_CONFIGURE_SUCCESS, data};
  }
  function failure(error) {
    return {type: GET_HS_CONFIGURE_FAILURE, error};
  }
}
