import {Form, Select, Spin} from 'antd';
import React, {useEffect} from 'react';

import {InstantlyFormLabel} from './InstantlyFormLabel';

const {Option} = Select;

export const InstantlyFormItem = ({
  errors,
  touched,
  values,
  isTokenVerified,
  campaigns,
  openSettings,
  onChange,
  getCampaigns,
  isLoading,
}: any) => {
  useEffect(() => {
    getCampaigns();
  }, [isTokenVerified]);
  return (
    <Spin spinning={isLoading}>
      <Form.Item
        label={<InstantlyFormLabel onClick={openSettings} showConnect={!isTokenVerified} />}
        validateStatus={errors.instantly_campaign_ids && touched.instantly_campaign_ids ? 'error' : ''}
        hasFeedback
        help={errors.name && touched.instantly_campaign_ids ? errors.instantly_campaign_ids : ''}
      >
        {isTokenVerified && (
          <Select
            mode="multiple"
            style={{width: '100%'}}
            placeholder="Select campaigns"
            value={values.instantly_campaign_ids}
            onChange={onChange}
          >
            {campaigns.map((c: any) => (
              <Option key={c.id}>{c.name}</Option>
            ))}
          </Select>
        )}
      </Form.Item>
    </Spin>
  );
};
