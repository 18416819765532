import {Dropdown, Button, Badge, Drawer} from 'antd';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {LazyNotificationsList} from '@growth-x/ui';

import AccountMenu from './accountMenu';
import './accountSwitcher.css';

export function AccountSwitcher({
  client,
  team_member,
  notifications,
  toggleNotifications,
  onClick,
  onReadNotification,
  loadMoreNotifications,
  shouldLoadMoreNotifications,
  isAdmin,
}) {
  return (
    <div className="accountSwitcher">
      <span className="accountSwitcher_email">
        {team_member ? (
          <>
            {team_member.email}
            <br />
            <span className="sub_email">member of {client.data.email}</span>
          </>
        ) : (
          <>
            {client.data.email}
            {isAdmin && <small> ({client.data.id})</small>}
          </>
        )}
      </span>
      <Dropdown
        overlay={<AccountMenu client={client.data} onClick={onClick} team_member={team_member} isAdmin={isAdmin} />}
        placement="bottomLeft"
        trigger={['click']}
      >
        <Button type="primary" shape="circle" icon="user" style={{marginRight: '10px'}} />
      </Dropdown>
      <Badge count={notifications.unreadNotificationsCount} overflowCount={10}>
        <Button type="primary" shape="circle" icon="bell" onClick={() => toggleNotifications()} />
      </Badge>
      <Drawer
        title={
          <span>
            Notifications <Badge count={notifications.unreadNotificationsCount} overflowCount={10} />
          </span>
        }
        className="notifications-drawer"
        width={400}
        onClose={toggleNotifications}
        visible={notifications.isOpen}
        bodyStyle={{paddingBottom: 80}}
      >
        <LazyNotificationsList notifications={notifications} onReadNotification={onReadNotification} />
        {shouldLoadMoreNotifications && (
          <VisibilitySensor
            onChange={(isVisible: boolean) => {
              if (isVisible) {
                loadMoreNotifications();
              }
            }}
            partialVisibility={true}
          >
            <span>{'Loading...'}</span>
          </VisibilitySensor>
        )}
      </Drawer>
    </div>
  );
}
