export class TagsService {
  client: any;
  serviceName = 'tags';
  constructor({client}: any) {
    this.client = client;
  }
  getTagsByClient() {
    return this.client.get(this.serviceName, {
      method: 'by_client',
    });
  }
  addTags(tags: string[], receivers: number[]) {
    return this.client.post(this.serviceName, {
      method: 'add',
      receivers,
      tags,
    });
  }
  removeTags(tags: string[], receivers: number[]) {
    return this.client.post(this.serviceName, {
      method: 'delete',
      receivers,
      tags,
    });
  }
}
