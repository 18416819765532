import {Modal, Steps} from 'antd';
import React, {useState} from 'react';

import {ConfirmationModal} from '../';
import {UserValidateStep} from '../userValidateStep/userValidateStep';
import './userValidateModal.css';

const {Step} = Steps;

const stepsCode = {
  default: 0,
  created: 0,
  in_progress: 1,
  requests_code: 2,
  typing_code: 3,
  user_validation: 3,
  done: 4,
  failed: 4,
};

export const UserValidateModal = ({visible, handleCancel, validateUser, validationInfo, userEmail, client}: any) => {
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const toggleConfirmationModal = () => setConfirmationVisible(value => !value);
  const noValidation = !validationInfo?.id;
  const currentStepIndex = stepsCode[validationInfo?.status] || stepsCode.default;

  const onCancel = () => {
    toggleConfirmationModal();
    handleCancel();
  };

  const onClose = () => {
    if (!['done', 'error'].includes(validationInfo.status)) {
      toggleConfirmationModal();
      return;
    }
    handleCancel();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      width={800}
      title={`Validation process ${userEmail ? `for ${userEmail}` : ''}`}
      className="validate-user-modal"
    >
      <ConfirmationModal visible={confirmationVisible} onOk={onCancel} onCancel={toggleConfirmationModal} />
      {noValidation ? (
        <span>Loading...</span>
      ) : (
        <div>
          <Steps size="small" current={currentStepIndex}>
            <Step title="Starting" />
            <Step title="Requesting Code" />
            <Step title="Waiting Input" />
            {!client.c && <Step title="Typing Code" />}
            {client.c && <Step title="User Validation" />}
            <Step title="Finished" />
          </Steps>
          <UserValidateStep
            userEmail={userEmail}
            validateUser={validateUser}
            validationInfo={validationInfo}
            client={client}
          />
        </div>
      )}
    </Modal>
  );
};
