export const formatNumbers = (number: number | undefined) => {
  if (typeof number !== 'number') return '-';
  // 1M
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + 'M';
  }
  // 10.4k
  else if (number >= 10000) {
    return (number / 1000).toFixed(1) + 'k';
  }
  // 1,502
  else if (number >= 1000) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  // 999
  return number;
};
