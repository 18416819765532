export class MembersService {
  client: any;
  serviceName = 'teamMembers';
  constructor({client}: any) {
    this.client = client;
  }
  getMembers() {
    return this.client.get(this.serviceName, {});
  }
  addMember(member) {
    return this.client.post(this.serviceName, member);
  }
  removeMember(id) {
    return this.client.delete(`${this.serviceName}/${id}`);
  }
  updateMember(id, member) {
    return this.client.patch(`${this.serviceName}/${id}`, member);
  }
  checkToken(token, uid) {
    return this.client.post(`${this.serviceName}/can_accept`, {token, uid});
  }
  setPassword(data) {
    return this.client.post(`${this.serviceName}/set_password`, data);
  }
  sendInvitation(id) {
    return this.client.post(`${this.serviceName}/${id}/invite`);
  }
}
