import {Button} from 'antd';
import React from 'react';

import {STRINGS} from '@growth-x/ui';

export function MobileWarning({onClose}) {
  return (
    <div className="mobile-app-warning">
      <Button onClick={onClose} icon="close" type="ghost" shape="circle" style={{color: 'white'}} />
      {STRINGS.warning.mobile_warning}
    </div>
  );
}
