import {message} from 'antd';
import {AxiosError, AxiosResponse} from 'axios';

import {GetManualTaskResponse, CreateManualTaskRequest} from '@growth-x/types';
import {CONSTANTS, STRINGS} from '@growth-x/ui';

import {AppDispatch, AppGetState, AppThunkAction} from '../';
import {ManualTasksService, TaskService} from '../../services';

export const GET_MANUAL_TASKS_REQUEST = 'GET_MANUAL_TASKS_REQUEST';
export const GET_MANUAL_TASKS_SUCCESS = 'GET_MANUAL_TASKS_SUCCESS';
export const GET_MANUAL_TASKS_FAILED = 'GET_MANUAL_TASKS_FAILED';

export const LOAD_MORE_MANUAL_TASKS_REQUEST = 'LOAD_MORE_MANUAL_TASKS_REQUEST';
export const LOAD_MORE_MANUAL_TASKS_SUCCESS = 'LOAD_MORE_MANUAL_TASKS_SUCCESS';
export const LOAD_MORE_MANUAL_TASKS_FAILED = 'LOAD_MORE_MANUAL_TASKS_FAILED';

export const POST_MANUAL_TASK_REQUEST = 'POST_MANUAL_TASK_REQUEST';
export const POST_MANUAL_TASK_SUCCESS = 'POST_MANUAL_TASK_SUCCESS';
export const POST_MANUAL_TASK_FAILED = 'POST_MANUAL_TASK_FAILED';

export const tasksActions = {
  getTasks,
  loadMoreTasks,
  createTask,
  retryTask,
};

function getTasks(): AppThunkAction {
  return (dispatch: AppDispatch) => {
    dispatch(request());
    ManualTasksService.getTasks().then(
      ({data}: AxiosResponse<GetManualTaskResponse>) => {
        dispatch(success(data));
      },
      (err: AxiosError) => dispatch(failed(err))
    );
  };

  function request() {
    return {type: GET_MANUAL_TASKS_REQUEST};
  }
  function success(data: GetManualTaskResponse) {
    return {type: GET_MANUAL_TASKS_SUCCESS, data};
  }
  function failed(err: AxiosError) {
    console.log(err);
    return {type: GET_MANUAL_TASKS_FAILED};
  }
}

function loadMoreTasks() {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const nextUrl = getState().manualTasks.next;
    if (nextUrl) {
      dispatch(request());
      ManualTasksService.loadMoreTasks(nextUrl).then(
        ({data}: AxiosResponse<GetManualTaskResponse>) => {
          dispatch(success(data));
        },
        (err: AxiosError) => dispatch(failed(err))
      );
    }
  };

  function request() {
    return {type: LOAD_MORE_MANUAL_TASKS_REQUEST};
  }
  function success(data: GetManualTaskResponse) {
    return {type: LOAD_MORE_MANUAL_TASKS_SUCCESS, data};
  }
  function failed(err: AxiosError) {
    console.log(err);
    return {type: LOAD_MORE_MANUAL_TASKS_FAILED};
  }
}

function createTask(props: CreateManualTaskRequest) {
  return (dispatch: AppDispatch) => {
    dispatch(request());
    ManualTasksService.createTask(props).then(
      () => {
        success();
        dispatch(getTasks());
      },
      (err: AxiosError) => dispatch(failed(err))
    );
  };

  function request() {
    return {type: POST_MANUAL_TASK_REQUEST};
  }
  function success() {
    return {type: POST_MANUAL_TASK_SUCCESS};
  }
  function failed(err: AxiosError) {
    console.log(err);
    message.error(STRINGS.DEFAULT_API_ERROR_MESSAGE);
    return {type: POST_MANUAL_TASK_FAILED};
  }
}

function retryTask(id: number) {
  return (dispatch: AppDispatch) => {
    TaskService.updateTask({id, status: CONSTANTS.TASK_STATUSES.CREATED}).then(
      () => {
        dispatch(getTasks());
      },
      (err: AxiosError) => {
        console.log(err);
        message.error(STRINGS.DEFAULT_API_ERROR_MESSAGE);
      }
    );
  };
}
