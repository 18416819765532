import {ClientAttachmentsService} from '../../services';

export const CREATE_CLIENT_ATTACHMENT_REQUEST = 'CREATE_CLIENT_ATTACHMENT_REQUEST';
export const CREATE_CLIENT_ATTACHMENT_SUCCESS = 'CREATE_CLIENT_ATTACHMENT_SUCCESS';
export const CREATE_CLIENT_ATTACHMENT_FAILURE = 'CREATE_CLIENT_ATTACHMENT_FAILURE';
export const DELETE_CLIENT_ATTACHMENT_REQUEST = 'DELETE_CLIENT_ATTACHMENT_REQUEST';
export const DELETE_CLIENT_ATTACHMENT_SUCCESS = 'DELETE_CLIENT_ATTACHMENT_SUCCESS';
export const DELETE_CLIENT_ATTACHMENT_FAILURE = 'DELETE_CLIENT_ATTACHMENT_FAILURE';
export const GET_CLIENT_ATTACHMENT_REQUEST = 'GET_CLIENT_ATTACHMENT_REQUEST';
export const GET_CLIENT_ATTACHMENT_SUCCESS = 'GET_CLIENT_ATTACHMENT_SUCCESS';
export const GET_CLIENT_ATTACHMENT_FAILURE = 'GET_CLIENT_ATTACHMENT_FAILURE';
export const GET_CLIENT_ATTACHMENTS_REQUEST = 'GET_CLIENT_ATTACHMENTS_REQUEST';
export const GET_CLIENT_ATTACHMENTS_SUCCESS = 'GET_CLIENT_ATTACHMENTS_SUCCESS';
export const GET_CLIENT_ATTACHMENTS_FAILURE = 'GET_CLIENT_ATTACHMENTS_FAILURE';

export const clientAttachmentsActions = {
  create,
  getList,
  del,
  get,
};

function create(attachment: any) {
  return async (dispatch: any, getState: any) => {
    const isAlreadyAdded = getState()?.clientAttachments?.list?.data?.find(
      (attachmentEl: any) =>
        attachmentEl.attachment_name === attachment.attachment_name || attachmentEl.attachment_name === attachment.name
    );
    if (isAlreadyAdded) {
      attachment['id'] = isAlreadyAdded.id;
      return attachment;
    }
    dispatch(request(attachment));
    try {
      const {data}: any = await ClientAttachmentsService.create(attachment);
      if (data?.id) {
        dispatch(success(data));
      }
      return data;
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request(attachment: any) {
    return {type: CREATE_CLIENT_ATTACHMENT_REQUEST, attachment};
  }

  function failure(status: string) {
    return {type: CREATE_CLIENT_ATTACHMENT_FAILURE, status};
  }

  function success(attachment: any) {
    return {type: CREATE_CLIENT_ATTACHMENT_SUCCESS, attachment};
  }
}

function getList() {
  return async (dispatch: any) => {
    dispatch(request());
    try {
      const clientAttachmentsList = await ClientAttachmentsService.getList();
      dispatch(success(clientAttachmentsList));
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request() {
    return {type: GET_CLIENT_ATTACHMENTS_REQUEST};
  }

  function success(clientAttachmentsList: any) {
    return {type: GET_CLIENT_ATTACHMENTS_SUCCESS, clientAttachmentsList};
  }

  function failure(status: string) {
    return {type: GET_CLIENT_ATTACHMENTS_FAILURE, status};
  }
}

function del(attachment_id: number) {
  return async (dispatch: any) => {
    dispatch(request(attachment_id));

    try {
      await ClientAttachmentsService.del(attachment_id);
      dispatch(success(attachment_id));
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request(attachment_id: number) {
    return {type: DELETE_CLIENT_ATTACHMENT_REQUEST, attachment_id};
  }

  function success(attachment_id: number) {
    return {type: DELETE_CLIENT_ATTACHMENT_SUCCESS, attachment_id};
  }

  function failure(status: string) {
    return {type: DELETE_CLIENT_ATTACHMENT_FAILURE, status};
  }
}

function get(attachment_id: number | string) {
  return async (dispatch: any) => {
    dispatch(request(attachment_id));

    try {
      const {data} = await ClientAttachmentsService.get(attachment_id);
      dispatch(success(attachment_id, data));
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request(attachment_id: number | string) {
    return {type: GET_CLIENT_ATTACHMENT_REQUEST, attachment_id};
  }

  function success(attachment_id: number | string, file: any) {
    return {type: GET_CLIENT_ATTACHMENT_SUCCESS, attachment_id, file};
  }

  function failure(status: string) {
    return {type: GET_CLIENT_ATTACHMENT_FAILURE, status};
  }
}
