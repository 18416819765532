import {GET_CLIENTS_MAINTENANCE} from './clientMaintenanceActions';

const initialState: any = {
  affiliatedClients: [],
};

export const clientMaintenanceReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CLIENTS_MAINTENANCE: {
      return {...state, affiliatedClients: action.affiliatedClients};
    }
    default:
      return state;
  }
};
