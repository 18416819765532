import {AudienceTreeService} from '../../services';

export const GET_AUDIENCE_TREE_REQUEST = 'GET_AUDIENCE_TREE_REQUEST';
export const GET_AUDIENCE_TREE_SUCCESS = 'GET_AUDIENCE_TREE_SUCCESS';
export const GET_AUDIENCE_TREE_FAILURE = 'GET_AUDIENCE_TREE_FAILURE';

export const audienceTreeActions = {
  getAudienceTree,
};

function getAudienceTree() {
  return async (dispatch: any) => {
    dispatch(request());

    const result = await AudienceTreeService.getAudienceTree();
    if (result.status === 200) {
      dispatch(success(result.data));
    } else {
      dispatch(failure(result.status));
    }
  };
  function request() {
    return {type: GET_AUDIENCE_TREE_REQUEST};
  }
  function success(data: any) {
    return {type: GET_AUDIENCE_TREE_SUCCESS, data};
  }
  function failure(status: string) {
    return {type: GET_AUDIENCE_TREE_FAILURE, status};
  }
}
