import * as React from 'react';

import './messageLengthCount.css';
import {isValidMessageLength} from '../../utils';

export const MessageLengthCount = ({text, maxLength}: any) => {
  const {isValid, message} = isValidMessageLength(text, maxLength);

  return (
    <span className={`message-length-count ${isValid ? '' : 'invalid'}`}>
      {`${message ? message.length : 0} / ${maxLength}`}
    </span>
  );
};
