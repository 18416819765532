import * as React from 'react';

import {InfoIcon} from '../../..';
import {STRINGS} from '../../../utils';

export const SearchFilterLabel = () => {
  return (
    <span style={{display: 'flex', alignItems: 'center'}}>
      <span>
        Search Filters URL* (
        <a
          href="https://www.linkedin.com/sales/search/people?viewAllFilters=true"
          target="_blank"
          rel="noreferrer noopener"
        >
          link
        </a>
        ):
      </span>
      <span style={{marginRight: 3, marginLeft: 5, cursor: 'pointer'}}>
        <InfoIcon message={STRINGS.tooltip.filter_url} />
      </span>
    </span>
  );
};
