import {Popover, Spin, Empty, Tooltip} from 'antd';
import * as React from 'react';

import './clickedLinksStats.scss';

function getPopoverContent(loading, stats) {
  if (loading) {
    return <Spin size="large" tip="Loading..." />;
  }
  if (!stats?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'This campaign has no clicked links'} />;
  }
  return (
    <div>
      {stats.map(link => (
        <div key={link.redirect_url} className="clicked-links-stats__row">
          <Tooltip title={link.redirect_url}>
            <span className="clicked-links-stats__link">{link.redirect_url}</span>
          </Tooltip>
          {' - '}
          <span className="clicked-links-stats__count">{link.total_click_count}</span>
        </div>
      ))}
    </div>
  );
}

export const ClickedLinksStats = ({stats, campaign, loading, children, getCampaignClickedLinksStats, filters}: any) => {
  return (
    <Popover
      onVisibleChange={visible =>
        visible ? getCampaignClickedLinksStats({campaign_id: campaign.id, date: filters.date}) : null
      }
      trigger="hover"
      className="clicked-links-stats"
      content={getPopoverContent(loading, stats)}
    >
      <div>{children}</div>
    </Popover>
  );
};
