import {message} from 'antd';

import {
  CREATE_INSTANCE_FAILURE,
  CREATE_INSTANCE_REQUEST,
  CREATE_INSTANCE_SUCCESS,
  DELETE_INSTANCE_FAILURE,
  DELETE_INSTANCE_REQUEST,
  DELETE_INSTANCE_SUCCESS,
  GET_INSTANCE_FAILURE,
  GET_INSTANCE_REQUEST,
  GET_INSTANCE_SUCCESS,
  REBOOT_INSTANCE_FAILURE,
  REBOOT_INSTANCE_REQUEST,
  REBOOT_INSTANCE_SUCCESS,
  SET_INSTANCE_SUCCESS,
  SET_PROGRESS_DONE,
  SET_PROGRESS_PERCENTAGE,
} from './adminMaintenanceActions';

const initialState: any = {
  getLoading: false,
  updateLoading: false,
  clientInstance: null,
};

export const adminMaintenanceReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_PROGRESS_DONE: {
      return {
        ...state,
        updateLoading: false,
        getLoading: false,
      };
    }
    case SET_INSTANCE_SUCCESS: {
      const percentage = action.data.percentage * 100 || 0;
      const next_percentage = action.data.next_percentage * 100 || 0;
      let updateLoading = true;

      if (action.data.state === 'SUCCESS') {
        updateLoading = false;
        if (action.data.action === 'reboot') {
          message.success('Rebooting the cloud instance completed successfully.');
        }
      }

      return {
        ...state,
        updateLoading,
        clientInstance: {...state?.clientInstance, task: {...action.data, percentage, next_percentage}},
      };
    }
    case SET_PROGRESS_PERCENTAGE: {
      return {
        ...state,
        updateLoading: true,
        clientInstance: {...state?.clientInstance, task: {...state?.clientInstance?.task, percentage: action.data}},
      };
    }
    case GET_INSTANCE_REQUEST: {
      return {...state, getLoading: true};
    }
    case GET_INSTANCE_SUCCESS: {
      let percentage = action.data?.task?.percentage * 100 || 0;
      let next_percentage = action.data?.task?.next_percentage * 100 || 0;
      const statePercentage = state?.clientInstance?.task?.percentage;
      const stateNextPercentage = state?.clientInstance?.task?.next_percentage;

      percentage = percentage > 0 && statePercentage < percentage ? percentage : statePercentage;
      next_percentage = next_percentage > 0 ? next_percentage : stateNextPercentage;
      let updateLoading = true;

      if (action.data?.task?.state === 'SUCCESS') {
        updateLoading = false;
      }

      return {
        ...state,
        getLoading: false,
        updateLoading,
        clientInstance: {
          ...action.data,
          task: {...action.data.task, percentage, next_percentage},
        },
      };
    }
    case GET_INSTANCE_FAILURE: {
      return {
        ...state,
        getLoading: false,
        clientInstance: {
          task: {},
        },
      };
    }
    case CREATE_INSTANCE_REQUEST: {
      return {...state, updateLoading: true};
    }
    case CREATE_INSTANCE_SUCCESS: {
      return {...state, updateLoading: false, createInstance: action.data};
    }
    case CREATE_INSTANCE_FAILURE: {
      return {...state, updateLoading: false};
    }
    case REBOOT_INSTANCE_REQUEST: {
      return {...state, updateLoading: true};
    }
    case REBOOT_INSTANCE_SUCCESS: {
      return {...state, updateLoading: false};
    }
    case REBOOT_INSTANCE_FAILURE: {
      return {...state, updateLoading: false};
    }
    case DELETE_INSTANCE_REQUEST: {
      return {...state, updateLoading: true};
    }
    case DELETE_INSTANCE_SUCCESS: {
      return {...state, updateLoading: false};
    }
    case DELETE_INSTANCE_FAILURE: {
      return {...state, updateLoading: false};
    }

    default:
      return state;
  }
};
