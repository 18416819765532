import {Button, Spin, Row, message, Select, Form} from 'antd';
import {Formik} from 'formik';
import {debounce} from 'lodash';
import React, {useEffect, useRef, useCallback, useState} from 'react';

import './hubspot.scss';

import {brandConfig, CONSTANTS, InfoIcon} from '@growth-x/ui';

export const Hubspot = props => {
  const POPUP_HEIGHT = 500;
  const POPUP_WIDTH = 500;
  const popupRef = useRef(null);
  const intervalRef = useRef(null);

  const {
    oauth2,
    setAuthorizationCode,
    getAuthorizedCredentials,
    clearAuthorizedCredentials,
    getUsers,
    getSearchProperty,
    configure,
  } = props;

  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    getAuthorizedCredentials();
  }, [getAuthorizedCredentials]);

  useEffect(() => {
    if (!authorized && oauth2?.authorized) {
      setAuthorized(true);
      getUsers();
    }
  }, [oauth2]);

  const generateState = () => {
    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let array = new Uint8Array(40);
    window.crypto.getRandomValues(array);
    array = array.map((x: number) => validChars.codePointAt(x % validChars.length));
    const randomState = String.fromCharCode.apply(null, array);
    return randomState;
  };

  const handleSubmit = data => {
    configure({
      hubspot_user_id: data.hubspot_user_id || null,
      custom_li_url_property_name: data.custom_li_url_property_name || null,
    });
  };

  const shouldShowSaveButton = (values, dirty) => {
    return dirty && (values.hubspot_user_id || values.custom_li_url_property_name);
  };

  const handleSearchPropertyName = debounce(value => {
    if (value) {
      getSearchProperty({property_name: value});
    }
  }, 750);
  const saveState = (state: string) => {
    sessionStorage.setItem(CONSTANTS.hs_oauth2_state_key, state);
  };

  const getFiledNameOptions = () => {
    let options = oauth2?.propertyNameOptions;
    if (oauth2?.custom_li_url_property_name) {
      options = [
        {name: oauth2?.custom_li_url_property_name, label: oauth2?.custom_li_url_property_label},
        ...oauth2?.propertyNameOptions,
      ];
    }
    return options.map(option => <Select.Option key={option.name}>{option.label}</Select.Option>);
  };

  const removeState = () => {
    sessionStorage.removeItem(CONSTANTS.hs_oauth2_state_key);
  };

  const openPopup = url => {
    const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
    const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
    return window.open(url, 'OAuth2 Popup', `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`);
  };

  const enhanceAuthorizeUrl = (state, redirect_uri) => {
    return `${CONSTANTS.hs_oauth2_url}&client_id=${brandConfig.hs_oauth2_client_id}&redirect_uri=${redirect_uri}&scope=${CONSTANTS.hs_oauth2_scope}&optional_scope=${CONSTANTS.hs_oauth2_optional_scope}&state=${state}`;
  };

  const closePopup = popupRef => {
    popupRef.current?.close();
  };

  const cleanup = useCallback((popupRef, handleMessageListener) => {
    closePopup(popupRef);
    removeState();
    window.removeEventListener('message', handleMessageListener);
  }, []);

  const getAuthCode = useCallback(() => {
    const redirect_uri = window.location.origin + CONSTANTS.hs_oauth2_redirect_path;
    const state = generateState();
    saveState(state);
    popupRef.current = openPopup(enhanceAuthorizeUrl(state, redirect_uri));

    async function handleMessageListener(msg) {
      if (msg.origin !== window.location.origin) {
        return;
      }
      try {
        const type = msg && msg.data && msg.data.type;
        if (type === CONSTANTS.hs_oauth2_message_response) {
          const errorMaybe = msg && msg.data && msg.data.error;
          if (errorMaybe) {
            message.error(errorMaybe);
          } else {
            const code = msg && msg.data && msg.data.payload && msg.data.payload.code;
            const app_id = brandConfig.hs_oauth2_app_id;
            if (code) {
              setAuthorizationCode({redirect_uri, code, app_id});
              getUsers();
            }
          }
          cleanup(popupRef, handleMessageListener);
        }
      } catch (genericError) {
        message.error(genericError);
        cleanup(popupRef, handleMessageListener);
      }
    }
    window.addEventListener('message', handleMessageListener);

    return () => {
      window.removeEventListener('message', handleMessageListener);
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [cleanup, setAuthorizationCode]);

  return (
    <Spin size="large" spinning={oauth2.isLoading}>
      {oauth2?.authorized ? (
        <Formik
          initialValues={{
            hubspot_user_id: oauth2?.hubspot_user_id,
            custom_li_url_property_name: oauth2?.custom_li_url_property_name,
          }}
          onSubmit={handleSubmit}
        >
          {({values, dirty, handleSubmit, setFieldValue}) => {
            return (
              <Form layout="vertical" className="hubspot-form" onSubmit={handleSubmit}>
                <Form.Item
                  label={
                    <>
                      {'Hubspot User:'}
                      <InfoIcon
                        message={
                          'Enter the HubSpot user email to associate your Growth-X account to HubSpot user. If you have multiple Growth-X accounts you can associate each account to a HubSpot user. For example, when you use Add to Growth-X Campaign feature in HubSpot, You will only see associated accounts campaigns'
                        }
                      />
                    </>
                  }
                >
                  <Select
                    allowClear
                    value={values.hubspot_user_id}
                    placeholder={'Hubspot User'}
                    style={{width: '100%'}}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onChange={value => setFieldValue('hubspot_user_id', value)}
                    notFoundContent={null}
                  >
                    {oauth2?.users?.map(option => (
                      <Select.Option key={option.id}>{option.email}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      Hubspot Custom Field for LinkedIn Profile URL of the Contact:
                      <InfoIcon
                        message={
                          'Provide the custom field where you store LinkedIn URL’s. This will help Growth-X to better outreach receivers when using Add to Growth-X Campaign feature in HubSpot'
                        }
                      />
                    </>
                  }
                >
                  <Select
                    allowClear
                    showSearch
                    value={values.custom_li_url_property_name}
                    placeholder={'Type for search..'}
                    style={{width: '100%'}}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={handleSearchPropertyName}
                    onChange={value => setFieldValue('custom_li_url_property_name', value)}
                    notFoundContent={null}
                  >
                    {getFiledNameOptions()}
                  </Select>
                </Form.Item>
                <Row style={{justifyContent: 'space-between'}} type="flex">
                  <Button type="danger" onClick={() => clearAuthorizedCredentials()}>
                    Disconnect
                  </Button>
                  {shouldShowSaveButton(values, dirty) && (
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  )}
                </Row>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Row style={{justifyContent: 'flex-end'}} type="flex">
          <Button type="primary" onClick={() => getAuthCode()}>
            Connect
          </Button>
        </Row>
      )}
    </Spin>
  );
};
