import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {IntercomProvider} from 'react-use-intercom';

import {brandConfig} from '@growth-x/ui';
import * as Sentry from '@sentry/react';

import App from './App';
import {ErrorBoundary} from './ErrorBoundary';
import {store} from './redux';
import {CONSTANTS} from './services/';
import {interceptor} from './services/apiService';
import {init} from './services/sockets';
import * as serviceWorker from './serviceWorker';

init(store);
interceptor(store);

if (process.env.NX_SENTRY === 'enabled') {
  Sentry.init({
    dsn: 'https://e669fd7ce123984822be0a4a5f7812df@o1202726.ingest.us.sentry.io/4506950420004864',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (brandConfig.gtmId && process.env.NX_GTM === 'enabled') {
  TagManager.initialize({
    gtmId: brandConfig.gtmId,
  });
}

ReactDOM.render(
  <ErrorBoundary fallback={<>There is a problem on our end. Please, try to refresh the page.</>}>
    <IntercomProvider appId={CONSTANTS.intercomAppId}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </IntercomProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
