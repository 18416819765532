import {Modal, Row, Select, Form, Button} from 'antd';
import * as React from 'react';

import './connectionTagsModal.css';

const {Option} = Select;

interface TagsModalProps {
  handleCancel: () => void;
  handleOk: (addTags: Array<string>, removeTags: Array<string>) => void;
  tags: Array<string>;
  visible: boolean;
}

interface TagsModalState {
  selectedAddTags: Array<string>;
  selectedRemoveTags: Array<string>;
}

export class ConnectionTagsModal extends React.Component<TagsModalProps, TagsModalState> {
  state: TagsModalState = {
    selectedAddTags: [],
    selectedRemoveTags: [],
  };

  handleSubmit = () => {
    this.props.handleOk(this.state.selectedAddTags, this.state.selectedRemoveTags);
    this.setState({selectedAddTags: [], selectedRemoveTags: []});
  };

  handleCancel = () => {
    this.props.handleCancel();
    this.setState({selectedAddTags: [], selectedRemoveTags: []});
  };

  handleAddTagsChange = (tags: any) => {
    this.setState({selectedAddTags: tags});
  };

  handleRemoveTagsChange = (tags: any) => {
    this.setState({selectedRemoveTags: tags});
  };

  render() {
    const children: any[] = [];
    if (this.props.tags && this.props.tags.length) {
      this.props.tags.forEach((tag: any) => {
        children.push(<Option key={tag}>{tag}</Option>);
      });
    }

    const removeChildren = [];
    for (let i = 10; i < 36; i++) {
      removeChildren.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    }

    return (
      <Modal
        visible={this.props.visible}
        onCancel={this.handleCancel}
        footer={null}
        width={600}
        title="Contact Details"
        className="tags-modal"
      >
        <Form layout="vertical">
          <Form.Item label="Add Tags:">
            <Select
              mode="tags"
              style={{width: '100%'}}
              placeholder="Select or type tags..."
              value={this.state.selectedAddTags}
              onChange={this.handleAddTagsChange}
            >
              {children}
            </Select>
          </Form.Item>
          <Form.Item label="Remove Tags:">
            <Select
              mode="tags"
              style={{width: '100%'}}
              placeholder="Select or type tags..."
              value={this.state.selectedRemoveTags}
              onChange={this.handleRemoveTagsChange}
            >
              {children}
            </Select>
          </Form.Item>
          <Row className="tags-modal__buttons-row" type="flex">
            <Button onClick={this.handleCancel}>Cancel</Button>
            <Button className="tags-modal__submit-button" type="primary" onClick={this.handleSubmit}>
              Save
            </Button>
          </Row>
        </Form>
      </Modal>
    );
  }
}
