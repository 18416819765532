import {Tooltip} from 'antd';
import * as React from 'react';

import {OutreachedTooltip} from './OutreachedTooltip';

export const Outreached = ({invite, nurtured, inmail, message_request}: any) => {
  const total = (invite || 0) + (nurtured || 0) + (inmail || 0) + (message_request || 0);
  const columnText = <span>{total ? `${total}` : '-'}</span>;
  if (total) {
    return (
      <Tooltip
        title={
          <OutreachedTooltip invite={invite} nurtured={nurtured} inmail={inmail} message_request={message_request} />
        }
      >
        {columnText}
      </Tooltip>
    );
  }
  return columnText;
};
