import {Input, Row, Button} from 'antd';
import React from 'react';

export const Disconnect = ({loading, disconnect}) => {
  return (
    <Row type="flex" className="instantly-disconnect">
      <Input placeholder={'Instantly API Key'} disabled value="****************" />
      <Button type="danger" onClick={disconnect} loading={loading}>
        Disconnect
      </Button>
    </Row>
  );
};
