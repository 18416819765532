import * as React from 'react';
import {LoginForm, RegisterForm} from '../';
import {Alert} from 'antd';
import {STRINGS} from '../../utils';
import './signInForm.css';

export const SignInForm = ({client, login, signup, isSignup}: any) => {
  return (
    <div className="signin-form">
      {!isSignup && <LoginForm client={client} login={login} />}
      {isSignup && <RegisterForm client={client} signup={signup} />}
      {client.unauthorizedError && (
        <Alert
          message=""
          description={STRINGS.warning.login_incognito_cookie}
          type="error"
          style={{marginBottom: '10px'}}
        />
      )}
    </div>
  );
};
