import React from 'react';
import {CustomView, isBrowser, isTablet} from 'react-device-detect';

export function BrowserView({children, ...props}) {
  return (
    <CustomView condition={isBrowser || isTablet} {...props}>
      {children}
    </CustomView>
  );
}
