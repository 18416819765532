import {AxiosResponse} from 'axios';

import {InstantlyCampaign} from '@growth-x/types';

export class InstantlyService {
  client: any;
  serviceName = 'instantly';

  constructor({client}: any) {
    this.client = client;
  }

  connect(api_key: string) {
    return this.client.post(this.serviceName + '/authorize', {api_key});
  }

  disconnect() {
    return this.client.delete(this.serviceName + '/delete_details');
  }

  getCampaigns(): AxiosResponse<InstantlyCampaign[]> {
    return this.client.get(this.serviceName + '/campaigns', {});
  }

  verifyToken() {
    return this.client.get(this.serviceName + '/verify', {});
  }
}
