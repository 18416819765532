import {
  GET_PROSPECTS_REQUEST,
  GET_PROSPECTS_SUCCESS,
  GET_PROSPECTS_FAILURE,
  LOAD_MORE_PROSPECTS_REQUEST,
  LOAD_MORE_PROSPECTS_SUCCESS,
  GET_PROSPECTS_TOTAL_SUCCESS,
  GET_PROSPECTS_TOTAL_FAILURE,
  GET_PROSPECTS_TOTAL_REQUEST,
} from './prospectActions';

const initialState: any = {
  list: [],
  total: 0,
  prospectsLoading: false,
  prospectsError: '',
  totalLoading: false,
};

export const prospectsReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PROSPECTS_REQUEST:
      return {
        ...state,
        prospectsLoading: true,
      };

    case GET_PROSPECTS_SUCCESS:
      return {
        ...state,
        list: action.prospects,
        prospectsLoading: false,
      };

    case GET_PROSPECTS_FAILURE:
      return {
        ...state,
        prospectsLoading: false,
        prospectsError: action.error,
      };

    case LOAD_MORE_PROSPECTS_REQUEST:
      return {
        ...state,
        prospectsLoading: true,
      };

    case LOAD_MORE_PROSPECTS_SUCCESS:
      return {
        ...state,
        list: [...state.list, ...action.prospects],
        total: action.total,
        prospectsLoading: false,
      };

    case GET_PROSPECTS_TOTAL_REQUEST:
      return {
        ...state,
        totalLoading: true,
      };

    case GET_PROSPECTS_TOTAL_FAILURE:
      return {
        ...state,
        totalLoading: false,
      };

    case GET_PROSPECTS_TOTAL_SUCCESS:
      return {
        ...state,
        totalLoading: false,
        total: action.total,
      };

    default:
      return state;
  }
};
