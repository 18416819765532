import _ from 'lodash';

import {getCampaignTypes, isCampaignRetention, CONSTANTS} from '@growth-x/ui';

export const prepareHeaders = (campaigns, showColumns, archiveMode) => {
  const percantageMap = ['invites_to_connections_rate', 'connections_to_responses_rate', 'total_positive_responses'];
  const deepCopyCampaigns = _.cloneDeep(campaigns);
  const data = deepCopyCampaigns.map(c => {
    c['invites'] = !isCampaignRetention(c) ? c?.stats?.total_invites : '-';
    c['nurtured'] = isCampaignRetention(c) ? c?.stats?.total_messages : '-';
    c['campaign_type'] = getCampaignTypes(c?.type)?.value;
    c['outreached'] =
      (c.stats.total_invites || 0) +
      (c.stats.total_messages || 0) +
      (c.stats.inmail || 0) +
      (c.stats.message_request || 0);
    c['isArchived'] = c.archived ? 'Archived' : 'Active';
    c['action'] = c.actions.join(', ');
    percantageMap.forEach(pc => {
      c.stats[pc] = !c.stats[pc]?.toString().includes('%') ? `${c.stats[pc] || 0} %` : c.stats[pc];
    });
    return c;
  });

  if (archiveMode === 'all' && !showColumns.some(x => x === 'status')) {
    showColumns = [...showColumns, 'status'];
  }

  const headers = showColumns.map(col => {
    return {key: CONSTANTS.keyLabelMap[col]?.key || col, label: CONSTANTS.keyLabelMap[col]?.label || col};
  });
  return {headers, data};
};
