import {User} from '@growth-x/types';

import {LOGOUT} from '../client';
import {WS_APP_TASK_UPDATED, WS_USER_ARCHIVED, WS_USER_UNARCHIVED} from '../websocket';
import {
  CREATE_USER_CLEANUP,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  OPEN_USER_FAILURE,
  OPEN_USER_SUCCESS,
  UPDATE_CUSTOM_PROXY_VISIBLE,
  UPDATE_USER_DETAILS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_TASK_SUCCESS,
  USER_STATS_FAILURE,
  USER_STATS_REQUEST,
  USER_STATS_SUCCESS,
  VALIDATE_USER_FAILURE,
  VALIDATE_USER_SUCCESS,
  VALIDATE_USER_UPDATE_CODE_SUCCESS,
} from './usersActions';

const initialState: any = {
  list: undefined,
  usersLoading: false,
  usersListError: '',
  userDetailsLoading: false,
  userDetailsError: '',
  statsError: '',
  statsLoading: false,
  stats: [],
  userValidation: {},
  userTask: {},
  userDetails: null,
  customProxyVisible: false,
};

export const usersReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        userDetailsLoading: true,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        userDetailsLoading: false,
        list: state.list.map((user: User) => (user.id === action.user.id ? {...user, ...action.user} : user)),
      };

    case UPDATE_USER_FAILURE:
      return {
        ...state,
        userDetailsLoading: false,
        userDetailsError: action.error,
      };

    case CREATE_USER_REQUEST:
      return {
        ...state,
        userDetailsLoading: true,
      };

    case CREATE_USER_SUCCESS:
    case WS_USER_UNARCHIVED: {
      const isAdded = state.list.find((user: User) => user.id === action.user.id);

      if (isAdded) {
        return {
          ...state,
          userDetailsLoading: false,
          list: state.list.map((user: User) => (user.id === action.user.id ? action.user : user)),
        };
      } else {
        return {
          ...state,
          userDetailsLoading: false,
          list: [...state.list, action.user],
        };
      }
    }
    case WS_USER_ARCHIVED:
      return {
        ...state,
        list: state.list.filter((user: User) => user.id !== action.user.id),
      };
    case CREATE_USER_CLEANUP:
      return {
        ...state,
        userDetailsError: '',
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        userDetailsLoading: false,
        userDetailsError: action.error,
      };

    case GET_USERS_REQUEST:
      return {
        ...state,
        usersLoading: true,
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        usersLoading: false,
        list: action.users,
      };

    case GET_USERS_FAILURE:
      return {
        ...state,
        usersLoading: false,
        usersListError: action.error,
        list: [],
      };
    case USER_STATS_SUCCESS:
      return {
        ...state,
        statsLoading: false,
        stats: action.stats,
      };

    case USER_STATS_REQUEST:
      return {
        ...state,
        statsLoading: true,
      };

    case USER_STATS_FAILURE:
      return {
        ...state,
        statsLoading: false,
        statsError: action.error,
      };
    case LOGOUT:
      return {...initialState};
    case VALIDATE_USER_SUCCESS:
      return {
        ...state,
        userValidation: action.payload,
      };
    case VALIDATE_USER_FAILURE:
      return {
        ...state,
        userValidation: {},
      };
    case VALIDATE_USER_UPDATE_CODE_SUCCESS:
      return {
        ...state,
        userValidation: action.payload,
      };
    case OPEN_USER_SUCCESS:
      return {
        ...state,
        userTask: action.payload,
      };
    case OPEN_USER_FAILURE:
      return {
        ...state,
        userTask: {},
      };
    case UPDATE_USER_TASK_SUCCESS: {
      return {
        ...state,
        userTask: action.payload,
      };
    }
    case WS_APP_TASK_UPDATED: {
      if (state.userValidation?.id === action.app_task.id) {
        return {
          ...state,
          userValidation: action.app_task,
        };
      }
      if (state.userTask?.id === action.app_task.id) {
        return {
          ...state,
          userTask: action.app_task,
        };
      }
      return {
        ...state,
      };
    }
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        userDetails: action.userDetails,
      };
    case UPDATE_CUSTOM_PROXY_VISIBLE:
      return {
        ...state,
        customProxyVisible: action.customProxyVisible,
      };

    default:
      return state;
  }
};
