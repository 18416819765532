import {Button, Upload, Icon} from 'antd';
import {RcFile} from 'antd/lib/upload';
import {FormikProps} from 'formik';
import * as React from 'react';

type CsvInputProps = {
  csvFile: RcFile;
  csvName: string;
  disabled: boolean;
  link: string;
} & Pick<FormikProps<unknown>, 'setFieldValue'>;

export const CsvInput = ({setFieldValue, csvFile, csvName, disabled, link}: CsvInputProps) => {
  return (
    <Upload
      disabled={disabled}
      multiple={false}
      showUploadList={false}
      accept=".csv"
      beforeUpload={(file: RcFile): boolean => {
        setFieldValue('csv', file);

        return false;
      }}
    >
      <Button disabled={disabled}>
        <Icon type="upload" /> Click to Upload
      </Button>
      {link ? (
        <a style={{marginLeft: '10px'}} href={link} target="_blank" rel="noreferrer noopener">
          {csvName}
        </a>
      ) : (
        <span style={{marginLeft: '10px'}}>{csvFile?.name || csvName}</span>
      )}
    </Upload>
  );
};
