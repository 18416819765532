import {Spin, Empty, Divider} from 'antd';
import * as React from 'react';

import {isCampaignRetention} from '@growth-x/ui';

import {InfoIcon} from '../';
import {mapRawStatsToItems, mapRawStatsToErrors} from './audienceUtils';

export const PopoverContent = ({stats, campaign, client, loading, potential}: any) => {
  const isAdmin = client?.intercom_disabled;
  const items = mapRawStatsToItems(stats, campaign);
  const errorItems = mapRawStatsToErrors(stats, campaign);
  return (
    <div className="audience-stats__popover">
      {loading ? (
        <Spin size="large" tip="Loading..." />
      ) : !stats?.length ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            isCampaignRetention(campaign)
              ? 'This campaign has no specific data about potential. Please enable campaign (ON) and we’ll gather data the next time that it will run.'
              : 'This campaign has no specific data about potential. Please enable Invites (I) and we’ll gather data the next time that it will run.'
          }
        />
      ) : (
        <>
          <div className="audience-stats__items-container">
            <span>{`potential: ${potential}`}</span>
            {/* <span className="audience-stats__potential-divider">{' • '}</span>
            <span>{`reach: ${reach}`}</span> */}
          </div>
          <div className="audience-stats__progress-line">
            {items.map(val => (
              <span
                style={{
                  backgroundColor: val.color,
                  flexGrow: val.total,
                }}
              ></span>
            ))}
          </div>
          <div className="audience-stats__items-container">
            {items.map(val => (
              <span className="audience-stats__item-container">
                <span
                  className="audience-stats__color-circle"
                  style={{
                    background: val.color,
                  }}
                ></span>
                <span style={{marginLeft: 5, ...val.styles}}>
                  {val.label} {val.tooltip && <InfoIcon message={val.tooltip} />}{' '}
                  <span className="audience-stats__item-total">{`${val.total}`}</span>
                </span>
              </span>
            ))}
          </div>
          {isAdmin && !!errorItems?.length && (
            <>
              <Divider className="audience-stats__errors-divider" orientation="left">
                Errors
              </Divider>
              <div className="audience-stats__items-container">
                {errorItems.map(val => (
                  <span className="audience-stats__item-container">
                    <span
                      className="audience-stats__color-circle"
                      style={{
                        background: val.color,
                      }}
                    ></span>
                    <span style={{marginLeft: 5, ...val.styles}}>
                      {val.label} <span className="audience-stats__item-total">{`${val.total}`}</span>
                    </span>
                  </span>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
