import {Tooltip, Icon, Dropdown, Button, Menu, Radio, Select, Input} from 'antd';
import * as React from 'react';

import {brandConfig} from '@growth-x/ui';

import {CONSTANTS} from '../../services/constants';

const InputGroup = Input.Group;
const RadioGroup = Radio.Group;
const Option = Select.Option;

export const ProspectsRightActions = ({
  client,
  handleExportAll,
  handleRefresh,
  prospectsLoading,
  handleSettingsChange,
  settingsEmail,
}: any) => {
  const [creditSelect, setCreditSelect] = React.useState(0);
  const showCreditSelect = client.data_credits <= 0;
  const settingsMenu = (
    <Menu>
      <RadioGroup className="prospects-page__settings-radios" onChange={handleSettingsChange} value={settingsEmail}>
        <Radio value={'email.personal'}>Personal Email</Radio>
        <br />
        <Radio value={'email.work'}>Work Email</Radio>
        <br />
        <Radio value={'email'}>Any Email</Radio>
        <br />
      </RadioGroup>
    </Menu>
  );
  return (
    <div className="prospects-page__right-buttons" style={{width: showCreditSelect ? '380px' : '200px'}}>
      <span style={{marginRight: '10px'}}>
        {client.data_credits} Credits{' '}
        <Tooltip title="Each credit count for 1 email match. You could also define your match requirements such as personal email or work email.">
          <Icon type="info-circle" className="tooltip-icon" />
        </Tooltip>
      </span>
      {showCreditSelect && !brandConfig.isAffiliate ? (
        <InputGroup compact style={{width: '180px'}}>
          <Select onChange={(val: any) => setCreditSelect(val)} value={creditSelect} style={{width: '140px'}}>
            {CONSTANTS.credit_packages.map((option: any) => (
              <Option value={option.value} key={option.value}>
                {option.name}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            icon="shopping-cart"
            target="_blank"
            disabled={!creditSelect}
            href={`mailto:${brandConfig.web}?subject=Request%20to%20Buy%20${
              CONSTANTS.credit_packages.find((o: any) => o.value === creditSelect).name
            }%20Data%20Credits`}
          />
        </InputGroup>
      ) : null}
      <Tooltip title="Settings">
        <Dropdown overlay={settingsMenu} trigger={['click']}>
          <Button shape="circle" icon="setting" />
        </Dropdown>
      </Tooltip>
      <Tooltip title="Export All Prospects">
        <Button onClick={handleExportAll} shape="circle" icon="download" />
      </Tooltip>
      <Tooltip title="Refresh">
        <Button onClick={handleRefresh} loading={prospectsLoading} shape="circle" icon="reload" />
      </Tooltip>
    </div>
  );
};
