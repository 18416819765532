import {Tooltip as AntdTooltip} from 'antd';
import React from 'react';

import {replaceLinkByTag} from '../../utils';

export function TooltipWithLink({title, overlayClassName, children}) {
  return (
    <AntdTooltip
      overlayClassName={overlayClassName}
      title={typeof title !== 'string' ? title : <span dangerouslySetInnerHTML={{__html: replaceLinkByTag(title)}} />}
    >
      {children}
    </AntdTooltip>
  );
}
