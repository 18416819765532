import {Button, Col, Form, Input, Row, Select, Switch, Modal} from 'antd';
import React from 'react';

import {User} from '@growth-x/types';
import {AdminFeatureIcon} from '@growth-x/ui';

interface UserProxyProps {
  user: User | any;
  isAdmin: boolean;
  errors: any;
  touched: any;
  values: any;
  setFieldValue: any;
  handleBlur: any;
  sortedLocations: any;
  handleLocationChange: any;
  customProxyVisible: boolean;
  onSwitchCheckedChange: any;
  removeUserCustomProxy: any;
  replaceUserProxy: any;
}

export function UserProxy({
  user,
  errors,
  isAdmin,
  handleBlur,
  touched,
  setFieldValue,
  values,
  sortedLocations,
  customProxyVisible,
  onSwitchCheckedChange,
  removeUserCustomProxy,
  handleLocationChange,
  replaceUserProxy,
}: UserProxyProps) {
  const userLocation = user?.proxy?.location;
  const currentProxy =
    userLocation && userLocation?.city ? userLocation.city : user?.proxy?.city ? user.proxy.city : '';
  return user?.proxy?.ip ? (
    <Form.Item
      style={{marginTop: 10}}
      labelCol={{span: 10}}
      wrapperCol={{span: 14}}
      label={
        <span>
          Proxy IP location: <AdminFeatureIcon />
        </span>
      }
    >
      <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: -5}}>
        <div style={{textAlign: 'right', marginRight: 10}}>{`${user.proxy.ip}  ${
          currentProxy && `(${currentProxy})`
        }`}</div>
        <Button
          type="danger"
          onClick={() => {
            removeUserCustomProxy({user: user.id});
          }}
        >
          Clear
        </Button>
        {isAdmin && !user.is_custom_proxy && (
          <Button
            style={{marginLeft: 5}}
            type="ghost"
            onClick={() => {
              Modal.confirm({
                title: 'This action will result in a charge',
                okText: 'Confirm',
                cancelText: 'Cancel',
                onOk: () => replaceUserProxy(user.id),
                onCancel: null,
              });
            }}
          >
            Replace
          </Button>
        )}
      </div>
    </Form.Item>
  ) : (
    <>
      <Form.Item
        label={
          <span>
            Proxy IP location: <AdminFeatureIcon />
          </span>
        }
      >
        <Row gutter={16} type="flex" justify="space-around" align="middle">
          <Col span={14}>
            <Select
              showSearch
              placeholder="Select the proxy location"
              optionFilterProp="children"
              value={values.location}
              onChange={value => handleLocationChange(value)}
              getPopupContainer={trigger => trigger.parentElement}
            >
              {sortedLocations.map(location => (
                <Select.Option value={location.join('/')}>{location.filter(t => t).join('/')}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={10}>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={18}>
                <div style={{fontSize: 12}}>
                  <b style={{float: 'right'}}>Use custom proxy location</b>
                </div>
              </Col>
              <Col span={6}>
                <Switch
                  style={{float: 'right'}}
                  checked={customProxyVisible}
                  onChange={value => onSwitchCheckedChange(value)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form.Item>
      {customProxyVisible && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Ip:"
              validateStatus={errors.custom_proxy_ip && touched.custom_proxy_ip ? 'error' : ''}
              hasFeedback
              help={errors.custom_proxy_ip && touched.custom_proxy_ip ? errors.custom_proxy_ip : ''}
            >
              <Input
                name="ip"
                value={values.custom_proxy_ip}
                placeholder="___.___.___.___"
                onChange={ev => setFieldValue('custom_proxy_ip', ev.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Username:"
              validateStatus={errors.custom_proxy_username && touched.custom_proxy_username ? 'error' : ''}
              hasFeedback
              help={errors.custom_proxy_username && touched.custom_proxy_username ? errors.custom_proxy_username : ''}
            >
              <Input
                name="custom_proxy_username"
                value={values.custom_proxy_username}
                placeholder="Type the username"
                onChange={ev => setFieldValue('custom_proxy_username', ev.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Port:"
              validateStatus={errors.custom_proxy_port && touched.custom_proxy_port ? 'error' : ''}
              hasFeedback
              help={errors.custom_proxy_port && touched.custom_proxy_port ? errors.custom_proxy_port : ''}
            >
              <Input
                name="custom_proxy_port"
                value={values.custom_proxy_port}
                placeholder="Type the port"
                onChange={ev => setFieldValue('custom_proxy_port', ev.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Password:"
              validateStatus={errors.custom_proxy_password && touched.custom_proxy_password ? 'error' : ''}
              hasFeedback
              help={errors.custom_proxy_password && touched.custom_proxy_password ? errors.custom_proxy_password : ''}
            >
              <Input.Password
                autoComplete="new-password"
                value={values.custom_proxy_password}
                name="custom_proxy_password"
                className={errors.custom_proxy_password && touched.custom_proxy_password ? 'invalid-input' : ''}
                onChange={ev => setFieldValue('custom_proxy_password', ev.target.value)}
                onBlur={handleBlur}
                placeholder={user.proxy?.custom_proxy_password ? '*******************' : 'Type your password'}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
}
