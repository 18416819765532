function escapeForRegex(str) {
  const regex = new RegExp(/\+|\*|\(|\[|</, 'g');
  return str.replace(regex, '\\$&');
}

export const replaceKeys = (message: string, keys: string[], values: string[]) => {
  keys.forEach((item: string, index: number) => {
    const escapedText = escapeForRegex(item);
    const regex = new RegExp(escapedText, 'g');
    message = message.replace(regex, values[index]);
  });
  return message;
};
