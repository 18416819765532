import {Modal, Input, Form, Button, Alert} from 'antd';
import React, {useState} from 'react';

import {brandConfig} from '../../utils';
import {OpenFeatureContent} from './OpenFeatureContent';

const {confirm} = Modal;

export function UserOpenModal({
  isOpen,
  onClose,
  client,
  userTask,
  updateUserTask,
  openUser,
  user,
  validatePassword,
  onToggleAddressBar,
}) {
  const shouldVerifyUser = !client.open_feature_trusted;
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const verifyUserPassword = async e => {
    e.preventDefault();
    setIsLoading(true);
    const form = new FormData(e.target);
    const password = form.get('password');
    const result = await validatePassword(user.id, password);
    if (result) {
      setIsVerified(true);
    }
    setIsLoading(false);
  };

  const handleCancel = task => {
    if (['connected', 'done', 'error'].includes(task.status)) {
      setIsVerified(false);
      setIsLoading(false);
      onClose(task);
      return;
    }
    confirm({
      title: 'Please, confirm your action.',
      content: 'After closing this window you will need to wait until the process is finished.',
      onOk() {
        setIsVerified(false);
        setIsLoading(false);
        onClose(task);
      },
      onCancel: null,
    });
  };

  return (
    <Modal
      title="Opening LinkedIn User"
      visible={isOpen}
      footer={null}
      destroyOnClose={true}
      maskClosable={false}
      width={!shouldVerifyUser || (shouldVerifyUser && isVerified) ? 1000 : 410}
      onCancel={() => handleCancel(userTask)}
    >
      {shouldVerifyUser && !isVerified && (
        <>
          <Form layout="inline" onSubmit={values => verifyUserPassword(values)}>
            <Form.Item>
              <Input.Password
                name="password"
                type="password"
                style={{width: 250}}
                placeholder="Type user LinkedIn password"
              />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" loading={isLoading} type="primary">
                Verify
              </Button>
            </Form.Item>
          </Form>
          <div style={{marginTop: 10}}>
            <Alert
              message={`To disable this password authentication, please send a request to ${brandConfig.contact}`}
              type="warning"
            />
          </div>
        </>
      )}
      {(!shouldVerifyUser || (shouldVerifyUser && isVerified)) && (
        <OpenFeatureContent
          client={client}
          updateUserTask={updateUserTask}
          userTask={userTask}
          openUser={openUser}
          user={user}
          onToggleAddressBar={onToggleAddressBar}
        />
      )}
    </Modal>
  );
}
