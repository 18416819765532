import {message} from 'antd';

import {brandConfig} from '@growth-x/ui';

import {MembersService, CsmService} from '../../services';
import {clientActions} from '../client';

export const GET_MEMBERS_REQUEST = 'GET_MEMBERS_REQUEST';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_FAILURE = 'GET_MEMBERS_FAILURE';
export const ADD_MEMBER_REQUEST = 'ADD_MEMBER_REQUEST';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_FAILURE = 'ADD_MEMBER_FAILURE';
export const REMOVE_MEMBER_REQUEST = 'REMOVE_MEMBER_REQUEST';
export const REMOVE_MEMBER_SUCCESS = 'REMOVE_MEMBER_SUCCESS';
export const REMOVE_MEMBER_FAILURE = 'REMOVE_MEMBER_FAILURE';
export const UPDATE_MEMBER_REQUEST = 'UPDATE_MEMBER_REQUEST';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAILURE = 'UPDATE_MEMBER_FAILURE';
export const SEND_INVITATION_REQUEST = 'SEND_INVITATION_REQUEST';
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS';
export const SEND_INVITATION_FAILURE = 'SEND_INVITATION_FAILURE';
export const CHECK_INVITATION_REQUEST = 'CHECK_INVITATION_REQUEST';
export const CHECK_INVITATION_SUCCESS = 'CHECK_INVITATION_SUCCESS';
export const CHECK_INVITATION_FAILURE = 'CHECK_INVITATION_FAILURE';
export const SET_MEMBER_PASSWORD_REQUEST = 'SET_MEMBER_PASSWORD_REQUEST';
export const SET_MEMBER_PASSWORD_SUCCESS = 'SET_MEMBER_PASSWORD_SUCCESS';
export const SET_MEMBER_PASSWORD_FAILURE = 'SET_MEMBER_PASSWORD_FAILURE';
export const SET_CSM_LIST = 'SET_CSM_LIST';

export const membersActions = {
  getMembers,
  addMember,
  removeMember,
  updateMember,
  checkInvitation,
  setPassword,
  sendInvitation,
  getCsmList,
};

function getMembers() {
  return (dispatch: any) => {
    dispatch(request());
    return MembersService.getMembers().then(
      ({data}: any) => {
        dispatch(success(data));
      },
      (error: string) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {type: GET_MEMBERS_REQUEST};
  }
  function success(members: any) {
    return {type: GET_MEMBERS_SUCCESS, members};
  }
  function failure(error: string) {
    message.error(`Sorry, something goes wrong, can't get the members list.`, 5);
    return {type: GET_MEMBERS_FAILURE, error};
  }
}

function getCsmList() {
  return async (dispatch: any) => {
    const {data} = await CsmService.getCsmList();
    dispatch({type: SET_CSM_LIST, data});
  };
}

function addMember(member) {
  return (dispatch: any) => {
    dispatch(request());
    return MembersService.addMember(member).then(
      ({data}: any) => {
        dispatch(success(data));
      },
      error => {
        const message = error?.response?.data?.email?.[0]?.message || error?.response?.data?.user?.[0]?.message;
        dispatch(failure(message || `Sorry, something goes wrong, can't add this member.`));
      }
    );
  };

  function request() {
    return {type: ADD_MEMBER_REQUEST};
  }
  function success(member: any) {
    return {type: ADD_MEMBER_SUCCESS, member};
  }
  function failure(error: string) {
    message.error(error, 5);
    return {type: ADD_MEMBER_FAILURE, error};
  }
}

function removeMember(id) {
  return (dispatch: any) => {
    dispatch(request());
    return MembersService.removeMember(id).then(
      () => {
        dispatch(success(id));
      },
      (error: string) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {type: REMOVE_MEMBER_REQUEST};
  }
  function success(id: any) {
    return {type: REMOVE_MEMBER_SUCCESS, id};
  }
  function failure(error: string) {
    message.error(`Sorry, something goes wrong, can't remove this member.`, 5);
    return {type: REMOVE_MEMBER_FAILURE, error};
  }
}

function updateMember(id, member) {
  return (dispatch: any) => {
    dispatch(request());
    return MembersService.updateMember(id, member).then(
      ({data}: any) => {
        dispatch(success(data));
      },
      error => {
        const message = error?.response?.data?.user[0]?.message;
        dispatch(failure(message || `Sorry, something goes wrong, can't update this member.`));
      }
    );
  };

  function request() {
    return {type: UPDATE_MEMBER_REQUEST};
  }
  function success(member: any) {
    return {type: UPDATE_MEMBER_SUCCESS, member};
  }
  function failure(error: string) {
    message.error(error, 5);
    return {type: UPDATE_MEMBER_FAILURE, error};
  }
}

function checkInvitation(uid, token) {
  return (dispatch: any) => {
    dispatch(request());
    if (!uid || !token) {
      dispatch(failure('Something goes wrong. Please, ask administrator resend invitation.'));
      return;
    }
    return MembersService.checkToken(uid, token).then(
      () => {
        dispatch(success());
      },
      error => {
        const message = error?.response?.data?.message;
        dispatch(failure(message || `Something goes wrong, please, contact ${brandConfig.contact}.`));
      }
    );
  };

  function request() {
    return {type: CHECK_INVITATION_REQUEST};
  }
  function success() {
    return {type: CHECK_INVITATION_SUCCESS};
  }
  function failure(error: string) {
    return {type: CHECK_INVITATION_FAILURE, error};
  }
}

function setPassword(data) {
  return (dispatch: any) => {
    dispatch(request());
    return MembersService.setPassword(data).then(
      () => {
        dispatch(success());
        dispatch(clientActions.getClient());
      },
      error => {
        const message = error?.response?.data?.message;
        dispatch(failure(message || `Sorry, something goes wrong, can't set password.`));
      }
    );
  };

  function request() {
    return {type: SET_MEMBER_PASSWORD_REQUEST};
  }
  function success() {
    return {type: SET_MEMBER_PASSWORD_SUCCESS};
  }
  function failure(error: string) {
    return {type: SET_MEMBER_PASSWORD_FAILURE, error};
  }
}

function sendInvitation(id) {
  return (dispatch: any) => {
    dispatch(request());
    return MembersService.sendInvitation(id).then(
      () => {
        dispatch(success());
      },
      (error: string) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {type: SEND_INVITATION_REQUEST};
  }
  function success() {
    message.success(`New email successfully sent to the member.`, 5);
    return {type: SEND_INVITATION_SUCCESS};
  }
  function failure(error: string) {
    message.error(`Sorry, something goes wrong, can't resend email to the member.`, 5);
    return {type: SEND_INVITATION_FAILURE, error};
  }
}
