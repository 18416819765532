import {WebSocketClient} from '@growth-x/ui';

import {adminMaintenanceActions} from '../redux/adminMaintenance';
import {campaignsActions} from '../redux/campaigns';
import {clientActions} from '../redux/client';
import {inboxActions} from '../redux/inbox';
import {notificationsActions} from '../redux/notifications';
import {usersActions} from '../redux/users';
import {
  WS_CAMPAIGN_CREATED,
  WS_CAMPAIGN_UPDATED,
  WS_CAMPAIGN_USER_ADDED,
  WS_CAMPAIGN_USER_REMOVED,
  WS_USER_UPDATED,
  WS_USER_CREATED,
  WS_CLIENT_LOGOUT,
  WS_USER_ARCHIVED,
  WS_USER_UNARCHIVED,
  WS_APP_TASK_UPDATED,
  WS_INBOX_CONVERSATION_UNSNOOZED,
  WS_CLOUD_INSTANCE_STATE_CHANGE,
  WS_CLIENT_UPDATED,
  WS_NOTIFICATION_CREATED,
} from '../redux/websocket';
import {CONSTANTS} from './constants';
const socket = new WebSocketClient();
socket.open(CONSTANTS.ws_url);

socket.onError = () => {
  // console.log('on error ', evt);
};

export const init = (store: any) => {
  socket.shouldReconnect = () => {
    const state = store ? store.getState() : null;
    return state && state.client && state.client.data;
  };
  socket.onMessage = (evt: any) => {
    try {
      const payload = JSON.parse(evt.data);
      const isDataUpdated = payload.type === 'data_updated';
      const postfix = isDataUpdated ? `_${payload.data_type}_${payload.data_action}` : '';
      const actionType = `${payload.type}${postfix}`;
      console.log('dispatch ws action ', actionType, payload);
      if (actionType === WS_CAMPAIGN_CREATED) {
        store.dispatch(campaignsActions.campaignCreatedWS(payload.campaign));
      } else if (actionType === WS_CLIENT_UPDATED) {
        store.dispatch(clientActions.updateClientData(payload.client));
      } else if (actionType === WS_CAMPAIGN_UPDATED) {
        store.dispatch(campaignsActions.campaignUpdatedWS(payload.campaign));
      } else if (actionType === WS_CAMPAIGN_USER_ADDED) {
        store.dispatch(campaignsActions.campaignAddUserWS(payload));
      } else if (actionType === WS_CAMPAIGN_USER_REMOVED) {
        store.dispatch(campaignsActions.campaignRemoveUserWS(payload));
      } else if (actionType === WS_USER_UPDATED) {
        // TODO check on backend side that WS event comes in one style
        store.dispatch(
          usersActions.userUpdatedWS(payload.users || (Array.isArray(payload.user) ? payload.user : [payload.user]))
        );
      } else if (actionType === WS_USER_CREATED) {
        store.dispatch(usersActions.userCreatedWS(payload.user));
      } else if (actionType === WS_CLIENT_LOGOUT) {
        store.dispatch(clientActions.forceLogout());
      } else if (actionType === WS_USER_ARCHIVED) {
        // TODO check on backend side that WS event comes in one style
        store.dispatch(
          usersActions.userArchivedWS(payload.users || (Array.isArray(payload.user) ? payload.user : [payload.user]))
        );
      } else if (actionType === WS_USER_UNARCHIVED) {
        // TODO check on backend side that WS event comes in one style
        store.dispatch(
          usersActions.userUnarchivedWS(payload.users || (Array.isArray(payload.user) ? payload.user : [payload.user]))
        );
      } else if (actionType === WS_APP_TASK_UPDATED) {
        if (payload.app_task && payload.app_task.code === 'send_message') {
          store.dispatch(inboxActions.inboxUpdated(payload.app_task));
        } else {
          store.dispatch({
            ...payload,
            type: actionType,
          });
        }
      } else if (actionType === WS_INBOX_CONVERSATION_UNSNOOZED) {
        store.dispatch(inboxActions.conversationUnsnoozed()); // payload.conversations
      } else if (actionType === WS_NOTIFICATION_CREATED) {
        store.dispatch(notificationsActions.pushNotifications([payload.notification]));
      } else if (actionType === WS_CLOUD_INSTANCE_STATE_CHANGE) {
        setTimeout(() => {
          store.dispatch(adminMaintenanceActions.setStateChange(payload.data));
        }, 500);
      } else {
        store.dispatch({
          ...payload,
          type: actionType,
        });
      }
    } catch (e) {
      console.error(`Websockets error :`, e);
    }
  };
};

export const open = () => {
  socket.open(CONSTANTS.ws_url);
};

export const emit = (payload: any) => {
  socket.send(JSON.stringify({...payload}));
};
