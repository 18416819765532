import {Tooltip} from 'antd';
import React from 'react';

import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';

import {brandConfig} from '../../utils';

library.add(faUserShield);

interface IAdminFeatureIcon {
  color?: string;
}
export function AdminFeatureIcon({color}: IAdminFeatureIcon) {
  return (
    <Tooltip title="This feature is only visible to admin login">
      <FontAwesomeIcon
        style={{color: color || brandConfig.primaryColor, marginRight: '5px'}}
        icon={['fas', 'user-shield']}
      />
    </Tooltip>
  );
}
