import {Tooltip, Select} from 'antd';
import React, {useCallback, useState} from 'react';

import {CONSTANTS} from '@growth-x/ui';

type TagSelectorProps = {
  showColumns: Array<string>;
  handleChangeColumns: (columns: Array<string>, columnType: string) => void;
  clientTags: Array<any>;
};

export const TagSelector: React.FC<TagSelectorProps> = ({showColumns, handleChangeColumns, clientTags}: TagSelectorProps) => {
  const [selectedTags, setSelectedTags] = useState<Array<string>>(() => {
    return [...new Set([...showColumns.filter((column: string) => !CONSTANTS.campaignColumns.find(c => c.value === column))])];
  });

  const tagColumnChange = useCallback(
    (newColumns: Array<string>) => {
      setSelectedTags(newColumns);
      handleChangeColumns(newColumns, 'tags');
    },
    [handleChangeColumns]
  );

  return (
    <Select
      id="right-action-tag-selector"
      placeholder="Add tags"
      mode="multiple"
      allowClear
      maxTagCount={0}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 10px',
      }}
      onChange={tagColumnChange}
      value={selectedTags}
    >
      {[...new Set([...clientTags.map(tag => tag.name), ...CONSTANTS.defaultTags])].map(tag => {
        return (
          <Select.Option className="receivers-list__filter-option" key={tag}>
            {tag.length > 10 ? (
              <Tooltip key={tag} title={tag}>
                {tag}
              </Tooltip>
            ) : (
              tag
            )}
          </Select.Option>
        );
      })}
    </Select>
  );
};
