export class LinkPreviewService {
  client: any;
  serviceName = '';
  constructor({client}: any) {
    this.client = client;
  }
  getPreview(url) {
    return this.client.get('link_preview/generate', {url});
  }
}
