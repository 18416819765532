import * as React from 'react';
import {Tooltip, Icon} from 'antd';

interface InfoIconProps {
  message: string;
}

export const ExclamationIcon = ({message}: InfoIconProps) => (
  <Tooltip title={message}>
    <Icon style={{color: '#e0a901'}} type="exclamation" />
  </Tooltip>
);
