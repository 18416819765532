import {Select, Button, Icon} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as React from 'react';

const {Option} = Select;

const actions = [
  {
    label: 'Invites (I)',
    value: 'I',
  },
  {
    label: 'Second Followups (S)',
    value: 'S',
  },
  {
    label: 'Followups (F)',
    value: 'F',
  },
  {
    label: 'Third Followups (T)',
    value: 'T',
  },
  {
    label: 'Nurturing Campaign On (ON)',
    value: 'ON',
  },
  {
    label: 'Nurturing Campaign Off (OFF)',
    value: 'OFF',
  },
];

export const ActionsFilter = ({handleChange, filters, clearAndClose}) => {
  return (
    <div className="campaigns-page__filter-wrapper">
      <Icon>
        <FontAwesomeIcon icon={['fas', 'bolt']} />
      </Icon>
      <Select
        mode="multiple"
        maxTagCount={1}
        placeholder="Select action"
        onChange={handleChange}
        value={filters?.actions}
        filterOption={(input, option) =>
          (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        className="campaigns-page__filter-input action"
      >
        {actions.map(campaign => (
          <Option key={campaign.value}>{campaign.label}</Option>
        ))}
      </Select>
      <Button onClick={clearAndClose} icon="close" type="link" className="campaigns-page__close-filter" size="small" />
    </div>
  );
};
