import {BillingService, CONSTANTS} from '../../services';

export const SET_CHARGEBEE_INSTANCE = 'SET_CHARGEBEE_INSTANCE';

export const chargebeeActions = {
  setChargebeeInstance,
  getPortalSession,
};

function setChargebeeInstance() {
  return (dispatch: any) => {
    const instance = window.Chargebee.init({
      site: CONSTANTS.chargebee,
      enableGTMTracking: true,
    });
    dispatch(set(instance));
  };

  function set(instance) {
    return {type: SET_CHARGEBEE_INSTANCE, instance};
  }
}

function getPortalSession() {
  return () => {
    return BillingService.getPortalSession()
      .then(response => {
        return JSON.parse(response.data);
      })
      .catch(_ => null);
  };
}
