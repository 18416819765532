import React from 'react';
import {Card} from 'antd';

export const SidebarCard = ({children, title, bordered = true}) => {
  return (
    <Card
      style={{margin: '8px'}}
      headStyle={{fontSize: '11px'}}
      bodyStyle={{padding: '0'}}
      bordered={false}
      title={title}
    >
      {children}
    </Card>
  );
};
