import {AnalyticsServiceAbstract} from '@growth-x/ui';
import {Logger} from './logger';
const ga = (...args: any) => {};

class AnalyticsService extends AnalyticsServiceAbstract {
  sendEvent = (category: string, event: string, label: string) => {
    Logger.debug('sendEvent (' + category + ' ' + event + ' ' + label + '}');
    ga('send', 'event', [category], [event], [label]);
  };
  sendAppView = (view: any) => {
    Logger.debug('sendView (' + view + ')');
    ga('send', view);
  };
}
const _AnalyticsService = new AnalyticsService();
export {_AnalyticsService as AnalyticsService};
