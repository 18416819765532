import {Button} from 'antd';
import React, {useState} from 'react';

import {brandConfig, isClientTrial} from '@growth-x/ui';

export default function UpgradeButton({client, onUpgrade, openBilling}) {
  if (brandConfig.isLeadoku) {
    if (client.type === 'trial' && !client.subscription_account_id) {
      return <UpgradeButtonLeadoku client={client} />;
    }
    if ((client.type === 'trial' && client.subscription_account_id) || client.type === 'on-hold') {
      return (
        <Button type="primary" size="small" style={{fontSize: '.9em', color: 'white'}} onClick={() => openBilling()}>
          Upgrade
        </Button>
      );
    }
  }
  if (brandConfig.isGx) {
    if (!isClientTrial(client.type)) return null;
    return <UpgradeButtonGX onUpgrade={onUpgrade} />;
  }
  return null;
}

const UpgradeButtonGX = ({onUpgrade}) => {
  return (
    <a
      className="ant-btn ant-btn-primary ant-btn-sm"
      target="_blank"
      rel="noreferrer noopener"
      href="https://growth-x.com/#pricing"
      style={{fontSize: '.9em'}}
      onClick={onUpgrade}
    >
      Upgrade
    </a>
  );
};

const UpgradeButtonLeadoku = ({client}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Button
      id="leadokuCheckout"
      loading={isLoading}
      type="primary"
      size="small"
      style={{fontSize: '.9em', color: 'white'}}
      onClick={() => openLeadokuSubscription(client, setIsLoading)}
    >
      Upgrade
    </Button>
  );
};

const openLeadokuSubscription = (client, setIsLoading) => {
  const cbInstance = (window as any).Chargebee.getInstance();
  cbInstance.setCheckoutCallbacks(() => {
    return {
      success: () => {
        setIsLoading(true);
        (window as any).dataLayer.push({event: 'success_payment'});
      },
    };
  });
  const cart = cbInstance.getCart();
  const product = cbInstance.initializeProduct('leadoku-starter');
  cart.setCustomer({
    first_name: client.name,
    email: encodeURIComponent(client.email),
  });
  cart.replaceProduct(product);
  cart.proceedToCheckout();
};
