export const settingsInitialState: any = {
  isAdmin: false,
  proxyLocations: [],
};

export const clientSettingsInitialState: any = {
  showCampaignColumns: [
    'actions',
    'type',
    'name',
    'warnings',
    'user',
    'potential',
    'to_be_outreached',
    'outreached',
    'invites_to_connections_rate',
    'connections_to_responses_rate',
    'total_positive_responses',
    'total_links_clicked',
    'creation_date',
    'in_sequence',
  ],
  showConnectionsColumns: [
    'first_name',
    'last_name',
    'headline',
    'email',
    'phone',
    'user',
    'tags',
    'response',
    'conversation',
    'positions',
    'companies',
    'imported_date',
  ],
  isNavCollapsed: false,
  openKeys: ['5'],
  campaignFilters: {
    campaigns: [],
    users: [],
    campaign_types: [],
    date: 'last_30_days_vs_prev_30_days',
    date_range_start: '',
    date_range_end: '',
    actions: [],
  },
  prospectsFilter: {
    date: 'last_30_days',
    start: 0,
    order: 'sent_date',
    order_direction: '-',
  },
  connectionsFilter: {
    date: 'last_30_days',
    start: 0,
  },
  showNewClientRunCampaignsTip: true,
  showMobileWarning: true,
};
