import {connect} from 'react-redux';

import {InstantlyFormItem} from '@growth-x/ui';

import {headerModalActions} from '../redux/headerModal';
import {instantlyActions} from '../redux/instantly';

function mapState(state: any, ownProps: any) {
  return {
    isLoading: state.instantly.isLoading,
    isTokenVerified: state.instantly.isTokenVerified,
    campaigns: state.instantly.campaigns,
    error: state.instantly.error,
    errors: ownProps.errors,
    touched: ownProps.touched,
    values: ownProps.values,
    onChange: ownProps.onChange,
  };
}

const actionCreators = {
  getCampaigns: instantlyActions.getCampaigns,
  openSettings: () => headerModalActions.setHeaderModal('instantly'),
};

export const InstantlyFormItemContainer = connect(mapState, actionCreators)(InstantlyFormItem);
