import {Icon, Tooltip, Button} from 'antd';
import React, {useState, memo} from 'react';
import {Link} from 'react-router-dom';

import {STRINGS} from '../../utils';
import {ConnectionConversationCellProps} from './types';

import './ConnectionConversationCell.scss';

export const ConnectionConversationCell = memo(function ConnectionConversationCell({
  receiver,
}: ConnectionConversationCellProps) {
  const [showTooltip, setShowTooltip] = useState(false);
  const {conversation, conversation_id, sn_conversation_id, inbox_responded, user_id} = receiver;
  const conversationLink = `${STRINGS.conversation_url}${conversation_id}`;
  const snConversationLink = `${STRINGS.sn_conversation_url}${sn_conversation_id}`;
  return (
    <>
      {conversation_id && (
        <p>
          <a href={conversationLink} target="_blank" rel="noopener noreferrer">
            Conversation link
          </a>
        </p>
      )}
      {sn_conversation_id && (
        <p>
          <a href={snConversationLink} target="_blank" rel="noopener noreferrer">
            Conversation link (SN)
          </a>
        </p>
      )}
      {conversation && (
        <Tooltip
          visible={showTooltip}
          getPopupContainer={triggerNode => triggerNode}
          placement="left"
          title={
            <span>
              {conversation.split('/n').map((text: string, index: number) => (
                <React.Fragment key={`${text}-${index}`}>
                  {text} <br />
                </React.Fragment>
              ))}
            </span>
          }
        >
          <Button type="link" className="connection-conversation-cell" onClick={() => setShowTooltip(value => !value)}>
            Preview <Icon type="eye" />
          </Button>
        </Tooltip>
      )}
      {conversation && inbox_responded && (
        <div className="connection-conversation-cell" style={{marginTop: '15px'}}>
          <Link to={user_id ? `/inbox/${user_id}?search=${receiver.name}&status=search` : '/'}>
            App inbox <Icon type="eye" />
          </Link>
        </div>
      )}
      {!conversation && <span>-</span>}
    </>
  );
});
