import {getCompaniesReplacer} from './functions';

export const isValidMessageLength = (message, maxLength) => {
  const initialKeys = {keys: ['{receiver.first_name}', '{receiver.last_name}', '{user.first_name}']};
  const replacer = getCompaniesReplacer(message, {});
  [...initialKeys.keys, ...replacer.keys].forEach(key => {
    if (message) {
      message = message.replaceAll(key, '_'.repeat(20));
    }
  });
  const isValid = !message || message.length <= maxLength;
  return {isValid, message};
};
