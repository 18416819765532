export const defaultColumns = [
  'first_name',
  'last_name',
  'headline',
  'email',
  'phone',
  'user',
  'tags',
  'response',
  'conversation',
  'positions',
  'company',
  'imported_date',
];
