import {Spin, Result} from 'antd';
import React, {useMemo, useEffect} from 'react';

import {brandConfig, getUserErrorMessageByCode, replaceKeys} from '../../utils';
import {NoVNCConnection} from '../noVNCConnection/NoVNCConnection';

// statuses:
// created
// in_progress
// ready_for_connection
// connected
// done
// error

const ErrorMessage = function ({validationErrorCode, email}) {
  const templateMsg =
    getUserErrorMessageByCode(validationErrorCode) || 'An error occurred while connecting. Try again later.';
  const errorMsg = replaceKeys(templateMsg, ['{user.email}', '{brandConfig.contact}'], [email, brandConfig.contact]);
  return <span>{errorMsg}</span>;
};

export function OpenFeatureContent({client, userTask, updateUserTask, openUser, user, onToggleAddressBar}) {
  const status = useMemo(() => userTask?.status, [userTask]);
  console.log(userTask);
  const {additional_data} = userTask;
  const {validationErrorCode} = additional_data || {};

  useEffect(() => {
    openUser({user_id: user.id});
  }, []);

  const onConnectionChanged = value => {
    if (['ready_for_connection', 'connected', 'done', 'error'].includes(value)) {
      updateUserTask({
        ...userTask,
        additional_data: {},
        status: value,
      });
    }
  };
  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {['in_progress', 'created'].includes(status) && (
          <Result
            status="info"
            title={
              <div>
                <p>
                  Starting service... <Spin />
                </p>
                Please wait, this could take approximately 1 or 2 minutes.
              </div>
            }
          />
        )}
        {status === 'ready_for_connection' && (
          <Result
            status="info"
            title={
              <span>
                Connecting to server... <Spin />
              </span>
            }
          />
        )}
        {status === 'done' && (
          <div>
            <Result status="success" title="Connection finished successfully" />
          </div>
        )}
        {(!status || status === 'error') && (
          <div>
            <Result
              status="warning"
              title="There are some problems with your operation"
              subTitle={<ErrorMessage validationErrorCode={validationErrorCode} email={user.email} />}
            />
          </div>
        )}
      </div>
      {(status === 'ready_for_connection' || status === 'connected') && (
        <NoVNCConnection
          client={client}
          options={{
            updateCallback: onConnectionChanged,
            onToggleAddressBar: onToggleAddressBar,
            showCustomAddressBar: true,
          }}
        />
      )}
    </>
  );
}
