import {
  GET_INBOX_UNREAD_COUNT_BY_USER_SUCCESS,
  GET_INBOX_CONVERSATION_REQUEST,
  GET_INBOX_CONVERSATION_FAILURE,
  GET_INBOX_CONVERSATION_SUCCESS,
  MARK_CONVERSATION_AS_READ_SUCCESS,
  LOAD_MORE_INBOX_CONVERSATION_SUCCESS,
  LOAD_MORE_INBOX_CONVERSATION_FAILURE,
  UPDATE_INBOX_CONVERSATION_TAGS_REQUEST,
  UPDATE_INBOX_CONVERSATION_TAGS_FAILURE,
  UPDATE_INBOX_CONVERSATION_TAGS_SUCCESS,
  UNMARK_CONVERSATION_AS_YOUR_TURN_SUCCESS,
  UPDATE_INBOX_SUCCESS,
  UPDATE_INBOX_USER_RECEIVER_NOTE_REQUEST,
  UPDATE_INBOX_USER_RECEIVER_NOTE_FAILURE,
  UPDATE_INBOX_USER_RECEIVER_NOTE_SUCCESS,
  UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_REQUEST,
  UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_FAILURE,
  UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_SUCCESS,
  INBOX_CONVERSATION_UPDATED,
  INBOX_CONVERSATION_UNSNOOZED,
  INBOX_REMOVE_CONVERSATION_FROM_LIST,
  ADD_CUSTOM_EMAIL_TO_RECEIVER_REQUEST,
  ADD_CUSTOM_EMAIL_TO_RECEIVER_SUCCESS,
  ADD_CUSTOM_EMAIL_TO_RECEIVER_FAILURE,
  ADD_CUSTOM_PHONE_TO_RECEIVER_REQUEST,
  ADD_CUSTOM_PHONE_TO_RECEIVER_SUCCESS,
  ADD_CUSTOM_PHONE_TO_RECEIVER_FAILURE,
  GET_INBOX_CONVERSATION_COUNT_REQUEST,
  GET_INBOX_CONVERSATION_COUNT_SUCCESS,
  GET_INBOX_CONVERSATION_COUNT_FAILURE,
  GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_REQUEST,
  GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_SUCCESS,
  GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_FAILURE,
  ADD_RECEIVER_TO_MANUAL_SEQUENCE_REQUEST,
  ADD_RECEIVER_TO_MANUAL_SEQUENCE_SUCCESS,
  ADD_RECEIVER_TO_MANUAL_SEQUENCE_FAILURE,
  REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_REQUEST,
  REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_SUCCESS,
  REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_FAILURE,
} from './inboxActions';
import {WS_APP_TASK_UPDATED, WS_INBOX_CONVERSATION_UPDATED} from '../websocket';

const initialState: any = {
  unreadCount: 0,
  unreadCountByUser: [],
  conversations: [],
  userId: null,
  conversationsLoading: false,
  conversationsDetailLoading: false,
  initialConversationsLoading: false,
  conversationsCountLoading: false,
  allConversationsLoaded: false,
  manualSequenceLoading: false,
  manualSequenceCampaign: null,
  tagsUpdating: false,
  inboxUpdating: false,
  noteUpdating: false,
  dealsNoteUpdating: false,
  totalConversationsCount: 0,
  pagination: {
    offset: 0,
  },
};

export const inboxReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_INBOX_UNREAD_COUNT_BY_USER_SUCCESS:
      return {
        ...state,
        unreadCountByUser: action.counts,
      };
    case GET_INBOX_CONVERSATION_REQUEST:
      return {
        ...state,
        userId: action.userId,
        initialConversationsLoading: true,
        allConversationsLoaded: false,
        conversationsLoading: true,
      };
    case GET_INBOX_CONVERSATION_FAILURE:
      return {
        ...state,
        initialConversationsLoading: false,
        conversationsLoading: false,
      };
    case LOAD_MORE_INBOX_CONVERSATION_FAILURE:
      return {
        ...state,
        conversationsLoading: false,
      };
    case GET_INBOX_CONVERSATION_SUCCESS: {
      if (action.userId !== state.userId) {
        return state;
      }
      return {
        ...state,
        conversations: action.conversations,
        conversationsLoading: false,
        initialConversationsLoading: false,
        allConversationsLoaded: action.conversations.length === state.totalConversationsCount,
        pagination: {
          ...state.pagination,
          offset: 0,
        },
      };
    }
    case LOAD_MORE_INBOX_CONVERSATION_SUCCESS: {
      const newConversations = [...state.conversations, ...action.conversations];
      return {
        ...state,
        conversations: newConversations,
        conversationsLoading: false,
        allConversationsLoaded: newConversations.length === state.totalConversationsCount,
        pagination: {
          ...state.pagination,
          offset: state.pagination.offset + 50,
        },
      };
    }

    case MARK_CONVERSATION_AS_READ_SUCCESS:
      return {
        ...state,
        conversations: state.conversations.map((c: any) => {
          return c.id !== action.id ? c : {...c, unread: action.unread};
        }),
      };
    case UNMARK_CONVERSATION_AS_YOUR_TURN_SUCCESS:
      return {
        ...state,
        conversations: state.conversations.map((c: any) => {
          return c.id !== action.id ? c : {...c, your_turn: false};
        }),
      };
    case UPDATE_INBOX_CONVERSATION_TAGS_REQUEST:
      return {
        ...state,
        tagsUpdating: true,
      };
    case UPDATE_INBOX_CONVERSATION_TAGS_FAILURE:
      return {
        ...state,
        tagsUpdating: false,
      };
    case UPDATE_INBOX_CONVERSATION_TAGS_SUCCESS:
      return {
        ...state,
        conversations: state.conversations.map((c: any) => {
          return c.id !== action.conversation.id ? c : action.conversation;
        }),
        tagsUpdating: false,
      };
    case INBOX_CONVERSATION_UPDATED:
      return {
        ...state,
        conversations: state.conversations.map((c: any) => {
          return action.conversation.id === c.id ? action.conversation : c;
        }),
      };
    case INBOX_CONVERSATION_UNSNOOZED: {
      const conversations = state.conversations.map((c: any) => {
        const foundConversation = action.conversations.find(
          (actionConversation: any) => actionConversation.id === c.id
        );
        return foundConversation || c;
      });
      const notFoundConversations = action.conversations.filter((newConversation: any) => {
        return !state.conversations.find((c: any) => {
          return c.id === newConversation.id;
        });
      });
      return {
        ...state,
        conversations: [...notFoundConversations, ...conversations],
      };
    }
    case WS_INBOX_CONVERSATION_UPDATED:
      return {
        ...state,
        conversations: state.conversations.map((c: any) => {
          const foundConversation = action.conversations.find(
            (actionConversation: any) => actionConversation.id === c.id
          );
          return {...c, ...foundConversation};
        }),
      };
    case WS_APP_TASK_UPDATED: {
      const isInboxSyncFinished =
        action.app_task &&
        action.app_task.code === 'update_inbox' &&
        (action.app_task.status === 'done' || action.app_task.status === 'error');
      return {
        ...state,
        inboxUpdating: isInboxSyncFinished ? false : state.inboxUpdating,
      };
    }
    case UPDATE_INBOX_SUCCESS:
      return {
        ...state,
        inboxUpdating: true,
      };
    case UPDATE_INBOX_USER_RECEIVER_NOTE_REQUEST:
      return {
        ...state,
        noteUpdating: true,
      };
    case UPDATE_INBOX_USER_RECEIVER_NOTE_FAILURE:
      return {
        ...state,
        noteUpdating: false,
      };
    case UPDATE_INBOX_USER_RECEIVER_NOTE_SUCCESS:
      return {
        ...state,
        conversations: state.conversations.map((c: any) => {
          return c.receiver.id !== action.receiver_id
            ? c
            : {
                ...c,
                receiver: {
                  ...c.receiver,
                  inbox_note: action.inbox_note,
                },
              };
        }),
        noteUpdating: false,
      };
    case UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_REQUEST:
      return {
        ...state,
        dealsNoteUpdating: true,
      };
    case UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_FAILURE:
      return {
        ...state,
        dealsNoteUpdating: false,
      };
    case UPDATE_INBOX_USER_RECEIVER_DEALS_AMOUNT_SUCCESS:
      return {
        ...state,
        conversations: state.conversations.map((c: any) => {
          if (c.receiver.id !== action.receiver_id) {
            return c;
          }
          return {
            ...c,
            deals_amount: action.deals_amount,
            receiver: {
              ...c.receiver,
            },
          };
        }),
        dealsNoteUpdating: false,
      };
    case INBOX_REMOVE_CONVERSATION_FROM_LIST:
      return {
        ...state,
        conversations: state.conversations.filter((c: any) => c.id !== action.id),
      };
    case ADD_CUSTOM_EMAIL_TO_RECEIVER_REQUEST:
      return {
        ...state,
        conversationsDetailLoading: true,
      };
    case ADD_CUSTOM_EMAIL_TO_RECEIVER_SUCCESS:
      return {
        ...state,
        conversationsDetailLoading: false,
        conversations: state.conversations.map((c: any) => {
          if (c.id !== action.data.id) {
            return c;
          }
          return {
            ...c,
            receiver: {...c.receiver, custom_email: action.data.custom_email},
          };
        }),
      };
    case ADD_CUSTOM_EMAIL_TO_RECEIVER_FAILURE:
      return {
        ...state,
        conversationsDetailLoading: false,
      };
    case ADD_CUSTOM_PHONE_TO_RECEIVER_REQUEST:
      return {
        ...state,
        conversationsDetailLoading: true,
      };
    case ADD_CUSTOM_PHONE_TO_RECEIVER_SUCCESS:
      return {
        ...state,
        conversationsDetailLoading: false,
        conversations: state.conversations.map((c: any) => {
          if (c.id !== action.data.id) {
            return c;
          }
          return {
            ...c,
            receiver: {...c.receiver, custom_phone_number: action.data.custom_phone_number},
          };
        }),
      };
    case ADD_CUSTOM_PHONE_TO_RECEIVER_FAILURE:
      return {
        ...state,
        conversationsDetailLoading: false,
      };
    case GET_INBOX_CONVERSATION_COUNT_REQUEST:
      return {
        ...state,
        conversationsCountLoading: true,
      };
    case GET_INBOX_CONVERSATION_COUNT_SUCCESS:
      return {
        ...state,
        conversationsCountLoading: false,
        totalConversationsCount: action.count,
      };
    case GET_INBOX_CONVERSATION_COUNT_FAILURE:
      return {
        ...state,
        conversationsCountLoading: false,
      };
    case GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_REQUEST:
      return {
        ...state,
        manualSequenceLoading: true,
      };
    case GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_SUCCESS:
      return {
        ...state,
        manualSequenceLoading: false,
        manualSequenceCampaign: action.data,
      };
    case GET_CAMPAIGN_BY_RECEIVER_FROM_MANUAL_SEQUENCE_FAILURE:
      return {
        ...state,
        manualSequenceLoading: false,
      };
    case ADD_RECEIVER_TO_MANUAL_SEQUENCE_REQUEST:
      return {
        ...state,
        manualSequenceLoading: true,
      };
    case ADD_RECEIVER_TO_MANUAL_SEQUENCE_SUCCESS:
      return {
        ...state,
        manualSequenceLoading: false,
      };
    case ADD_RECEIVER_TO_MANUAL_SEQUENCE_FAILURE:
      return {
        ...state,
        manualSequenceLoading: false,
      };
    case REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_REQUEST:
      return {
        ...state,
        manualSequenceLoading: true,
      };
    case REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_SUCCESS:
      return {
        ...state,
        manualSequenceLoading: false,
        manualSequenceCampaign: null,
      };
    case REMOVE_RECEIVER_FROM_MANUAL_SEQUENCE_FAILURE:
      return {
        ...state,
        manualSequenceLoading: false,
      };
    default:
      return state;
  }
};
