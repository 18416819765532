import {InstantlyCampaign} from '@growth-x/types';

import {
  GET_INSTANTLY_CAMPAIGNS_FAILURE,
  GET_INSTANTLY_CAMPAIGNS_REQUEST,
  GET_INSTANTLY_CAMPAIGNS_SUCCESS,
  VERIFY_INSTANTLY_TOKEN_FAILURE,
  VERIFY_INSTANTLY_TOKEN_REQUEST,
  VERIFY_INSTANTLY_TOKEN_SUCCESS,
  CONNECT_INSTANTLY_REQUEST,
  CONNECT_INSTANTLY_SUCCESS,
  CONNECT_INSTANTLY_FAILURE,
  DISCONNECT_INSTANTLY_REQUEST,
  DISCONNECT_INSTANTLY_SUCCESS,
  DISCONNECT_INSTANTLY_FAILURE,
} from './instantlyActions';

interface InstantlyState {
  isLoading: boolean;
  isActionLoading: boolean;
  isTokenVerified: boolean;
  error: string | undefined,
  campaigns: InstantlyCampaign[];
}

const initialState: InstantlyState = {
  isLoading: false,
  isActionLoading: false,
  isTokenVerified: false,
  error: undefined,
  campaigns: [],
};

export const instantlyReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case VERIFY_INSTANTLY_TOKEN_REQUEST:
    case GET_INSTANTLY_CAMPAIGNS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CONNECT_INSTANTLY_REQUEST:
    case DISCONNECT_INSTANTLY_REQUEST:
      return {
        ...state,
        isActionLoading: true,
        error: undefined,
      };
    case VERIFY_INSTANTLY_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isTokenVerified: true,
      };
    case GET_INSTANTLY_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isTokenVerified: true,
        campaigns: action.campaigns,
      };
    case DISCONNECT_INSTANTLY_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
        isTokenVerified: false,
      };
    case CONNECT_INSTANTLY_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
        isTokenVerified: true,
      };
    case VERIFY_INSTANTLY_TOKEN_FAILURE:
    case GET_INSTANTLY_CAMPAIGNS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isTokenVerified: false,
        campaigns: [],
      };
    case CONNECT_INSTANTLY_FAILURE:
    case DISCONNECT_INSTANTLY_FAILURE:
      return {
        ...state,
        isActionLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
