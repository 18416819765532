import {
  GET_CONNECTIONS_REQUEST,
  GET_CONNECTIONS_SUCCESS,
  GET_CONNECTIONS_FAILURE,
  LOAD_MORE_CONNECTIONS_REQUEST,
  LOAD_MORE_CONNECTIONS_SUCCESS,
} from './connectionsActions';

import {UPDATE_CLIENT_TAGS, LOGOUT} from '../client';

const initialState: any = {
  list: [],
  tags: [],
  total: 0,
  connectionsLoading: false,
  connectionsError: '',
};

export const connectionsReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CONNECTIONS_REQUEST:
      return {
        ...state,
        connectionsLoading: true,
      };

    case GET_CONNECTIONS_SUCCESS:
      return {
        ...state,
        list: action.connections,
        total: action.total,
        connectionsLoading: false,
      };

    case GET_CONNECTIONS_FAILURE:
      return {
        ...state,
        connectionsLoading: false,
        connectionsError: action.error,
      };

    case LOAD_MORE_CONNECTIONS_REQUEST:
      return {
        ...state,
        connectionsLoading: true,
      };

    case LOAD_MORE_CONNECTIONS_SUCCESS:
      return {
        ...state,
        list: [...state.list, ...action.connections],
        total: action.total,
        connectionsLoading: false,
      };
    case UPDATE_CLIENT_TAGS: {
      return {...state, tags: action.tags};
    }
    case LOGOUT:
      return {...initialState};

    default:
      return state;
  }
};
