import {Layout} from 'antd';
import React from 'react';
import {connect} from 'react-redux';

import {MobileLogo, MobileAccountSwitcher} from '@growth-x/ui';

import {TemplatesModalContainer} from '../../containers';
import {getSettings} from '../../pages/campaigns/utils';
import {clientActions} from '../../redux/client';
import {settingsActions} from '../../redux/settings';
import {MobileWarning} from './MobileWarning';

import './header.scss';

const MobileHeader = ({client, isAdmin, team_member, logout, settings, setSettings}: any) => {
  const onClick = ({key}) => {
    if (key === 'logout') {
      logout();
    }
  };

  return (
    <Layout.Header
      style={{
        background: 'var(--color-white)',
        padding: '0 5px',
        lineHeight: 'unset',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #ddd',
      }}
    >
      <MobileLogo />
      <MobileAccountSwitcher
        client={client}
        team_member={team_member}
        isNavCollapsed={settings.isNavCollapsed}
        setSettings={setSettings}
        onClick={onClick}
        isAdmin={isAdmin}
      />
      {settings.showMobileWarning && <MobileWarning onClose={() => setSettings({showMobileWarning: false})} />}
      <TemplatesModalContainer />
    </Layout.Header>
  );
};

const mapStateToProps = (state: any) => ({
  client: state.client,
  team_member: state.client.team_member,
  settings: getSettings(state),
  isAdmin: state.settings.isAdmin,
});

const actionCreators = {
  logout: clientActions.logout,
  setSettings: settingsActions.setSettings,
};

const ConnectedHeader = connect(mapStateToProps, actionCreators)(MobileHeader);

export {ConnectedHeader as MobileHeader};
