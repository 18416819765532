import {Menu, Icon, Tooltip, Popconfirm} from 'antd';
import * as React from 'react';

import {STRINGS} from '../../utils';

const Checked = ({isChecked}: any) => isChecked && <Icon className="campaigns-page__action-checkmark" type="check" />;
const StyledAction = ({children}: any) => (
  <span style={{color: 'var(--color-action-menu-item)', fontWeight: 'bold'}}>{children}</span>
);

const retentionMenu = (record: any) => {
  return [
    <Menu.Item key="messages_active">
      <StyledAction>N</StyledAction> Nurturing <Checked isChecked={record.actions.includes('N')} />
    </Menu.Item>,
    <Menu.Item key="followups_active" disabled={!record.followup_message}>
      {getFollowup(record)}
    </Menu.Item>,
    <Menu.Item key="second_followups_active" disabled={!record.second_followup_message}>
      {getSecondFollowup(record)}
    </Menu.Item>,
    <Menu.Item key="third_followups_active" disabled={!record.third_followup_message}>
      {getThirdFollowup(record)}
    </Menu.Item>,
  ];
};

const getFollowupWithTooltip = withoutTooltip => {
  return <Tooltip title={STRINGS.tooltip.followups_disabled}>{withoutTooltip}</Tooltip>;
};

const getFollowup = record => {
  const withoutTooltip = (
    <div>
      <StyledAction>F</StyledAction> First Follow-up <Checked isChecked={record.actions.includes('F')} />
    </div>
  );

  if (!record.followup_message) {
    return getFollowupWithTooltip(withoutTooltip);
  }
  return withoutTooltip;
};

const getSecondFollowup = record => {
  const withoutTooltip = (
    <div>
      <StyledAction>S</StyledAction> Second Follow-up <Checked isChecked={record.actions.includes('S')} />
    </div>
  );

  if (!record.second_followup_message) {
    return getFollowupWithTooltip(withoutTooltip);
  }
  return withoutTooltip;
};

const getThirdFollowup = record => {
  const withoutTooltip = (
    <div>
      <StyledAction>T</StyledAction> Third Follow-up <Checked isChecked={record.actions.includes('T')} />
    </div>
  );

  if (!record.third_followup_message) {
    return getFollowupWithTooltip(withoutTooltip);
  }
  return withoutTooltip;
};

const acquisitionMenu = (record: any) => {
  return [
    <Menu.Item key="messages_active">
      <StyledAction>I</StyledAction> Invite <Checked isChecked={record.actions.includes('I')} />
    </Menu.Item>,
    <Menu.Item key="followups_active" disabled={!record.followup_message}>
      {getFollowup(record)}
    </Menu.Item>,
    <Menu.Item key="second_followups_active" disabled={!record.second_followup_message}>
      {getSecondFollowup(record)}
    </Menu.Item>,
    <Menu.Item key="third_followups_active" disabled={!record.third_followup_message}>
      {getThirdFollowup(record)}
    </Menu.Item>,
  ];
};

export const CampaignActionsMenu = ({record, onActionsChange, isLastActiveCampaign}: any) => {
  const isLastCampaign = isLastActiveCampaign(record.id);
  const [visible, setVisible] = React.useState(false);
  const [menuClickInfo, setMenuClickInfo] = React.useState(null);

  const handleMenuClick = (record: any, e: any) => {
    if (record.actions.length === 1 && isLastCampaign && record[e?.key]) {
      setVisible(true);
      setMenuClickInfo({record, e});
    } else {
      onActionsChange(record, e);
    }
  };

  const handleConfirm = () => {
    onActionsChange(menuClickInfo.record, menuClickInfo.e);
    setMenuClickInfo(null);
    setVisible(false);
  };

  const PopConfirmTitle = () => (
    <div style={{width: 300}}>
      <p>
        Please note that turning off all activity on your campaigns will stop Growth-X from syncing with your Linkedin
        inbox.
      </p>
      <p>Confirm if you’d like to continue ?</p>
    </div>
  );

  return (
    <Popconfirm
      title={<PopConfirmTitle />}
      visible={visible}
      onConfirm={() => {
        handleConfirm();
        setMenuClickInfo(null);
      }}
      onCancel={() => setVisible(false)}
      okText="Yes"
      cancelText="No"
    >
      <Menu className="campaign-actions-menu" onClick={e => handleMenuClick(record, e)}>
        {record.type.includes('retention') ? retentionMenu(record) : acquisitionMenu(record)}
      </Menu>
    </Popconfirm>
  );
};
