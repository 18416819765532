import {message} from 'antd';
import {debounce} from 'lodash';

import {IntercomService, ReceiversService} from '../../services';

import {UPDATE_DATA_CREDITS} from '../client';
import {SET_SETTINGS} from '../settings';

export const GET_PROSPECTS_REQUEST = 'GET_PROSPECTS_REQUEST';
export const GET_PROSPECTS_SUCCESS = 'GET_PROSPECTS_SUCCESS';
export const GET_PROSPECTS_FAILURE = 'GET_PROSPECTS_FAILURE';
export const LOAD_MORE_PROSPECTS_REQUEST = 'LOAD_MORE_PROSPECTS_REQUEST';
export const LOAD_MORE_PROSPECTS_SUCCESS = 'LOAD_MORE_PROSPECTS_SUCCESS';
export const LOAD_MORE_PROSPECTS_FAILURE = 'LOAD_MORE_PROSPECTS_FAILURE';
export const GET_PROSPECTS_TOTAL_REQUEST = 'GET_TOTAL_PROSPECTS_REQUEST';
export const GET_PROSPECTS_TOTAL_SUCCESS = 'GET_TOTAL_PROSPECTS_SUCCESS';
export const GET_PROSPECTS_TOTAL_FAILURE = 'GET_TOTAL_PROSPECTS_FAILURE';

export const prospectActions = {
  getProspects,
  loadMoreProspects,
  archiveReceivers,
  enhanceReceivers,
  exportAllProspects,
  filterProspects,
};

function getProspects(prospectsFilter: any) {
  return (dispatch: any, getState: any) => {
    dispatch(request());
    const clientId = getState().client.data.id;
    const settings = getState().settings;
    settings[clientId] = {...settings[clientId], prospectsFilter};
    dispatch({type: SET_SETTINGS, settings});
    ReceiversService.getProspects(prospectsFilter).then(
      ({data}: any) => {
        dispatch(success(data.receivers));
        dispatch(getProspectsTotal(prospectsFilter));
      },
      (error: string) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {type: GET_PROSPECTS_REQUEST};
  }
  function success(prospects: any) {
    return {type: GET_PROSPECTS_SUCCESS, prospects};
  }
  function failure(status: string) {
    return {type: GET_PROSPECTS_FAILURE, status};
  }
}

function getProspectsTotal(filters: any) {
  return (dispatch: any) => {
    dispatch(request());

    ReceiversService.getProspects({...filters, only_total: true}).then(
      ({data}: any) => {
        dispatch(success(data.receivers, data.total));
      },
      (error: string) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {type: GET_PROSPECTS_TOTAL_REQUEST};
  }
  function success(prospects: any, total: number) {
    return {type: GET_PROSPECTS_TOTAL_SUCCESS, prospects, total};
  }
  function failure(status: string) {
    return {type: GET_PROSPECTS_TOTAL_FAILURE, status};
  }
}

const debouncedGetData = debounce((dispatch, filters) => {
  ReceiversService.getProspects(filters).then(
    ({data}: any) => {
      dispatch(success(data.receivers, data.total));
      dispatch(getProspectsTotal(filters));
    },
    (error: string) => {
      dispatch(failure(error));
    }
  );
  function success(prospects: any, total: number) {
    return {type: GET_PROSPECTS_SUCCESS, prospects, total};
  }
  function failure(status: string) {
    return {type: GET_PROSPECTS_FAILURE, status};
  }
}, 1000);

function filterProspects(prospectsFilter: any) {
  return (dispatch: any, getState: any) => {
    dispatch(request());
    const clientId = getState().client.data.id;
    const settings = getState().settings;
    settings[clientId] = {...settings[clientId], prospectsFilter};
    dispatch({type: SET_SETTINGS, settings});
    debouncedGetData(dispatch, prospectsFilter);
  };

  function request() {
    return {type: GET_PROSPECTS_REQUEST};
  }
}

function loadMoreProspects(prospectsFilter: any) {
  return (dispatch: any, getState: any) => {
    dispatch(request());
    const clientId = getState().client.data.id;
    const settings = getState().settings;
    settings[clientId] = {...settings[clientId], prospectsFilter};
    dispatch({type: SET_SETTINGS, settings});
    ReceiversService.getProspects(prospectsFilter).then(
      ({data}: any) => {
        dispatch(success(data.receivers, data.total));
        dispatch(getProspectsTotal(prospectsFilter));
      },
      (error: string) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {type: LOAD_MORE_PROSPECTS_REQUEST};
  }
  function success(prospects: any, total: number) {
    return {type: LOAD_MORE_PROSPECTS_SUCCESS, prospects, total};
  }
  function failure(status: string) {
    return {type: GET_PROSPECTS_FAILURE, status};
  }
}

function archiveReceivers(receiverIds: number[]) {
  return (dispatch: any, getState: any) => {
    const clientId = getState().client.data.id;
    const prospectsFilters = getState().settings[clientId].prospectsFilter;
    ReceiversService.archiveReceivers(receiverIds).then(() => {
      dispatch(getProspects(prospectsFilters));
    });
  };
}

function enhanceReceivers(receiverIds: number[]) {
  return (dispatch: any, getState: any) => {
    const clientId = getState().client.data.id;
    const prospectsFilters = getState().settings[clientId].prospectsFilter;
    ReceiversService.enhanceReceivers(receiverIds).then(({data}: any) => {
      message.info(
        `${data.total_matches} matches found of ${data.total_receivers} (${data.total_matches} credits used)`
      );
      IntercomService.trackIntercomEvent('data_credits', data.credits_left, true);
      dispatch({type: UPDATE_DATA_CREDITS, ...data});
      dispatch(getProspects(prospectsFilters));
    });
  };
}

function exportAllProspects(filters) {
  return () => {
    ReceiversService.exportAllProspects(filters).then((url: string) => {
      window.open(url, '_self');
    });
  };
}
