import {Col, Row} from 'antd';
import React, {memo} from 'react';

import {formatNumbers} from '@growth-x/ui';

interface OutreachedTooltipProps {
  invite: number;
  nurtured: number;
  inmail: number;
  message_request: number;
}

export const OutreachedTooltip = memo(function OutreachedTooltip({
  invite,
  nurtured,
  inmail,
  message_request,
}: OutreachedTooltipProps) {
  const statsTooltip = [
    {
      value: invite || 0,
      label: 'Invited',
    },
    {
      value: nurtured || 0,
      label: 'Nurtured',
    },
    {
      value: inmail || 0,
      label: 'InMail',
    },
    // {
    //   value: message_request || 0,
    //   label: 'Message Requests',
    // },
  ];
  return (
    <Row>
      {statsTooltip
        .filter(item => item.value)
        .map(item => (
          <Col key={item.label}>
            <span>{`${formatNumbers(item.value)} ${item.label}`}</span>
          </Col>
        ))}
    </Row>
  );
});
