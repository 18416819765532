export class InboxService {
  client: any;
  constructor({client}: any) {
    this.client = client;
  }
  serviceName = 'inbox';
  getInboxUnreadCountByUser() {
    return this.client.get(`${this.serviceName}/unread_count_by_user`, {});
  }
  getInboxConversations(params: any) {
    return this.client.get(`${this.serviceName}/conversations/preview`, params);
  }
  getInboxConversationDetails(params: any) {
    return this.client.get(`${this.serviceName}/conversations/details`, params);
  }
  getInboxConversationsCount(params: any) {
    return this.client.get(`${this.serviceName}/conversations/preview`, params);
  }
  markConversationAsRead(params: any) {
    return this.client.put(`${this.serviceName}/mark_read`, params);
  }
  unmarkConversationYourTurn(params: any) {
    return this.client.put(`${this.serviceName}/your_turn`, params);
  }
  snoozeConversation(id: number, until: string) {
    return this.client.put(`${this.serviceName}/conversations/${id}/snooze`, {inbox_snoozed_until: until});
  }
  archiveConversation(id: number, flag: boolean) {
    return this.client.put(`${this.serviceName}/conversations/${id}/archive`, {inbox_archived: flag});
  }
  setInSequence(id: number, state: string) {
    return this.client.put(`${this.serviceName}/conversations/${id}/update_sequence_state`, {state});
  }
  suggestTemplate(conversationId: number) {
    return this.client.get(`api/v2/suggest-template`, {inbox_id: conversationId});
  }
  saveSuggestionFeedback(conversationId: number, score: number, commentary: string) {
    return this.client.post(`api/v2/accuracy-statistics`, {inbox: conversationId, score, commentary});
  }
}
