import {Button, Checkbox, Dropdown, Menu, Tooltip} from 'antd';
import React from 'react';
import {TagSelector, CONSTANTS} from '@growth-x/ui';

export function SelectTableColumns({onChange, columns, clientTags}) {
  return (
    <Tooltip title="Columns">
      <Dropdown
        overlay={
          <Menu>
            <div>
              <Checkbox.Group
                className="campaigns-page__columns-checkboxes"
                onChange={(newColumns: Array<string>) => onChange(newColumns, 'normal')}
                defaultValue={columns}
                options={CONSTANTS.campaignColumns}
              />
              <TagSelector showColumns={columns} handleChangeColumns={onChange} clientTags={clientTags} />
            </div>
          </Menu>
        }
        trigger={['click']}
      >
        <Button className="campaigns-page__columns-button" shape="circle" icon="layout" />
      </Dropdown>
    </Tooltip>
  )
}
