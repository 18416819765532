import {Layout, Button} from 'antd';
import React, {useState} from 'react';

import './sidebar.scss';

const {Sider} = Layout;

export const Sidebar = props => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <Sider
      collapsible={!!props.collapsible}
      onCollapse={collapsed => setIsCollapsed(collapsed)}
      width={330}
      style={{height: '100%'}}
      className="inbox-sider"
      collapsedWidth={18}
      theme="light"
      reverseArrow={true}
      trigger={<Button shape="circle" icon={isCollapsed ? 'left' : 'right'} />}
    >
      {props.children}
    </Sider>
  );
};
