import {Button, Spin} from 'antd';
import React from 'react';

import TokenError from './components/tokenError/tokenError';

export function CheckPromo({error, isValid, isLoading, promoRedirectUrl}) {
  if (isLoading) {
    return <Spin />;
  }

  if (isValid && promoRedirectUrl) {
    window.location.replace(promoRedirectUrl);
    return <Spin />;
  }

  return (
    <>
      {<TokenError error={error} />}
      {<Button href="/">Go to main page</Button>}
    </>
  );
}
