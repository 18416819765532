export class ConversationNoteService {
  client: any;
  constructor({client}: any) {
    this.client = client;
  }
  serviceName = 'api/v2/note';
  createNote(params: any) {
    return this.client.post(`${this.serviceName}`, params);
  }
  updateNote(noteId: string, params: any) {
    return this.client.put(`${this.serviceName}/${noteId}`, params);
  }
  deleteNote(noteId: string) {
    return this.client.delete(`${this.serviceName}/${noteId}`);
  }
}
