import {Modal, Form, Input, Button, Row} from 'antd';
import {Formik} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import {Client, Template} from '@growth-x/types';
import {CONSTANTS} from '@growth-x/ui';

import {MessageTextArea} from '../..';
import {SwitchRow} from '../../campaignDetails/common';

interface TemplatesModalProps {
  client: Client;
  visible: boolean;
  loading: boolean;
  onToggleModal: () => void;
  template: any;
  templates: any;
  onSave: (template: Template) => void;
}

export class TemplatesModal extends React.Component<TemplatesModalProps, any> {
  handleSave = async (template: Template, formik: any) => {
    await this.props.onSave({...this.props.template, ...template});
    this.props.onToggleModal();
    formik.resetForm();
  };

  onCloseModal = () => {
    this.props.onToggleModal();
  };

  render() {
    const {template, client} = this.props;
    const isReadOnly = template?.client && template?.client !== client.id;
    return (
      <Modal
        visible={this.props.visible || this.props.loading}
        onCancel={this.props.onToggleModal}
        footer={null}
        maskClosable={false}
        title={`${template.id ? 'Edit' : 'New'} Template`}
        className="template-modal"
      >
        <Formik
          initialValues={{
            title: template.title,
            text: template.text,
            shared: template.shared,
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            title: Yup.string().required('Required').max(60, `Max Length is 60 characters`),
            text: Yup.string().required('Required'),
          })}
          destroyOnUnmount={true}
          onSubmit={this.handleSave}
        >
          {({values, touched, errors, handleSubmit, setFieldValue, resetForm}) => (
            <Form onSubmit={handleSubmit} layout="vertical">
              <Form.Item
                label="Template name:"
                validateStatus={errors.title && touched.title ? 'error' : ''}
                hasFeedback
                help={errors.title && touched.title ? errors.title : ''}
              >
                <Input
                  name="title"
                  value={values.title}
                  placeholder="Type the name of your template"
                  onChange={ev => setFieldValue('title', ev.target.value)}
                  disabled={isReadOnly}
                />
              </Form.Item>
              <MessageTextArea
                label={<span>Template:</span>}
                placeholder="Type the template"
                name="text"
                setValue={value => setFieldValue('text', value)}
                value={values.text}
                touched={touched.text}
                errors={errors.text}
                insertTexts={CONSTANTS.message_variable_list}
                showAttachment={false}
                className="template-text"
                templates={this.props.templates}
                disabled={isReadOnly}
              />
              {client.group && (
                <SwitchRow
                  label={'Share this template with your Group:'}
                  onChange={(checked: boolean) => setFieldValue('shared', checked ? 1 : 0)}
                  checked={!!values.shared}
                  isLock={isReadOnly}
                />
              )}
              <Row style={{justifyContent: 'flex-end'}} type="flex">
                <Button
                  onClick={() => {
                    this.onCloseModal();
                    resetForm();
                  }}
                  style={{marginRight: 10}}
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={this.props.loading} disabled={isReadOnly}>
                  Save
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}
