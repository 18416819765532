import {SET_MAINTENANCE, UNSET_MAINTENANCE} from './maintenanceActions';

const initialState: any = {
  isMaintenance: false,
};

export const maintenanceReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_MAINTENANCE: {
      return {...state, isMaintenance: true};
    }
    case UNSET_MAINTENANCE: {
      return {...state, isMaintenance: false};
    }
    default:
      return state;
  }
};
