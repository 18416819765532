export class SfOAuth2Service {
  client: any;
  constructor({client}: any) {
    this.client = client;
  }
  serviceName = 'salesforce';

  getAuthorizedCredentials() {
    return this.client.get(`${this.serviceName}/get-details`, {});
  }
  setAuthorizationCode(params) {
    return this.client.post(`${this.serviceName}/authorize`, params);
  }
  clearAuthorizedCredentials() {
    return this.client.delete(`${this.serviceName}/delete-details`, {});
  }
}
