import {LOGOUT} from '../client';
import {
  GET_MANUAL_TASKS_REQUEST,
  GET_MANUAL_TASKS_SUCCESS,
  GET_MANUAL_TASKS_FAILED,
  LOAD_MORE_MANUAL_TASKS_REQUEST,
  LOAD_MORE_MANUAL_TASKS_SUCCESS,
  LOAD_MORE_MANUAL_TASKS_FAILED,
  POST_MANUAL_TASK_REQUEST,
  POST_MANUAL_TASK_SUCCESS,
  POST_MANUAL_TASK_FAILED,
} from './manualTasksActions';

const initialState: any = {
  isLoading: false,
  isRequestLoading: false,
  count: 0,
  next: null,
  tasks: [],
};

export const manualTasksReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_MANUAL_TASKS_REQUEST:
    case LOAD_MORE_MANUAL_TASKS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_MANUAL_TASKS_SUCCESS: {
      const {count, next, results} = action.data;
      return {
        ...state,
        isLoading: false,
        tasks: results,
        count,
        next,
      };
    }
    case LOAD_MORE_MANUAL_TASKS_SUCCESS: {
      const {count, next, results} = action.data;
      return {
        ...state,
        isLoading: false,
        tasks: [...state.tasks, ...results],
        count,
        next,
      };
    }
    case GET_MANUAL_TASKS_FAILED: {
      return {
        ...state,
        isLoading: false,
        count: 0,
        next: null,
        tasks: [],
      };
    }
    case LOAD_MORE_MANUAL_TASKS_FAILED: {
      return {
        ...state,
        isLoading: false,
        next: null,
      };
    }
    case POST_MANUAL_TASK_REQUEST: {
      return {
        ...state,
        isRequestLoading: true,
      };
    }
    case POST_MANUAL_TASK_FAILED:
    case POST_MANUAL_TASK_SUCCESS: {
      return {
        ...state,
        isRequestLoading: false,
      };
    }
    case LOGOUT:
      return {...initialState};
    default:
      return state;
  }
};
