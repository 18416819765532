export class ManualSequenceService {
  client: any;
  constructor({client}: any) {
    this.client = client;
  }
  serviceName = 'api/v2/manual-sequence';

  addReceiver(params) {
    return this.client.post(`${this.serviceName}/add-receiver-to-sequence`, params);
  }
  removeReceiver(params) {
    return this.client.post(`${this.serviceName}/remove-receiver-from-sequence`, params);
  }
  getCampaignByReceiver(params) {
    return this.client.get(this.serviceName, {...params, limit: 20});
  }
}
