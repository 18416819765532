import {Icon} from 'antd';
import React, {useState, memo} from 'react';
import './urlFilters.scss';

function titleToLabel(title = '') {
  const a = title.toLowerCase().replace(/[-_\s.]+(.)?/g, (_, c) => (c ? ' ' + c : ''));
  return a.substring(0, 1).toUpperCase() + a.substring(1);
}

function getFilterTitle(filterType) {
  switch (filterType) {
    case 'INDUSTRY':
      return 'Industry';
    case 'COMPANY_HEADCOUNT':
      return 'Company headcount';
    case 'RELATIONSHIP':
      return 'Connection';
    case 'REGION':
      return 'Geography';
    case 'CURRENT_TITLE':
      return 'Current job title';
    case 'CONNECTION':
      return 'Connection';
    default:
      return titleToLabel(filterType);
  }
}

export const UrlFilters = memo(function UrlFilters({data, loading, filtersSavedSearch}: any) {
  const [expanded, setExpanded] = useState(false);
  if ((!data || !data.filters || !data.filters.length) && (!filtersSavedSearch || !filtersSavedSearch.filters)) {
    return null;
  }
  const list = data?.filters || filtersSavedSearch?.filters;
  const keywords = data?.keywords || filtersSavedSearch?.keywords;
  const decodeText = text => {
    try {
      return decodeURIComponent(text);
    } catch (error) {
      console.log('error', error);
      return text;
    }
  };
  return (
    <div
      className={`url-filter ${expanded ? 'expanded' : ''}`}
      style={{marginBottom: 30, backgroundColor: 'white'}}
      onClick={() => (!expanded ? setExpanded(!expanded) : null)}
    >
      <div>
        <div className="url-filter__header" onClick={() => setExpanded(!expanded)}>
          {'Filters'}
          {loading && <Icon type="loading" />}
        </div>
        <div>
          {list.map(filter => {
            return (
              <div className="url-filter__item">
                <div className="url-filter__item-title">{getFilterTitle(filter.type)}:</div>
                <div>
                  {filter.values.map(v => (
                    <span className={`url-filter__item-value ${v.selectionType}`}>
                      {decodeText(v.text || v.displayValue)}
                    </span>
                  ))}
                </div>
              </div>
            );
          })}
          {keywords && (
            <div className="url-filter__item">
              <div className="url-filter__item-title">{getFilterTitle('Keywords')}:</div>
              <div>
                <span className={`url-filter__item-value INCLUDED`}>{decodeText(keywords)}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="url-filter__transparent-gradient" />
    </div>
  );
});
