import {LOGOUT} from '../client';
import {SET_HEADER_MODAL} from './headerModalActions';

const defaultState = {
  headerModal: undefined,
};

export const headerModalReducers = (state = defaultState, action: any) => {
  switch (action.type) {
    case SET_HEADER_MODAL: {
      return {...state, headerModal: action.headerModal};
    }
    case LOGOUT: {
      return defaultState;
    }
    default:
      return state;
  }
};
