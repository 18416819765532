import {Button, Form, Select, Tooltip} from 'antd';
import React, {useEffect} from 'react';

import {Campaign, User} from '@growth-x/types';

import {getEllipsedText, isCampaignRetention} from '../../utils';

interface SelectCampaignProps {
  user: User;
  campaigns: Campaign[];
  conversation: any;
  addReceiverToManualSequence: any;
  removeReceiverFromManualSequence: any;
  getCampaignByReceiverFromManualSequence: any;
  manualSequenceCampaign: any;
  manualSequenceLoading: boolean;
}

const {Option} = Select;

export const SelectCampaign = ({
  user,
  campaigns,
  conversation,
  addReceiverToManualSequence,
  removeReceiverFromManualSequence,
  getCampaignByReceiverFromManualSequence,
  manualSequenceLoading,
  manualSequenceCampaign,
}: SelectCampaignProps) => {
  if (!user && !conversation) return null;
  const [campaignId, setCampaignId] = React.useState(undefined);

  useEffect(() => {
    if (user && conversation) {
      getCampaignByReceiverFromManualSequence(conversation.receiver.id, user.id);
    }
  }, [conversation?.receiver?.id, user?.id, getCampaignByReceiverFromManualSequence]);

  useEffect(() => {
    setCampaignId(manualSequenceCampaign?.id);
  }, [manualSequenceCampaign]);

  const onSave = () => {
    addReceiverToManualSequence(campaignId, conversation.receiver.id, user.id);
  };

  const onRemove = () => {
    removeReceiverFromManualSequence(campaignId, conversation.receiver.id, user.id);
  };

  const retentions =
    campaigns.filter(
      (campaign: Campaign) =>
        isCampaignRetention(campaign) && campaign.selectedUser && campaign.selectedUser.id === user.id
    ) || [];
  const isSaved = manualSequenceCampaign && manualSequenceCampaign.id;
  const isInvalidConversation = conversation && conversation.ur_invalid === 6;

  // should be removed after refactoring
  if (!user) {
    return null;
  }

  return (
    !isInvalidConversation && (
      <Form.Item>
        <Select
          loading={manualSequenceLoading}
          onChange={(campaignId: number) => {
            setCampaignId(campaignId);
          }}
          disabled={Boolean(!!isSaved || user.archived)}
          style={{width: '73%', margin: 5}}
          placeholder="Add to nurturing campaign"
          value={campaignId}
        >
          {retentions.map(r => {
            const hasEllipsis = r.name.length > 25;
            return (
              <Option key={r.id} value={r.id}>
                <Tooltip title={r.name}>{hasEllipsis ? getEllipsedText(r.name, 25) : r.name}</Tooltip>
              </Option>
            );
          })}
        </Select>
        {isSaved ? (
          <Button disabled={Boolean(user.archived)} type="primary" onClick={onRemove}>
            Clear
          </Button>
        ) : (
          <Button disabled={Boolean(user.archived)} type="primary" onClick={onSave}>
            Save
          </Button>
        )}
      </Form.Item>
    )
  );
};
