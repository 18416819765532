import {
  PUSH_NOTIFICATIONS,
  TOGGLE_NOTIFICATIONS,
  GET_NOTIFICATIONS_LIST_REQUEST,
  GET_NOTIFICATIONS_LIST_SUCCESS,
  GET_NOTIFICATIONS_LIST_FAILURE,
  LOAD_MORE_NOTIFICATIONS_REQUEST,
  LOAD_MORE_NOTIFICATIONS_SUCCESS,
  LOAD_MORE_NOTIFICATIONS_FAILURE,
  READ_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_SETTINGS_SUCCESS,
  GET_NOTIFICATION_SETTINGS_REQUEST,
  SET_NOTIFICATION_SETTINGS_REQUEST,
  SET_NOTIFICATION_SETTINGS_SUCCESS,
} from './notificationsActions';
import {LOGOUT} from '../client';

const initialState: any = {
  isOpen: false,
  loading: false,
  unreadNotificationsCount: 0,
  nextUrl: null,
  list: [],
  settings: null,
};

export const notificationsReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case PUSH_NOTIFICATIONS: {
      return {
        ...state,
        list: [...action.notifications, ...state.list],
        unreadNotificationsCount: state.unreadNotificationsCount + 1,
      };
    }
    case TOGGLE_NOTIFICATIONS: {
      return {...state, isOpen: !state.isOpen};
    }
    case LOGOUT: {
      return initialState;
    }
    case LOAD_MORE_NOTIFICATIONS_REQUEST: {
      return {...state, loading: true};
    }
    case GET_NOTIFICATIONS_LIST_REQUEST: {
      return {...state, loading: true};
    }
    case GET_NOTIFICATIONS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: [...state.list, ...action.notifications],
        unreadNotificationsCount: action.unreadNotificationsCount,
        nextUrl: action.nextUrl,
      };
    }
    case LOAD_MORE_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: [...state.list, ...action.notifications],
        unreadNotificationsCount: action.unreadNotificationsCount,
        nextUrl: action.nextUrl,
      };
    }
    case READ_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        list: state.list.map(notification => (notification.id === action.payload?.id ? action.payload : notification)),
        unreadNotificationsCount: state.unreadNotificationsCount - 1,
      };
    }
    case GET_NOTIFICATIONS_LIST_FAILURE: {
      return {...state, loading: false};
    }
    case LOAD_MORE_NOTIFICATIONS_FAILURE: {
      return {...state, loading: false};
    }
    case GET_NOTIFICATION_SETTINGS_SUCCESS:
    case SET_NOTIFICATION_SETTINGS_SUCCESS: {
      return {...state, settings: action.data, loading: false};
    }
    case GET_NOTIFICATION_SETTINGS_REQUEST:
    case SET_NOTIFICATION_SETTINGS_REQUEST: {
      return {...state, loading: true};
    }
    default:
      return state;
  }
};
