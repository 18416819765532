import {Icon} from 'antd';
import * as React from 'react';

import {TooltipWithLink} from '../tooltipWithLink/TooltipWithLink';

interface InfoIconProps {
  message: string | React.ReactNode;
  filled?: any;
  color?: string;
  type?: string;
  style?: object;
  className?: string;
  overlayClassName?: string;
}

export const InfoIcon = ({
  message,
  filled = 'filled',
  type = 'info-circle',
  color = 'var(--color-tooltip-icon)',
  overlayClassName = '',
  style = {},
  className = '',
}: InfoIconProps) => (
  <TooltipWithLink overlayClassName={overlayClassName} title={message}>
    <Icon style={{...style, color}} type={type} theme={filled} className={className} />
  </TooltipWithLink>
);
