import {brandConfig} from '../../utils';
import {ReactComponent as GxLogoImage} from './gx.svg';
import {ReactComponent as LeadokuLogoImage} from './leadoku.svg';
import {ReactComponent as InfiniteLogoImage} from './infinite.svg';
import {ReactComponent as DigitalMarkLogoImage} from './digital-mark.svg';

export const getLogo = () => {
  switch (brandConfig.name) {
    case 'Leadoku':
      return LeadokuLogoImage;
    case 'Infinite':
      return InfiniteLogoImage;
    case 'Digital Mark':
      return DigitalMarkLogoImage;
    default:
      return GxLogoImage;
  }
};
