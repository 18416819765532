import * as React from 'react';
import {Tooltip, Icon} from 'antd';

interface DangerIconProps {
  message: string;
}

export const DangerIcon = ({message}: DangerIconProps) => (
  <Tooltip title={message}>
    <Icon style={{color: '#f8292e', cursor: 'pointer'}} type="warning" />
  </Tooltip>
);
