import * as React from 'react';
import {Modal} from 'antd';
import {STRINGS} from '../..';

interface ConfirmationModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  title?: string;
  message?: string;
  okType?: 'link' | 'default' | 'primary' | 'ghost' | 'dashed' | 'danger' | undefined;
  dataTestId?: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  visible,
  title,
  onOk,
  onCancel,
  okType,
  message,
  dataTestId,
}) => {
  return (
    <Modal
      closable={false}
      visible={visible}
      title={title || STRINGS.confirmation.confirmation_default_title}
      onOk={() => onOk()}
      onCancel={() => onCancel()}
      okText="Yes"
      cancelText="Cancel"
      okType={okType || 'primary'}
      destroyOnClose={true}
    >
      <p data-testid={dataTestId}>{message || STRINGS.confirmation.confirmation_default_message}</p>
    </Modal>
  );
};
