import {GET_AUDIENCE_TREE_REQUEST, GET_AUDIENCE_TREE_SUCCESS, GET_AUDIENCE_TREE_FAILURE} from "./audienceTreeActions";

const initialState: any = {
  data: null,
  loading: false,
  errorStatus: null,
};

export const audienceTreeReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_AUDIENCE_TREE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_AUDIENCE_TREE_SUCCESS: {
      return {...state, data: action.data, loading: false};
    }
    case GET_AUDIENCE_TREE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorStatus: action.status,
      };
    }
    default:
      return state;
  }
};
