import {
  GET_BILLING_INFO_REQUEST,
  GET_BILLING_INFO_SUCCESS,
  GET_BILLING_INFO_FAILURE,
  SET_BILLING_LOADING,
  BILLING_CONTACTS_REQUEST,
  BILLING_CONTACTS_FAILURE,
  GET_BILLING_CONTACTS_SUCCESS,
  CHECK_PROMO_TOKEN_REQUEST,
  CHECK_PROMO_TOKEN_SUCCESS,
  CHECK_PROMO_TOKEN_FAILURE,
} from './billingActions';

const initialState: any = {
  loading: false,
  info: null,
  error: null,
  contactEmails: {},
  addNewContactVisible: false,
  loadingCheckPromo: false,
  isPromoValid: false,
  promoRedirectUrl: '',
  promoError: '',
};

export const billingReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_BILLING_INFO_REQUEST: {
      return {...state, loading: true};
    }
    case SET_BILLING_LOADING: {
      return {...state, loading: action.loading};
    }
    case GET_BILLING_INFO_SUCCESS: {
      return {...state, loading: false, info: action.info};
    }
    case GET_BILLING_INFO_FAILURE: {
      return {...state, loading: false, error: action.error};
    }
    case BILLING_CONTACTS_REQUEST: {
      return {...state, loading: true};
    }
    case BILLING_CONTACTS_FAILURE: {
      return {...state, loading: false, error: action.error};
    }
    case GET_BILLING_CONTACTS_SUCCESS: {
      return {...state, loading: false, contactEmails: action?.data?.billing_contacts};
    }
    case CHECK_PROMO_TOKEN_REQUEST: {
      return {...state, loadingCheckPromo: true};
    }
    case CHECK_PROMO_TOKEN_FAILURE: {
      return {...state, loadingCheckPromo: false, promoError: action?.error};
    }
    case CHECK_PROMO_TOKEN_SUCCESS: {
      return {...state, loadingCheckPromo: false, promoError: '', isPromoValid: true, promoRedirectUrl: action?.url};
    }
    default:
      return state;
  }
};
