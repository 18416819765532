import {STRINGS} from '../index';

export const getCampaignErrorMessageByCode = (errorCode: string) => {
  if (!errorCode) {
    return '';
  }

  return STRINGS.errors.campaign[errorCode] || STRINGS.errors.campaign.error_default;
};

export const getUserErrorMessageByCode = (errorCode: string) => {
  if (!errorCode) {
    return '';
  }

  return STRINGS.errors.user[errorCode] || STRINGS.errors.user.error_default;
};
