import * as React from 'react';
import {Popover} from 'antd';
import {PopoverContent} from './popoverContent';
import './audienceStats.scss';

export const AudienceStats = ({
  stats,
  campaign,
  client,
  loading,
  potential,
  children,
  getCampaignAudienceStats,
}: any) => {
  return (
    <Popover
      onVisibleChange={visible => (visible ? getCampaignAudienceStats(campaign.id) : null)}
      trigger="click"
      content={
        <PopoverContent stats={stats} campaign={campaign} client={client} loading={loading} potential={potential} />
      }
    >
      <div>{children}</div>
    </Popover>
  );
};
