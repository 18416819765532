export class TwitterAuthService {
  client: any;
  service_name = 'twitter';
  constructor({client}: any) {
    this.client = client;
  }
  async getAuthorizationLink(user_id: number) {
    const parameters = {user_id};
    return this.client.get(`${this.service_name}/authorize`, parameters).then((result: any) => result);
  }

  async unlinkTwitter(user_id: number) {
    const parameters = {user_id};
    return this.client.post(`${this.service_name}/unlink`, parameters).then((result: any) => result);
  }

  async getSignature(user_id: number, url: string, http_method: string) {
    return this.client
      .post(`${this.service_name}/generate_signature`, {user_id, url, http_method})
      .then((result: any) => result);
  }
}
