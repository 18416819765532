import {brandConfig} from '@growth-x/ui';

import gxImage from '../assets/images/gx.png';
import infiniteImage from '../assets/images/infinite.png';
import leadokuImage from '../assets/images/leadoku.png';
import DigitalMarkLogoImage from '../assets/images/digital-mark.svg';

export const getLogo = () => {
  switch (brandConfig.name) {
    case 'Leadoku':
      return leadokuImage;
    case 'Infinite':
      return infiniteImage;
    case 'Digital Mark':
      return DigitalMarkLogoImage;
    default:
      return gxImage;
  }
};
