import React from 'react';
import {CustomView, isMobile, isTablet} from 'react-device-detect';

export function MobileView({children, ...props}) {
  return (
    <CustomView condition={isMobile && !isTablet} {...props}>
      {children}
    </CustomView>
  );
}
