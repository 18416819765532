export class ReceiversService {
  client: any;
  constructor({client}: any) {
    this.client = client;
  }
  serviceName = 'receivers';

  getProspects(pagination: any) {
    return this.client.get(this.serviceName, {
      method: 'prospects_new',
      ...pagination,
    });
  }
  getConnections(pagination: any) {
    return this.client.get(this.serviceName, {
      method: 'by_client',
      ...pagination,
    });
  }
  archiveReceivers(ids: number[]) {
    return this.client.post('client_receivers', {
      method: 'archive',
      receiver_ids: ids,
    });
  }
  enhanceReceivers(ids: number[]) {
    return this.client.post('client_receivers', {
      method: 'enhance',
      receiver_ids: ids,
    });
  }
  exportAllProspects(filters) {
    const parameters = {method: 'export_all_prospects_new', ...filters};
    return this.client.getUrl(this.serviceName, parameters);
  }
  exportConnections(filters) {
    const parameters = {method: 'export_all', ...filters};
    return this.client.getUrl(this.serviceName, parameters);
  }
}
