import * as React from 'react';
import {Icon} from 'antd';

export const EnhancedReceiverCell = ({receiver, field}: any) => {
  if (!receiver.enhanced) {
    return <Icon style={{color: 'var(--color-main-blue)'}} type="lock" />;
  } else if (receiver.enhanced === 2) {
    return (
      <span>
        <span style={{color: 'orange'}}>!</span> not found
      </span>
    );
  } else {
    return (
      <span style={{wordWrap: 'break-word', wordBreak: 'break-word'}}>{receiver[field] ? receiver[field] : '-'}</span>
    );
  }
};
