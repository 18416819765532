export class ClientAttachmentsService {
  client: any;
  serviceName = 'client_attachments';

  constructor({client}: any) {
    this.client = client;
  }

  create(attachment: File) {
    return this.client
      .postMultipart(this.serviceName, {attachment: attachment})
      .catch((e: any) => e.response)
      .then((result: any) => result);
  }

  del(attachment_id: number) {
    return this.client.delete(this.serviceName + `/${attachment_id}`);
  }

  get(attachment_id: number | string) {
    return this.client
      .get(this.serviceName + `/${attachment_id}`, {})
      .catch((e: any) => e.response)
      .then((result: any) => result);
  }

  getList() {
    return this.client.get(this.serviceName, {});
  }
}
