import {Layout} from 'antd';
import * as React from 'react';

import {Sidebar, BrowserView, MobileView} from '@growth-x/ui';

import {Header, Navigation} from '../';
import {MobileNavigation} from '../navigation/mobileNavigation';

export const PageWrapper = ({children, ...rest}: any) => (
  <Layout style={{height: '100%'}} className={`page-${rest.pageName}`}>
    <Header />
    <Layout style={{position: 'relative'}}>
      <div style={{borderRight: '1px solid var(--color-light-gray)'}}>
        <BrowserView>
          <Navigation
            location={rest.location}
            setSettings={rest.setSettings}
            isNavCollapsed={rest.settings.isNavCollapsed}
            client={rest.client}
            users={rest.users}
            unreadCountByUser={rest.unreadCountByUser}
            inboxLoading={rest.initialConversationsLoading}
            isAdmin={rest.isAdmin}
            openKeys={rest.settings.openKeys}
          />
        </BrowserView>
        <MobileView>
          <MobileNavigation
            location={rest.location}
            setSettings={rest.setSettings}
            isNavCollapsed={rest.settings.isNavCollapsed}
            client={rest.client}
            users={rest.users}
            unreadCountByUser={rest.unreadCountByUser}
            inboxLoading={rest.initialConversationsLoading}
            isAdmin={rest.isAdmin}
            openKeys={rest.settings.openKeys}
          />
        </MobileView>
      </div>
      <Layout.Content>{children}</Layout.Content>
      <BrowserView>
        {rest.sidebar && (
          <Sidebar collapsedWidth={40} collapsible={true}>
            {rest.sidebar}
          </Sidebar>
        )}
      </BrowserView>
    </Layout>
  </Layout>
);
