export class UserReceiverService {
  client: any;
  serviceName = 'user_receivers';

  constructor({client}: any) {
    this.client = client;
  }

  updateInboxNoteValue(user_id: number, receiver_id: number, inbox_note) {
    return this.client.put(this.serviceName, {
      method: 'update_value',
      user_id,
      receiver_id,
      key: 'inbox_note',
      value: inbox_note,
    });
  }

  updateDealsAmountValue(user_id: number, receiver_id: number, deals_amount) {
    return this.client.put(this.serviceName, {
      method: 'update_value',
      user_id,
      receiver_id,
      key: 'deals_amount',
      value: deals_amount,
    });
  }

  addCustomFieldToUserReceiver(params: any) {
    const {custom_email, custom_phone_number} = params;
    return this.client.patch(`api/v2/${this.serviceName}/${params?.id}`, {custom_email, custom_phone_number});
  }
}
