import {connect} from 'react-redux';

import {AdminTools} from '../../components/header/AdminTools';
import {adminMaintenanceActions} from '../../redux/adminMaintenance';
import {clientActions} from '../../redux/client';

function mapState(state: any) {
  return {
    client: state.client.data,
    isAdmin: state.settings.isAdmin,
    clientInstance: state.adminMaintenance.clientInstance,
    getLoading: state.adminMaintenance.getLoading,
    updateLoading: state.adminMaintenance.updateLoading,
  };
}

const actionCreators = {
  createCloudInstance: adminMaintenanceActions.createCloudInstance,
  forceRebootCloudInstance: adminMaintenanceActions.forceRebootCloudInstance,
  delayDeleteCloudInstance: adminMaintenanceActions.delayDeleteCloudInstance,
  deleteCloudInstance: adminMaintenanceActions.deleteCloudInstance,
  getCloudInstance: adminMaintenanceActions.getCloudInstance,
  setProgressPercentage: adminMaintenanceActions.setProgressPercentage,
  setProgressDone: adminMaintenanceActions.setProgressDone,
  getClient: clientActions.getClient,
};

export const AdminToolsContainer = connect(mapState, actionCreators)(AdminTools);
