import {Tooltip, Button, Dropdown, Menu, Checkbox} from 'antd';
import React from 'react';

import {TableColumn} from '@growth-x/types';

import {CONSTANTS} from '../../services/constants';

const allColumns: TableColumn[] = CONSTANTS.connectionsTableColumns;

export const ConnectionsRightActions = ({
  handleExport,
  handleRefresh,
  connectionsLoading,
  handleChangeColumns,
  showColumns,
  searchValue,
}: any) => {
  const columnsMenu = (
    <Menu>
      <div>
        <Checkbox.Group
          className="connections-page__columns-checkboxes"
          onChange={handleChangeColumns}
          defaultValue={showColumns}
          options={allColumns}
        />
      </div>
    </Menu>
  );
  return (
    <div className="connections-page__right-buttons">
      <Tooltip title="Columns">
        <Dropdown overlay={columnsMenu} trigger={['click']}>
          <Button shape="circle" icon="layout" />
        </Dropdown>
      </Tooltip>
      <Tooltip title={searchValue ? 'Export Filtered' : 'Export All'}>
        <Button onClick={handleExport} shape="circle" icon="download" />
      </Tooltip>
      <Tooltip title="Refresh">
        <Button onClick={handleRefresh} loading={connectionsLoading} shape="circle" icon="reload" />
      </Tooltip>
    </div>
  );
};
