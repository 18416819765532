export class TaskService {
  client: any;
  constructor({client}: any) {
    this.client = client;
  }
  serviceName = 'app_tasks';

  getTasks(params: any) {
    return this.client.get(`${this.serviceName}`, params);
  }

  postTask(params: any) {
    return this.client.post(`${this.serviceName}`, params);
  }

  updateTask(params: any) {
    return this.client.put(`${this.serviceName}`, params);
  }
}
