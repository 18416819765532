import React, {useMemo} from 'react';

import {STRINGS} from '../../utils';
import UpgradeButton from './UpgradeButtons';
import {getLogo} from './utils';
import './Logo.scss';

const env = process.env.NX_ENV || 'production';

const getDaysLeft = (trialEndDate: string) => {
  if (!trialEndDate) {
    return 0;
  }
  // slow equivalent: moment(trialEndDate).diff(moment(), 'days');
  return Math.floor((new Date(trialEndDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
};

const getClientType = client => {
  if (client.type === 'starter' && client.signature_enabled) {
    return 'freemium';
  }
  return client.type;
};

export const Logo = ({client, onUpgrade, isAdmin, openBilling}: any) => {
  let versionText = isAdmin ? client.app_version : '';
  versionText += env !== 'production' ? ` ${env}` : '';
  versionText += client.type ? ` [${getClientType(client)}]` : '';
  versionText += client && isAdmin ? ' [admin mode]' : '';
  const daysLeft = useMemo(() => getDaysLeft(client.trial_end_date), [client.trial_end_date]);

  const LogoImage = getLogo();
  return (
    <div className="logo">
      <LogoImage className="logo__img" />
      <div className="version">
        <div className="text-primary text-smaller">{versionText}</div>
        <div>
          <UpgradeButton client={client} onUpgrade={onUpgrade} openBilling={openBilling} />
          {client && client.type === 'on-hold' && (
            <span className="trial-days">{STRINGS.info_subscription_onhold}</span>
          )}
          {client && client.type === 'starter' && client.trial_active && (
            <span className="trial-days">{`You have ${daysLeft} days left of your trial.`}</span>
          )}
        </div>
      </div>
    </div>
  );
};
