import {Form, Input, Select, Button, Row} from 'antd';
import {Formik} from 'formik';
import React from 'react';

import {CONSTANTS, STRINGS} from '../../utils';
import {AccountListSchema} from './AccountListSchema';

const initialValues = {
  name: '',
  user: undefined,
  code: CONSTANTS.TASK_TYPES.ACCOUNT_LIST,
  list_name: '',
  filter_url: '',
};

export const SavingAccountsTask = ({users, onSubmit}: any) => {
  return (
    <Formik initialValues={initialValues} validationSchema={AccountListSchema} onSubmit={onSubmit}>
      {({values, touched, errors, dirty, handleSubmit, setFieldValue}) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Form.Item
              label="Name*:"
              validateStatus={errors.name && touched.name ? 'error' : ''}
              hasFeedback
              help={errors.name && touched.name ? errors.name : ''}
            >
              <Input
                name="name"
                value={values.name}
                placeholder="Type the name of your task"
                onChange={ev => setFieldValue('name', ev.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="User*:"
              validateStatus={errors.user && touched.user ? 'error' : ''}
              hasFeedback
              help={errors.user && touched.user ? errors.user : ''}
            >
              <Select onChange={value => setFieldValue('user', value)} style={{width: '200px'}}>
                {users.map(user => (
                  <Select.Option key={user.id}>
                    {user.first_name} {user.last_name}({user.email})
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="List Name*:"
              validateStatus={errors.list_name && touched.list_name ? 'error' : ''}
              hasFeedback
              help={errors.list_name && touched.list_name ? errors.list_name : ''}
            >
              <Input
                name="list_name"
                value={values.list_name}
                placeholder="Type the LinkedIn's list name"
                onChange={ev => setFieldValue('list_name', ev.target.value)}
              />
            </Form.Item>
            <Form.Item
              label={'Search Filters URL*:'}
              validateStatus={errors.filter_url && touched.filter_url ? 'error' : ''}
              hasFeedback
              help={errors.filter_url && touched.filter_url ? errors.filter_url : ''}
            >
              <Input
                name="filter_url"
                placeholder={STRINGS.placeholder.filter_url}
                value={values.filter_url}
                onChange={ev => setFieldValue('filter_url', ev.target.value)}
              />
            </Form.Item>
            <Row type="flex" justify="end">
              <Button htmlType="submit" type="primary" disabled={!dirty}>
                Create Task
              </Button>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};
