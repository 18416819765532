import {CONSTANTS} from '../constants';

type CompaniesReplacer = {
  keys: string[];
  values: string[];
};

/**
 * A function that returns an object that contains two arrays,
 * keys and values. The first stores the keys to looking for in text
 * and the second one stores the values to replace the those keys.
 * @param {String} message - Message wrote by client
 * @param {Object} variables - The variables for replacer
 * the company exists
 * @returns {CompaniesReplacer} CompaniesReplacer - Object with two values, keys and values
 */
export const getCompaniesReplacer = (message = '', variables: any): CompaniesReplacer => {
  const initialKeys = [
    {key: '{receiver.company_name}', value: ['company_name', 'company']},
    {key: '{receiver.most_recent_past_company}', value: ['most_recent_past_company']},
    {key: '{receiver.company_industry}', value: ['company_industry']},
    {key: '{receiver.company_region}', value: ['company_region']},
    {key: '{receiver.receiver_region}', value: ['receiver_location']},
    {key: '{receiver.company_name OR ', value: ['company_name', 'company'], editable: true},
    {key: '{receiver.most_recent_past_company OR ', value: ['most_recent_past_company'], editable: true},
    {key: '{receiver.company_industry OR ', value: ['company_industry'], editable: true},
    {key: '{receiver.company_region OR ', value: ['company_region'], editable: true},
    {key: '{receiver.receiver_region OR ', value: ['location'], editable: true},
  ];
  let keysAndValues = {
    keys: [],
    values: [],
  };

  initialKeys.forEach(item => {
    if (message.includes(item.key)) {
      const property = item.value.filter(x => variables[x]);
      const receiverValue = variables[property[0]];
      const defaultValue = CONSTANTS.COMPANY_VARIABLES_FALLBACK[item.value[0]];
      const splittedMessage = message.split(item.key);

      let preparedValue = receiverValue || defaultValue;

      if (item.editable) {
        keysAndValues = splittedMessage.slice(1).reduce((acc, chunk) => {
          const fallBackCompany = chunk.substring(0, chunk.indexOf('}'));
          preparedValue = receiverValue || fallBackCompany || defaultValue;

          return {
            keys: [...acc.keys, `${item.key}${fallBackCompany}}`],
            values: [...acc.values, preparedValue],
          };
        }, keysAndValues);
      } else {
        keysAndValues = {keys: [...keysAndValues.keys, item.key], values: [...keysAndValues.values, preparedValue]};
      }
    }
  });

  return keysAndValues;
};
