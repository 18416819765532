import {Button, Dropdown, Menu, Icon} from 'antd';
import * as React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ActionsFilter} from './actionsFilter';
import {ArchiveStateFilter} from './archiveStateFilter';
import {CampaignsFilter} from './campaignsFilter';
import {CampaignTypesFilter} from './campaignTypesFilter';
import {DateFilter} from './dateFilter';
import {UsersFilter} from './usersFilter';
import './campaignFilters.css';

const FILTER_DEFAULT_STATES = {
  date: 'last_30_days_vs_prev_30_days',
  users: [],
  campaigns: [],
  campaign_types: [],
  archive_mode: 'unarchived_only',
  actions: [],
};

const sortFilters = filters => {
  const defaultOrder = Object.keys(FILTER_DEFAULT_STATES);
  filters.sort((a, b) => defaultOrder.indexOf(a) - defaultOrder.indexOf(b));
  return filters;
};

export const CampaignFilters = ({
  campaigns,
  users,
  filterCampaigns,
  filters,
  updateGraph,
  client,
  statsMode,
  getCampaigns,
  setArchiveMode,
  archiveMode,
}: any) => {
  const [campaignsToRequest, setCampaignsToRequest] = React.useState([]);
  const [shownFilters, setShownFilters] = React.useState([]);

  React.useEffect(() => {
    const notEmptyFilters = Object.keys(FILTER_DEFAULT_STATES).filter(filter => {
      if (filter === 'date' || filter === 'archive_mode') {
        return filters[filter] !== FILTER_DEFAULT_STATES[filter];
      } else {
        return filters[filter]?.length > 0;
      }
    });
    setShownFilters(notEmptyFilters);
  }, [filters]);

  const filteredCampaigns = !filters?.users?.length
    ? campaigns
    : campaigns.filter(c => filters.users.some(id => String(id) === String(c?.selectedUser?.id)));
  const handleUsersChange = users => {
    filterCampaigns({
      ...filters,
      users,
    });
    updateGraph(campaignsToRequest);
  };

  const getFiltersDropdown = (currentFilters: string[]) => {
    return (
      <Menu onClick={params => setShownFilters(sortFilters([...shownFilters, params.key]))}>
        {!currentFilters.includes('date') && (
          <Menu.Item key="date">
            <Icon>
              <FontAwesomeIcon icon={['fas', 'clock']} />
            </Icon>{' '}
            Time period
          </Menu.Item>
        )}
        {!currentFilters.includes('users') && (
          <Menu.Item key="users">
            <Icon>
              <FontAwesomeIcon icon={['fas', 'users']} />
            </Icon>{' '}
            User
          </Menu.Item>
        )}
        {!currentFilters.includes('campaigns') && (
          <Menu.Item key="campaigns">
            <Icon>
              <FontAwesomeIcon icon={['fas', 'list-ul']} />
            </Icon>{' '}
            Campaign
          </Menu.Item>
        )}
        {!currentFilters.includes('campaign_types') && (
          <Menu.Item key="campaign_types">
            <Icon>
              <FontAwesomeIcon icon={['fas', 'object-ungroup']} />
            </Icon>{' '}
            Campaign type
          </Menu.Item>
        )}
        {!currentFilters.includes('archive_mode') && (
          <Menu.Item key="archive_mode">
            <Icon>
              <FontAwesomeIcon icon={['fas', 'archive']} />
            </Icon>{' '}
            Archive state
          </Menu.Item>
        )}
        {!currentFilters.includes('actions') && (
          <Menu.Item key="actions">
            <Icon>
              <FontAwesomeIcon icon={['fas', 'bolt']} />
            </Icon>{' '}
            Action
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const handleCampaignsChangeAction = actions => {
    filterCampaigns({
      ...filters,
      actions,
    });
    updateGraph(campaignsToRequest);
  };

  const handleCampaignsChange = campaignsIds => {
    const ids = campaignsIds
      .map(campaign => {
        const filteredIds = campaigns
          .filter(item => {
            return item.name.toLowerCase().includes(campaign?.toLowerCase());
          })
          .map(item => item.id.toString());

        if (!isNaN(campaign) && !filteredIds.length) return campaign;

        return filteredIds.length > 0 ? filteredIds : [0];
      })
      .flat(1);

    filterCampaigns({
      ...filters,
      campaigns: ids,
    });
    updateGraph(ids);
    setCampaignsToRequest(ids);
  };
  const handleCampaignsChangeType = campaign_types => {
    filterCampaigns({
      ...filters,
      campaign_types,
    });
    updateGraph(campaignsToRequest);
  };

  const handleCampaignStatusChange = value => {
    getCampaigns(client.id, value);
    statsMode && updateGraph(campaignsToRequest);
    setArchiveMode(value);
  };

  const handleDateChange = date => {
    filterCampaigns({
      ...filters,
      date,
      date_range_start: '',
      date_range_end: '',
    });
    updateGraph(campaignsToRequest);
  };

  const clearAndCloseUserFilter = () => {
    handleUsersChange([]);
    setShownFilters(shownFilters.filter(filter => filter !== 'users'));
  };

  const clearAndCloseCampaignFilter = () => {
    handleCampaignsChange([]);
    setShownFilters(shownFilters.filter(filter => filter !== 'campaigns'));
  };

  const clearAndCloseDateFilter = () => {
    handleDateChange('last_30_days_vs_prev_30_days');
    setShownFilters(shownFilters.filter(filter => filter !== 'date'));
  };

  const clearAndCloseCampaignTypesFilter = () => {
    handleCampaignsChangeType([]);
    setShownFilters(shownFilters.filter(filter => filter !== 'campaign_types'));
  };

  const clearAndCloseActionsFilter = () => {
    handleCampaignsChangeAction([]);
    setShownFilters(shownFilters.filter(filter => filter !== 'actions'));
  };

  const clearAndCloseArhiveFilter = () => {
    handleCampaignStatusChange('unarchived_only');
    setShownFilters(shownFilters.filter(filter => filter !== 'archive_mode'));
  };

  const getFilter = filterType => {
    switch (filterType) {
      case 'users':
        return (
          <UsersFilter
            key={filterType}
            handleChange={handleUsersChange}
            users={users}
            filters={filters}
            clearAndClose={clearAndCloseUserFilter}
          />
        );
      case 'campaigns':
        return (
          <CampaignsFilter
            key={filterType}
            handleChange={handleCampaignsChange}
            filteredCampaigns={filteredCampaigns}
            filters={filters}
            clearAndClose={clearAndCloseCampaignFilter}
          />
        );
      case 'date':
        return (
          <DateFilter
            key={filterType}
            handleChange={handleDateChange}
            filters={filters}
            clearAndClose={clearAndCloseDateFilter}
            updateGraph={updateGraph}
            campaignsToRequest={campaignsToRequest}
            filterCampaigns={filterCampaigns}
          />
        );
      case 'campaign_types':
        return (
          <CampaignTypesFilter
            key={filterType}
            handleChange={handleCampaignsChangeType}
            filters={filters}
            clearAndClose={clearAndCloseCampaignTypesFilter}
          />
        );
      case 'actions':
        return (
          <ActionsFilter
            key={filterType}
            filters={filters}
            handleChange={handleCampaignsChangeAction}
            clearAndClose={clearAndCloseActionsFilter}
          />
        );
      case 'archive_mode':
        return (
          <ArchiveStateFilter
            key={filterType}
            handleChange={handleCampaignStatusChange}
            archiveMode={archiveMode}
            clearAndClose={clearAndCloseArhiveFilter}
          />
        );
    }
  };

  return (
    <div className="campaigns-page__filters">
      {shownFilters.map(getFilter)}

      {shownFilters.length < 6 && (
        <Dropdown trigger={['click']} overlay={getFiltersDropdown(shownFilters)} placement="bottomCenter">
          <Button type="link" icon="plus">
            Add filter
          </Button>
        </Dropdown>
      )}
    </div>
  );
};
