import {
  SET_SF_AUTHORIZATION_CODE_REQUEST,
  SET_SF_AUTHORIZATION_CODE_SUCCESS,
  SET_SF_AUTHORIZATION_CODE_FAILURE,
  GET_SF_AUTHORIZED_CREDENTIALS_REQUEST,
  GET_SF_AUTHORIZED_CREDENTIALS_SUCCESS,
  GET_SF_AUTHORIZED_CREDENTIALS_FAILURE,
  CLEAR_SF_AUTHORIZED_CREDENTIALS_REQUEST,
  CLEAR_SF_AUTHORIZED_CREDENTIALS_SUCCESS,
  CLEAR_SF_AUTHORIZED_CREDENTIALS_FAILURE,
  WAIT_LOGOUT_REQUEST,
  WAIT_LOGOUT_SUCCESS,
} from './sfOauth2Actions';

const initialState: any = {
  isLoading: false,
};

export const sfOauth2Reducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_SF_AUTHORIZED_CREDENTIALS_REQUEST: {
      return {...state, isLoading: true};
    }
    case GET_SF_AUTHORIZED_CREDENTIALS_SUCCESS: {
      return {...state, isLoading: false, ...action.data};
    }
    case GET_SF_AUTHORIZED_CREDENTIALS_FAILURE: {
      return {...state, isLoading: false};
    }
    case SET_SF_AUTHORIZATION_CODE_REQUEST: {
      return {...state, isLoading: true};
    }
    case SET_SF_AUTHORIZATION_CODE_SUCCESS: {
      return {...state, isLoading: false, ...action.data};
    }
    case SET_SF_AUTHORIZATION_CODE_FAILURE: {
      return {...state, isLoading: false};
    }
    case CLEAR_SF_AUTHORIZED_CREDENTIALS_REQUEST: {
      return {...state, isLoading: true};
    }
    case CLEAR_SF_AUTHORIZED_CREDENTIALS_SUCCESS: {
      return {isLoading: false};
    }
    case CLEAR_SF_AUTHORIZED_CREDENTIALS_FAILURE: {
      return {...state, isLoading: false};
    }
    case WAIT_LOGOUT_REQUEST: {
      return {...state, isLoading: true};
    }
    case WAIT_LOGOUT_SUCCESS: {
      return {...state, isLoading: false};
    }
    default:
      return state;
  }
};
