import React from 'react';
import {Route, Redirect} from 'react-router-dom';

import {FullPageLoader} from '@growth-x/ui';

export function PrivateRoute({component: Component, client, isLoading = false, isAdmin, ...rest}: any) {
  return (
    <Route
      {...rest}
      render={props => {
        if (!client)
          return (
            <Redirect
              to={{
                pathname: '/login',
                search: rest.location.search,
                state: {from: props.location},
              }}
            />
          );
        if (isLoading) return <FullPageLoader />;
        // Render the 'audience' page only if the user is an admin
        if (rest.path === '/audience' && !isAdmin)
          return (
            <Redirect
              to={{
                pathname: '/',
                search: rest.location.search,
                state: {from: props.location},
              }}
            />
          );
        return <Component {...props} />;
      }}
    />
  );
}
