import {Spin} from 'antd';
import React, {useEffect} from 'react';

import {Connect} from './Connect';
import {Disconnect} from './Disconnect';

import './Instantly.scss';

export const Instantly = ({isLoading, isActionLoading, isTokenVerified, connect, disconnect, verifyToken, error}) => {
  useEffect(() => {
    verifyToken();
  }, []);
  return (
    <div>
      {isLoading && <Spin />}
      {!isLoading && !isTokenVerified && <Connect loading={isActionLoading} connect={connect} error={error}/>}
      {!isLoading && isTokenVerified && <Disconnect loading={isActionLoading} disconnect={disconnect} />}
    </div>
  );
};
