import React from 'react';

import {CONSTANTS} from '../../utils';
import {SavingAccountsTask} from './SavingAccountsTask';

export const TaskDetails = ({type, ...rest}) => {
  if (type === CONSTANTS.TASK_TYPES.ACCOUNT_LIST) {
    return <SavingAccountsTask {...rest} />;
  }
  return null;
};
