import {SET_CHARGEBEE_INSTANCE} from './chargebeeActions';
import {LOGOUT} from '../client';

const initialState: any = {
  instance: null,
};

export const chargebeeReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CHARGEBEE_INSTANCE: {
      return {...state, instance: action.instance};
    }
    case LOGOUT: {
      state.instance.logout();
      return state;
    }
    default:
      return state;
  }
};
