import {Tooltip} from 'antd';
import * as React from 'react';

export const ConnectionCell = ({value, isUrl, tooltipText, noWrap = false, fontSize}: any) => {
  const text = value ? value : '-';

  const redirectTo = tooltipText ? tooltipText : text;
  return (
    <span
      style={{
        overflowWrap: 'unset',
        height: '100px !important',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: fontSize,
        whiteSpace: noWrap ? 'nowrap' : 'normal',
      }}
    >
      <Tooltip title={tooltipText}>
        {isUrl && value ? (
          <a
            href={redirectTo.includes('http') ? redirectTo : `//${redirectTo}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </a>
        ) : (
          text
        )}
      </Tooltip>
    </span>
  );
};
