import {Form, Input, Button, Typography, Icon} from 'antd';
import {Formik} from 'formik';
import React from 'react';
import * as Yup from 'yup';

const {Title} = Typography;

const defaultValues = {
  password: '',
  password2: '',
};

const PasswordSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  password2: Yup.string().test('passwords-match', 'Passwords must match', function (value) {
    return this.parent.password === value;
  }),
});

function MemberSetPassword({onSubmit}) {
  return (
    <Formik
      initialValues={defaultValues}
      enableReinitialize={true}
      validationSchema={PasswordSchema}
      onSubmit={(values, actions) => {
        onSubmit(values);
        actions.resetForm({values: defaultValues});
      }}
    >
      {({values, handleSubmit, setFieldValue, errors, touched}) => (
        <Form onSubmit={handleSubmit}>
          <Title level={4}>Set Password</Title>
          <Form.Item
            validateStatus={errors.password && touched.password ? 'error' : ''}
            hasFeedback
            help={errors.password && touched.password ? errors.password : ''}
          >
            <Input
              prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
              value={values.password}
              name="password"
              type="password"
              placeholder="Password"
              onChange={e => setFieldValue('password', e.target.value)}
            />
          </Form.Item>
          <Form.Item
            validateStatus={errors.password2 && touched.password2 ? 'error' : ''}
            hasFeedback
            help={errors.password2 && touched.password2 ? errors.password2 : ''}
          >
            <Input
              prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
              value={values.password2}
              name="password2"
              type="password"
              placeholder="Repeat password"
              onChange={e => setFieldValue('password2', e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Send Password
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

export default MemberSetPassword;
