import {MaintenanceService} from '../../services';

export const SET_MAINTENANCE = 'SET_MAINTENANCE';
export const UNSET_MAINTENANCE = 'UNSET_MAINTENANCE';

export const maintenanceActions = {
  checkMaintenance,
};

function checkMaintenance() {
  return (dispatch: any) => {
    MaintenanceService.checkMaintenance().then(
      (res: any) => {
        if (res.data.maintenance) dispatch(set());
        else dispatch(unset());
      },
      () => dispatch(unset())
    );
  };

  function set() {
    return {type: SET_MAINTENANCE};
  }
  function unset() {
    return {type: UNSET_MAINTENANCE};
  }
}
