import {connect} from 'react-redux';

import {TemplatesModal} from '@growth-x/ui';

import {templateActions} from '../../redux/templates';

function mapState(state: any) {
  return {
    template: state.templates.editTemplate,
    client: state.client.data,
    visible: state.templates.showTemplateModal,
    loading: state.templates.newTemplateLoading,
    templates: state.templates.list,
  };
}

const actionCreators = {
  onSave: templateActions.saveTemplate,
  onToggleModal: templateActions.toggleTemplateModal,
};

export const TemplatesModalContainer = connect(mapState, actionCreators)(TemplatesModal);
