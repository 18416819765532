import React from 'react';

import {BrowserView, MobileView} from '@growth-x/ui';

import {DesktopHeader} from './desktopHeader';
import {MobileHeader} from './mobileHeader';

export function Header() {
  return (
    <>
      <BrowserView>
        <DesktopHeader />
      </BrowserView>
      <MobileView>
        <MobileHeader />
      </MobileView>
    </>
  );
}
