import {Campaign} from '@growth-x/types';

import {LOGOUT} from '../client';
import {WS_CAMPAIGN_UPDATED, WS_CAMPAIGN_REMOVED, WS_CAMPAIGN_CREATED} from '../websocket';
import {
  GET_CAMPAIGNS_REQUEST,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGNS_FAILURE,
  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  UPDATE_CAMPAIGN_REQUEST,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAILURE,
  ARCHIVE_CAMPAIGN_SUCCESS,
  ARCHIVE_CAMPAIGN_FAILURE,
  UNARCHIVE_CAMPAIGN_SUCCESS,
  UNARCHIVE_CAMPAIGN_FAILURE,
  DUPLICATE_CAMPAIGN_SUCCESS,
  DUPLICATE_CAMPAIGN_FAILURE,
  SET_CAMPAIGNS_FILTERS,
  SET_CAMPAIGNS_STATS,
  SET_CAMPAIGNS_STATS_MODE,
  GET_CAMPAIGNS_GRAPH_REQUEST,
  GET_CAMPAIGNS_GRAPH_SUCCESS,
  GET_CAMPAIGNS_GRAPH_FAILURE,
  SET_CAMPAIGHS_GRAPH_OPTIONS,
  UPDATE_CAMPAIGNS,
  GET_CAMPAIGN_AUDIENCE_STATS_REQUEST,
  GET_CAMPAIGN_AUDIENCE_STATS_SUCCESS,
  GET_CAMPAIGN_AUDIENCE_STATS_FAILURE,
  GET_CAMPAIGN_CLICKED_LINKS_STATS_REQUEST,
  GET_CAMPAIGN_CLICKED_LINKS_STATS_SUCCESS,
  GET_CAMPAIGN_CLICKED_LINKS_STATS_FAILURE,
  PARSE_SEARCH_URL_REQUEST,
  PARSE_SEARCH_URL_FAILURE,
  PARSE_SEARCH_URL_SUCCESS,
  SET_CAMPAIGNS_FILTERS_DONE,
  DELETE_CAMPAIGN_FAILURE,
  DELETE_CAMPAIGN_SUCCESS,
} from './campaignsActions';

const initialState: any = {
  list: [],
  campaignsLoading: true,
  campaignsListError: '',
  campaignDetailsLoading: false,
  campaignDetailsError: '',
  statsError: '',
  statsLoading: false,
  stats: [],
  statsMode: false,
  filters: {
    campaigns: [],
    users: [],
    campaign_types: [],
    date: 'last_30_days_vs_prev_30_days',
    date_range_start: '',
    date_range_end: '',
    actions: [],
  },
  campaignsStats: {},
  campaignsTotalStats: {},
  graphData: null,
  graphLoading: false,
  graphOptions: ['invites', 'connections', 'responses'],
  showCampaignColumns: [
    'actions',
    'type',
    'name',
    'warnings',
    'user',
    'potential',
    'to_be_outreached',
    'outreached',
    'invites',
    'nurtured',
    'invites_to_connections_rate',
    'connections_to_responses_rate',
    'total_positive_responses',
    'total_links_clicked',
    'creation_date',
    'in_sequence',
  ],
  campaignAudienceStatsLoading: false,
  campaignAudienceStats: [],
  campaignParsedFiltersLoading: false,
  campaignParsedFilters: null,
  campaignClickedLinksStatsLoading: false,
  campaignClickedLinksStats: [],
};

export const campaignsReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        campaignDetailsLoading: true,
      };
    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignDetailsLoading: false,
        list: state.list.map((campaign: Campaign) => (campaign.id === action.campaign.id ? action.campaign : campaign)),
      };
    case UPDATE_CAMPAIGNS:
      return {
        ...state,
        list: state.list.map((campaign: Campaign) => {
          return action.campaigns.find(c => c.id === campaign.id) || campaign;
        }),
      };
    case UPDATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        campaignDetailsLoading: false,
        campaignDetailsError: action.error,
      };
    case CREATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        campaignDetailsLoading: true,
      };
    case CREATE_CAMPAIGN_SUCCESS: {
      const isAlreadyAdded = state.list.find((campaign: Campaign) => campaign.id === action.campaign.id);
      if (isAlreadyAdded) {
        return {
          ...state,
          campaignDetailsLoading: false,
        };
      } else {
        return {
          ...state,
          campaignDetailsLoading: false,
          list: [...state.list, action.campaign],
        };
      }
    }
    case CREATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        campaignDetailsLoading: false,
        campaignDetailsError: action.error,
      };
    case DUPLICATE_CAMPAIGN_SUCCESS: {
      const isAlreadyAdded = state.list.find((campaign: Campaign) => campaign.id === action.campaign.id);
      const campaign = {...action.campaign, isNewCampaign: true};

      if (isAlreadyAdded) {
        return {
          ...state,
          campaignDetailsLoading: false,
        };
      } else {
        return {
          ...state,
          campaignDetailsLoading: false,
          list: [...state.list, campaign],
        };
      }
    }
    case DUPLICATE_CAMPAIGN_FAILURE: {
      return {
        ...state,
        campaignDetailsLoading: false,
        campaignDetailsError: action.error,
      };
    }
    case GET_CAMPAIGNS_REQUEST:
      return {
        ...state,
        campaignsLoading: true,
      };
    case GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaignsLoading: false,
        list: action.campaigns,
      };
    case GET_CAMPAIGNS_FAILURE:
      return {
        ...state,
        campaignsLoading: false,
        campaignsListError: action.error,
      };
    case ARCHIVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        list: state.list.filter((campaign: Campaign) => campaign.id !== action.campaignId),
      };
    case ARCHIVE_CAMPAIGN_FAILURE:
      return {
        ...state,
        campaignsListError: action.error,
      };
    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        list: state.list.filter((campaign: Campaign) => campaign.id !== action.campaignId),
      };
    case DELETE_CAMPAIGN_FAILURE:
      return {
        ...state,
        campaignsListError: action.error,
      };
    case UNARCHIVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        list: state.list.filter((campaign: Campaign) => campaign.id !== action.campaignId),
      };
    case UNARCHIVE_CAMPAIGN_FAILURE:
      return {
        ...state,
        campaignsListError: action.error,
      };
    case SET_CAMPAIGNS_STATS_MODE:
      return {
        ...state,
        statsMode: action.value,
      };
    case SET_CAMPAIGNS_FILTERS: {
      return {
        ...state,
        campaignsLoading: true,
      };
    }
    case SET_CAMPAIGNS_FILTERS_DONE: {
      return {
        ...state,
        campaignsLoading: false,
      };
    }
    case SET_CAMPAIGNS_STATS: {
      let totalCampaigns = 0;
      const totalCampaignComparison = {};
      const totalCampaignColumns = {};

      const campaignsStats = action.stats;
      totalCampaigns = Object.keys(campaignsStats?.by_campaign || {}).length;
      Object.keys(campaignsStats?.by_campaign || {}).forEach(campaignId => {
        const campaignStats = campaignsStats.by_campaign[campaignId];
        Object.keys(campaignStats).forEach(stat => {
          if (stat === 'tags') {
            campaignStats?.tags.forEach(tag => {
              Object.keys(tag).forEach(tagCol => {
                totalCampaignColumns[tagCol] =
                  typeof totalCampaignColumns[tagCol] === 'undefined'
                    ? tag[tagCol] || 0
                    : totalCampaignColumns[tagCol] + tag[tagCol];
              });
            });
          } else {
            totalCampaignColumns[stat] =
              typeof totalCampaignColumns[stat] === 'undefined'
                ? campaignStats[stat] || 0
                : totalCampaignColumns[stat] + campaignStats[stat];
          }
        });
      });

      if (campaignsStats?.by_campaign_comparison) {
        Object.keys(campaignsStats?.by_campaign_comparison || {}).forEach(campaignId => {
          const campaignStats = campaignsStats.by_campaign_comparison[campaignId];
          Object.keys(campaignStats).forEach(stat => {
            totalCampaignComparison[stat] =
              typeof totalCampaignComparison[stat] === 'undefined'
                ? campaignStats[stat] || 0
                : totalCampaignComparison[stat] + campaignStats[stat];
          });
        });
      }
      const campaignsTotalStats = {
        total: true,
        ...totalCampaignColumns,
        totalCampaigns,
        campaignComparison: totalCampaignComparison,
      };
      return {
        ...state,
        campaignsStats: action.stats,
        campaignsLoading: false,
        campaignsTotalStats,
      };
    }
    case GET_CAMPAIGNS_GRAPH_SUCCESS: {
      let normalizedGraphData: any = [];
      const options = state.graphOptions;
      options.forEach(option => {
        const optionItems = action.graphData[option]
          ? [
              ...action.graphData[option].map(item => {
                return {...item, type: option};
              }),
            ]
          : [];
        normalizedGraphData = [...normalizedGraphData, ...optionItems];
      });
      normalizedGraphData = normalizedGraphData.sort((a, b) => {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        }
        return 0;
      });
      return {
        ...state,
        graphData: normalizedGraphData,
        graphLoading: false,
      };
    }
    case GET_CAMPAIGNS_GRAPH_REQUEST:
      return {
        ...state,
        graphLoading: true,
      };
    case GET_CAMPAIGNS_GRAPH_FAILURE:
      return {
        ...state,
        graphLoading: false,
      };
    case SET_CAMPAIGHS_GRAPH_OPTIONS:
      return {
        ...state,
        graphOptions: action.options,
      };
    case GET_CAMPAIGN_AUDIENCE_STATS_REQUEST:
      return {
        ...state,
        campaignAudienceStatsLoading: true,
      };
    case GET_CAMPAIGN_AUDIENCE_STATS_FAILURE:
      return {
        ...state,
        campaignAudienceStatsLoading: false,
      };
    case GET_CAMPAIGN_AUDIENCE_STATS_SUCCESS:
      return {
        ...state,
        campaignAudienceStatsLoading: false,
        campaignAudienceStats: action.stats,
      };
    case GET_CAMPAIGN_CLICKED_LINKS_STATS_REQUEST:
      return {
        ...state,
        campaignClickedLinksStatsLoading: true,
      };
    case GET_CAMPAIGN_CLICKED_LINKS_STATS_FAILURE:
      return {
        ...state,
        campaignClickedLinksStatsLoading: false,
      };
    case GET_CAMPAIGN_CLICKED_LINKS_STATS_SUCCESS:
      return {
        ...state,
        campaignClickedLinksStatsLoading: false,
        campaignClickedLinksStats: [...action.stats],
      };
    case PARSE_SEARCH_URL_REQUEST:
      return {
        ...state,
        campaignParsedFiltersLoading: true,
      };
    case PARSE_SEARCH_URL_FAILURE:
      return {
        ...state,
        campaignParsedFiltersLoading: false,
        campaignParsedFilters: null,
      };
    case PARSE_SEARCH_URL_SUCCESS:
      return {
        ...state,
        campaignParsedFiltersLoading: false,
        campaignParsedFilters: action.filters,
      };
    case WS_CAMPAIGN_REMOVED:
      return {
        ...state,
        list: state.list.filter((campaign: Campaign) => campaign.id !== action.campaign.id),
      };
    case WS_CAMPAIGN_CREATED: {
      const isPresented = state.list.find((campaign: Campaign) => campaign.id === action.campaign.id);
      const newCampaign = isPresented?.isDuplicatedCampaign
        ? {...action.campaign, isDuplicatedCampaign: true}
        : action.campaign;

      if (isPresented) {
        return {
          ...state,
          list: state.list.map((campaign: Campaign) => (campaign.id === action.campaign.id ? newCampaign : campaign)),
        };
      } else {
        return {
          ...state,
          list: [...state.list, newCampaign],
        };
      }
    }
    case WS_CAMPAIGN_UPDATED:
      return {
        ...state,
        campaignDetailsLoading: false,
        list: state.list.map((campaign: Campaign) => (campaign.id === action.campaign.id ? action.campaign : campaign)),
      };
    case LOGOUT:
      return {...initialState};
    default:
      return state;
  }
};
